import React, { useEffect, useRef, useState } from "react"
import "../styles.css"
import { fetchQuestion, validateAnswer, runQuery, update, fetchSearchFields, fetchUpdatedToken } from "../utils/apis"
import { toast } from "react-toastify"
import { Link } from "react-router-dom"
import QuestionComponent from "../assets/question"
import SolutionUploadComponent from "../assets/solution-upload"
import { useDispatch, useSelector } from "react-redux"
import { fetchUserDetails, setUser } from "../redux/actions/user"
import ToolLevelForm from "./get-question-filters"
import { useNavigate, useSearchParams } from "react-router-dom"
import Header from "./question-header"
import { LEVELS, SUBJECTS, toastConfig } from "../utils/constants"
import { createArray } from "../utils/common-function"
import { useLocation } from "react-router-dom"
import Loader from "../assets/loader"
import ResultComponent from "../assets/result"
import SuccessPage from "../assets/success-page"
import Modal from "../assets/modal"
import { LANGUAGES } from "../utils/constants"
import CommonHeader from "../assets/common-header"
import { Box, Button, MenuItem, Slider, Tooltip, Typography } from "@mui/material"
import useNetworkStatus from "../assets/network_detector"
///split pane
import SplitPane, { Pane } from "split-pane-react"
import "split-pane-react/esm/themes/default.css"
import { SkipNext, SubjectOutlined } from "@mui/icons-material"
import Dropdown from "./ui/Dropdown"
import QuestionTimer from "../assets/timer"

const QuestionPage = ({ route, navigation }) => {
  const levels = createArray(LEVELS)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const userDetails = useSelector((state) => state.user.userDetails)

  const [loader, setLoader] = useState(false)
  const [isInitialLoad, setIsInitialLoad] = useState(true)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [confirmationModalMessage, setConfirmationModalMessage] = useState("")
  const [mode, setMode] = useState(LANGUAGES[0].key)
  const [subscribeComponent, setSubsscribeComponent] = useState(false)
  const [displayIntroForm, setDisaplyIntroForm] = useState(false)
  const [questionMeta, setQuestionMeta] = useState(null)
  const [selectedToolName, setSelectedToolName] = useState()
  const [selectedTechStack, setSelectedTechStack] = useState()
  const [selectedIndustry, setSelectedIndustry] = useState([])
  const [selectedLevel, setSelectedLevel] = useState()
  const [selectedTopic, setSelectedTopic] = useState([])
  const [searchParams, setSearchParams] = useSearchParams()
  const [submittedAnswer, setSubmittedAnswer] = useState("")
  const [answerResult, setAnswerResult] = useState(null)
  const [result, setResult] = useState(null)
  const [isError, setIsError] = useState(false)
  const [submittedKey, onSetSubKey] = useState("")
  const [currentPageRes, setcurrentPageRes] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [pendingToolName, setPendingToolName] = useState(null)
  const [pendingLevel, setPendingLevel] = useState(null)
  const [searchFilterList, setSearchFilterList] = useState({})
  const [techStackUpdate, setTechStackUpdate] = useState({ updating: false, questionId: null })
  const [showSuccessPage, setShowSuccessPage] = useState(false)
  const [quesFetchError, setquesFetchError] = useState(false)
  const [resultLoader, setResultLoader] = useState(false)
  const [timer, setTimer] = useState(0)
  const [isTimerPaused, setIsTimerPaused] = useState(false)
  const [showFreeArenaPopup, setShowFreeArenaPopup] = useState(false)
  const hasShownPopup = localStorage.getItem("hasShownFreeArenaPopup") === "true"
  const [runSol, setRunSol] = useState(false)
  const [next, setNext] = useState(false)
  const [showConfirmationModalForQuesSwitch, setShowConfirmationModalForQuesSwitch] = useState(false)
  const [confirmationModalMessageForQuesSwitch, setConfirmationModalMessageForQuesSwitch] = useState("")
  const [prev, setPrev] = useState(false)
  const isAnswerSubmitted = false
  const { isOnline, isNetworkActive } = useNetworkStatus()
  const [showNetworkConn, setShowNetworkConn] = useState(false)
  const currentlevel = useRef(null)
  const currentTool = useRef(null)

  useEffect(() => {
    if (!isNetworkActive) {
      setShowNetworkConn(true)
    } else {
      setShowNetworkConn(false)
    }
  }, [isNetworkActive])

  //console.log(hasShownPopup)
  //console.log(userDetails)
  useEffect(() => {
    //automatically refresh token
    const interval = setInterval(() => {
      console.log("refreshing token")
      const refreshToken = localStorage.getItem("refreshToken")
      if (refreshToken) {
        fetchUpdatedToken().then((data) => {
          if (data.error) {
            toast.error(data.error, toastConfig)
          } else {
            localStorage.setItem("usertoken", data.token)
          }
        })
      }
    }, 300000)
    return () => clearInterval(interval)
  }, [])

  const limit = 10

  const getSearchFilters = async (tool, level) => {
    let searchFilters = await fetchSearchFields(new URLSearchParams({ tool, level }))
    if (searchFilters && searchFilters.data) {
      console.log("get filter called", searchFilters.data)
      setSearchFilterList(searchFilters.data)
    }
  }

  const fetchMetaData = async (forceFetch = false, questionId) => {
    if (forceFetch || isInitialLoad) {
      try {
        console.log("=", forceFetch, isInitialLoad, selectedToolName)
        await getQuestionMetaData(forceFetch, questionId)
        if (!searchFilterList || !Object.keys(searchFilterList) || !Object.keys(searchFilterList).length) {
          getSearchFilters(selectedToolName, selectedLevel)
        }
        setDisaplyIntroForm(false)
        if (isInitialLoad) setIsInitialLoad(false)
      } catch (error) {
        console.error("Failed to fetch question metadata:", error)
        // Handle error appropriately
        setquesFetchError(true)
      }
    } else {
      // Show confirmation modal
      setShowConfirmationModal(true)
    }
  }

  const confirmUpdate = async (confirm) => {
    setShowConfirmationModal(false)
    if (confirm) {
      if (pendingToolName !== null) {
        console.log("5")
        console.log(pendingToolName, "a")
        getSearchFilters(pendingToolName, selectedLevel)
        setSelectedToolName(pendingToolName)
        setPendingToolName(null) // Reset pending tool name
      }
      if (pendingLevel !== null) {
        getSearchFilters(selectedToolName, pendingLevel)
        setSelectedLevel(pendingLevel)
        setPendingLevel(null) // Reset pending level
      }
    } else {
      // User canceled, just reset pending values
      setPendingToolName(null)
      setPendingLevel(null)
    }
    setquesFetchError(false)
  }

  const confirmQuesSwitch = (confirm) => {
    setShowConfirmationModalForQuesSwitch(false)
    if (confirm) {
      if (next) {
        getQuestionMetaData(true)
      } else {
        getQuestionMetaData(false, prev, true)
      }
    }
    setNext(false)
    setPrev(false)
  }

  useEffect(() => {
    if (loader) return
    dispatch(fetchUserDetails())
  }, [dispatch])

  useEffect(() => {
    if (loader || quesFetchError) return

    if (!userDetails) return

    if (userDetails && !userDetails.isVerified) {
      toast.error("Please verify your email to continue", toastConfig)
      navigate("/verify-email")
    }

    if (userDetails && userDetails.loginCount === 1 && hasShownPopup === false) {
      // Show the popup
      setShowFreeArenaPopup(true)
      // Update localStorage to remember that the popup has been shown
      localStorage.setItem("hasShownFreeArenaPopup", "true")
    }

    if (isInitialLoad && userDetails && userDetails.questionPreferences) {
      if (userDetails.questionPreferences.toolName) {
        console.log("2")
        currentTool.current = userDetails.questionPreferences.toolName
        setSelectedToolName(userDetails.questionPreferences.toolName)
      }
      if (userDetails.questionPreferences.level) {
        currentlevel.current = userDetails.questionPreferences.level
        setSelectedLevel(userDetails.questionPreferences.level)
      }
    }

    if (isInitialLoad && userDetails && !userDetails.questionPreferences) {
      setDisaplyIntroForm(true)
    }

    const shouldFetchMetaData = selectedToolName && (selectedLevel || techStackUpdate.questionId || searchParams.get("questionId"))
    console.log(shouldFetchMetaData, userDetails, isInitialLoad, selectedLevel, selectedToolName)

    if (isInitialLoad && shouldFetchMetaData) {
      fetchMetaData()
      setIsInitialLoad(false) // Mark initial load as completed
    } else if (!isInitialLoad && shouldFetchMetaData) {
      console.log("Re-fetching metadata due to state change...")
      if (techStackUpdate.updating) {
        fetchMetaData(true, techStackUpdate.questionId)
        setTechStackUpdate({ updating: false, questionId: "" })
      } else {
        fetchMetaData(true)
      }
    }
  }, [selectedLevel, selectedToolName, selectedTopic, selectedIndustry, userDetails, hasShownPopup])

  const resetComponent = () => {
    setQuestionMeta(null)
    setSubmittedAnswer(null)
    setAnswerResult(null)
    onSetSubKey("")

    // setSelectedToolName(selectedTechStack)
    setIsError(false)
    setResult(null)
    setTotalPages(0)
    setcurrentPageRes(1)
    setShowSuccessPage(false)
    setTimer(0)
    setIsTimerPaused(false)
    setRunSol(false)
  }

  const getQuestionMetaData = async (isNext = false, questionId, fromPrev = false) => {
    setLoader(true)
    resetComponent()
    if (!fromPrev) questionId = !isNext || questionId ? searchParams.get("questionId") || "" : ""
    console.log({ questionId })
    try {
      setSelectedTechStack(selectedToolName)
      const queryParams = new URLSearchParams({
        questionId,
        toolName: selectedToolName,
        level: selectedLevel || "",
        func: selectedTopic || [],
        industry: selectedIndustry || [],
        isInitialLoad,
        isToolUpdated: currentTool.current !== selectedToolName,
        isLevelUpdated: currentlevel.current !== selectedLevel,
      })
      let response = await fetchQuestion(queryParams)
      if (response.status == 200) {
        setQuestionMeta(response.data)
        console.log(response.data)

        navigate(`?questionId=${response.data.questionId}`, { replace: true })

        if (response.data.answerSubmitted && response.data.answerSubmitted.length > 0 && response.data.rewardPoints) {
          toast.info(`You have already submitted your solution & scored ${response.data.rewardPoints} reward points, however you can practice this problem as many times as you wish`, toastConfig)
        }
        if (selectedToolName === "Python") {
          setSubmittedAnswer(response.data.solutionTemplate)
        }
        if (response.data.rewardPoints) {
          setIsTimerPaused(true)
        }
        setTimer(response.data.totalTimeSpent || 0)
      } else {
        toast.error(response.message || "Some error has occured", toastConfig)
        setquesFetchError(true)
        setIsTimerPaused(true)
        if (response.message === "No question found") {
          setLoader(false)
        }
        if (response.message === "Free tier complete") {
          setSubsscribeComponent(true)
          setLoader(false)
        }
      }
    } catch (error) {
      console.log({ error })
      console.error(error)
      setquesFetchError(true)
      toast.error("Some error has occured", toastConfig)
    } finally {
      setLoader(false)
    }
  }

  const handleFileUpload = (file) => {
    setSubmittedAnswer(file)
  }

  const handleTextInput = (v) => {
    setSubmittedAnswer(v)
  }

  const getValidateObj = (key) => {
    let answer = submittedAnswer
    const formData = new FormData()
    selectedToolName === "Excel" ? formData.append("file", answer) : formData.append("submittedAnswer", answer)
    formData.append("questionId", questionMeta.questionId)
    formData.append("techStack", selectedTechStack)
    formData.append("language", LANGUAGES.find((l) => l.key === mode).value)
    formData.append("userQuestionHistoryId", questionMeta.userQuestionHistoryId)
    formData.append("limit", limit)
    formData.append("page", currentPageRes)
    formData.append("submittedKey", key)
    selectedToolName === "Excel" && formData.append("ExcelFunctions", questionMeta.ExcelFunctions)
    selectedToolName === "SQL" && formData.append("SQLFunctions", questionMeta.SQLFunctions)
    selectedToolName === "Python" && formData.append("PythonFunctions", questionMeta.PythonFunctions)
    key === "submit" && formData.append("timeSpent", timer)

    return formData
  }

  const updateResultSection = (response, key) => {
    onSetSubKey(key)
    setResultLoader(false)
    let isAnswerCorrect = false
    if (response.status == 200) {
      console.log(response.data.count)
      setTotalPages(response.data.count)
      console.log(response)
      if (response.data.isAnswerCorrect) {
        setIsError(false)
        setResult(response.data.submittedResult)
        isAnswerCorrect = true
        localStorage.removeItem(`${questionMeta.userQuestionHistoryId}-solution`)
      } else {
        setIsError(true)
        key === "submit" ? setResult(response.message) : setResult(response.data.submittedResult)
      }
    } else {
      setIsError(true)
      setResult(response.message || "Some error has occured")
    }
    setAnswerResult(isAnswerCorrect)
    if (key == "submit") {
      if (isAnswerCorrect) {
        setTimer(0)
        setShowSuccessPage(true)
        setIsTimerPaused(true)
        isAnswerSubmitted = true
      }
      if (!isAnswerCorrect) {
        setIsTimerPaused(false)
        toast.error("Incorrect Solution", toastConfig)
      }
    }
  }

  const onSubmit = async (key, page) => {
    try {
      if (!submittedAnswer) {
        toast.error("Please add the solution inside function", toastConfig)
        setIsTimerPaused(false)
        return
      }
      let formData = getValidateObj(key)
      setResultLoader(true)
      let response = key === "submit" ? await validateAnswer(formData) : await runQuery(formData)
      if (key === "submit") {
        setIsTimerPaused(true)

        // Handle form submission logic here
      } else {
        setRunSol(true)
      }
      updateResultSection(response, key)
    } catch (err) {
      setIsError(true)
      setResult(err.message || "Some error has occured")
    }
  }

  const onChangeTechStack = async (t) => {
    if (t === "Python") {
      setSubmittedAnswer(questionMeta.solutionTemplate)
    } else {
      setSubmittedAnswer("")
    }
    console.log("4")
    const currentQuestionId = searchParams.get("questionId")
    setTechStackUpdate({ updating: true, questionId: currentQuestionId })

    setSelectedTechStack(t)
    setSelectedToolName(t)
    getSearchFilters(t, selectedLevel)
  }

  const onChangePrimary = (k, v) => {
    let messagePart = ""

    let pref = userDetails?.questionPreferences
    if (!pref) {
      pref = {
        toolName: selectedToolName,
        level: selectedLevel,
      }
    }
    if (k === "toolName") {
      messagePart = `tool ${v}`
      setPendingToolName(v)
    }
    if (k == "level") {
      messagePart = `level ${v}`
      setPendingLevel(v)
    }

    setConfirmationModalMessage(`Are you sure you want to skip the current question by updating ${messagePart}?`)
    setShowConfirmationModal(true)

    pref[k] = v
    setUser((prevDetails) => ({
      ...prevDetails,
      questionPreferences: pref,
    }))
  }

  const onSearchFilter = (v) => {
    setSelectedIndustry(v?.industries)
    setSelectedTopic(v?.functions)
  }

  const handleQuesSwitch = (isNext = false, questionId, fromPrev = false) => {
    setNext(isNext)
    setPrev(questionId)
    let messagePart = ""
    if (runSol) messagePart = `Try Submitting your solution, before switching to ${isNext === true ? "next" : "previous"} question`
    else messagePart = `Are you sure you want to switch to ${isNext === true ? "next" : "previous"} question?`
    setConfirmationModalMessageForQuesSwitch(messagePart)
    setShowConfirmationModalForQuesSwitch(true)
    setIsTimerPaused(true)
  }

  //split pane
  const [sizes, setSizes] = useState([400, "40%"])

  const inlineStyle = {
    display: "flex",
    height: "calc(100vh - 89px)",
    marginTop: "90px",
    backgroundColor: "rgba(35, 38, 39, 1)",
  }
  const inlineStyleLoading = {
    overflow: "auto",
    height: "calc(100vh - 50px)",
    width: "100%",
    marginTop: "50px",
    backgroundColor: "rgba(35, 38, 39, 1)",
    position: "relative",
    color: "white",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  }

  console.log(loader, displayIntroForm, "shre pref value")

  return (
    <div>
      {loader ? (
        <div
          className=""
          style={inlineStyleLoading}>
          <CommonHeader />
          <Loader />
        </div>
      ) : displayIntroForm ? (
        <>
          <CommonHeader />
          <ToolLevelForm
            onSubmit={(e) => {
              console.log("1")
              setSelectedToolName(e.tool)
              setSelectedLevel(e.level)
            }}
          />
        </>
      ) : (
        <>
          {!questionMeta ? (
            <div style={inlineStyleLoading}>
              <CommonHeader />
              <div className="flex flex-col justify-start items-center">
                {quesFetchError && (
                  <>
                    <div className="mx-auto my-4">
                      <h2 className="font-bold text-3xl text-gray-200">Unable to fetch current question</h2>
                      <div className="gradient" />
                    </div>
                    <div className="shiny-border max-w-md">
                      <div className="myCard p-6 !bg-[#1f2021]">
                        <Button
                          variant="outlined"
                          onClick={() => getQuestionMetaData(true)}
                          className={`!flex-1  !shadow-none  !text-yellow-600 !border-yellow-600 !my-4 !ml-auto`}
                          size="small"
                          endIcon={<SkipNext />}>
                          Switch to Next Question
                        </Button>
                        <div id="subject-dropdown">
                          <Dropdown
                            title="Tools"
                            tooltip={`Primary tool selected for the situation is ${selectedToolName}`}
                            icon={<SubjectOutlined />}
                            children={SUBJECTS.map((subject) => (
                              <MenuItem
                                key={subject}
                                value={subject}
                                onChange={(event) => onChangePrimary("toolName", event.target.value)}
                                className="!text-gray-200 !font-light !text-xs">
                                {subject}
                              </MenuItem>
                            ))}
                          />
                        </div>
                        <Box className="w-full">
                          <Typography
                            id="level-slider"
                            gutterBottom
                            className="!text-gray-200 !my-4">
                            Level (1-{LEVELS})
                          </Typography>
                          <Slider
                            value={selectedLevel}
                            onChange={(event) => onChangePrimary("level", event.target.value)}
                            aria-labelledby="level-slider"
                            valueLabelDisplay="auto"
                            step={1}
                            marks
                            min={1}
                            max={LEVELS}
                            className="!text-gray-200"
                          />
                          <Button
                            variant="outlined"
                            size="small"
                            disabled
                            className="hover:!bg-transparent !font-medium !text-xs !px-2 !py-1.5 !capitalize !my-3 !rounded-none
                    !border-0  !border-b !text-gray-400 !border-gray-400 hover:!text-gray-200">
                            Current level: {selectedLevel}
                          </Button>
                        </Box>
                      </div>
                    </div>
                    {/*<Tooltip
                  title="Subjects/Tools"
                  placement="bottom"
                  arrow>
                  <div className="">
                    <select
                      id="subject-select"
                      value={selectedToolName}
                      onChange={(event) => onChangePrimary("toolName", event.target.value)}
                      className="drop-down-container">
                      {SUBJECTS.map((subject) => (
                        <option
                          value={subject}
                          key={subject}
                          className="p-2 text-pretty text-[17px]">
                          {subject}
                        </option>
                      ))}
                    </select>
                  </div>
                </Tooltip> */}
                  </>
                )}
              </div>
            </div>
          ) : questionMeta ? (
            <div>
              {subscribeComponent ? (
                <Modal
                  isOpen={subscribeComponent}
                  onClose={() => setSubsscribeComponent(!subscribeComponent)}
                  title="Free trial Complete!">
                  <p className="text-gray-400 text-sm mt-2">
                    Free tier completed <Link to={"/pricing"}>please subscribe to avail benifits</Link>
                  </p>
                  <Button
                    variant="contained"
                    size="small"
                    className="!shadow-none !bg-gray-600 !text-gray-200 !border-0"
                    onClick={() => setSubsscribeComponent(!subscribeComponent)}>
                    Close Modal
                  </Button>
                </Modal>
              ) : showSuccessPage ? (
                <Modal
                  isOpen={showSuccessPage}
                  onClose={() => {
                    setShowSuccessPage(false)
                  }}
                  title="Confirm Action">
                  <p className="text-gray-400 text-sm mt-2">{"Superb ! Move to Next question ?"}</p>
                  <Button
                    variant="contained"
                    size="small"
                    className="!shadow-none !bg-gray-600 !text-gray-200 !border-0"
                    onClick={() => getQuestionMetaData(true)}>
                    Yes
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    className="!shadow-none !text-gray-200 !border !border-gray-600 !mx-2"
                    onClick={() => setShowSuccessPage(false)}>
                    No
                  </Button>
                </Modal>
              ) : null}
              <Header
                primaryTool={selectedToolName}
                selectedLevel={selectedLevel}
                onChangeSubject={(subject) => onChangePrimary("toolName", subject)}
                onChangeLevel={(level) => onChangePrimary("level", level)}
                levels={levels}
                onSubmit={onSubmit}
                isQuestionFetched={questionMeta ? true : false}
                searchFilterList={searchFilterList}
                onSearchFilter={onSearchFilter}
                selectedIndustry={selectedIndustry}
                selectedTopic={selectedTopic}
                getQuestionMetaData={getQuestionMetaData}
                questionMeta={questionMeta}
                handleQuesSwitch={handleQuesSwitch}
              />

              <div>
                <SplitPane
                  split="vertical"
                  sizes={sizes}
                  onChange={setSizes}
                  style={inlineStyle}>
                  <Pane
                    minSize={500}
                    maxSize="50%">
                    <div className="question-data-wrapper">
                      <QuestionComponent
                        questionMeta={questionMeta}
                        techStack={selectedTechStack}
                        onSubmit={onSubmit}
                        isQuestionFetched={questionMeta ? true : false}
                        onChangeTechStack={onChangeTechStack}
                        techStacks={questionMeta?.techStacks}
                        primaryTool={selectedToolName}
                      />
                    </div>
                  </Pane>
                  <div className="question-data-wrapper">
                    <QuestionTimer
                      userQuestionHistoryId={questionMeta.userQuestionHistoryId}
                      elapsedTime={timer}
                      isTimerPaused={isTimerPaused}
                      onTimeUpdate={(updatedTime) => {
                        setTimer(updatedTime)
                      }}
                      onQuestionSwitch={loader && !isInitialLoad}
                      isAnswerSubmitted={isAnswerSubmitted}
                    />

                    <SolutionUploadComponent
                      tool={selectedTechStack}
                      handleFileUpload={handleFileUpload}
                      handleTextInput={handleTextInput}
                      answer={submittedAnswer}
                      handleModeChange={(value) => {
                        setMode(value)
                      }}
                      LANGUAGES={LANGUAGES}
                      mode={mode}
                      onSubmit={onSubmit}
                      onChangeTechStack={onChangeTechStack}
                      techStacks={questionMeta?.techStacks}
                      selectedTechStack={selectedTechStack}
                      isQuestionFetched={questionMeta ? true : false}
                      primaryTool={selectedToolName}
                      questionMeta={questionMeta}
                      timer={timer}
                      stopTimer={() => setIsTimerPaused(true)}
                      startTimer={() => setIsTimerPaused(false)}
                      resultLoader={resultLoader}
                      handleCurrentAnswer={() => (selectedTechStack === "SQL" ? setSubmittedAnswer("") : setSubmittedAnswer(questionMeta.solutionTemplate))}
                    />

                    <ResultComponent
                      result={result}
                      isAnswerCorrect={answerResult}
                      currentPage={currentPageRes}
                      setCurrentPage={(v) => {
                        setcurrentPageRes(v)
                        onSubmit(submittedKey, v)
                      }}
                      totalPages={totalPages}
                      resultLoader={resultLoader}
                    />
                  </div>
                </SplitPane>
              </div>
            </div>
          ) : null}
        </>
      )}

      {showConfirmationModal && (
        <Modal
          isOpen={showConfirmationModal}
          onClose={() => setShowConfirmationModal(false)}
          title="Confirm Action">
          <p className="text-gray-400 text-sm mt-2">{confirmationModalMessage}</p>
          <div className="flex gap-2 mt-4">
            <Button
              variant="contained"
              size="small"
              className="!shadow-none !bg-gray-600 !text-gray-200 !border-0"
              onClick={() => confirmUpdate(true)}>
              Yes
            </Button>
            <Button
              variant="outlined"
              size="small"
              className="!shadow-none !text-gray-200 !border !border-gray-600"
              onClick={() => confirmUpdate(false)}>
              No
            </Button>
          </div>
        </Modal>
      )}
      {showConfirmationModalForQuesSwitch && (
        <Modal
          isOpen={showConfirmationModalForQuesSwitch}
          onClose={() => {
            setShowConfirmationModalForQuesSwitch(false)
            setIsTimerPaused(false)
          }}
          title="Confirm Action">
          <p className="text-gray-400 text-sm mt-2">{confirmationModalMessageForQuesSwitch}</p>
          <div className="flex gap-2 mt-4">
            <Button
              variant="contained"
              size="small"
              className="!shadow-none !bg-gray-600 !text-gray-200 !border-0"
              onClick={() => confirmQuesSwitch(true)}>
              Yes
            </Button>
            <Button
              variant="outlined"
              size="small"
              className="!shadow-none !text-gray-200 !border !border-gray-600"
              onClick={() => confirmQuesSwitch(false)}>
              No
            </Button>
          </div>
        </Modal>
      )}

      {showFreeArenaPopup && (
        <Modal
          isOpen={showFreeArenaPopup}
          onClose={() => setShowFreeArenaPopup(false)}
          title="Welcome to Marma AI!">
          <p className="text-gray-400 text-sm mt-2">Hi {userDetails.name}, We are pleased to offer you free access to Marma Arena. You can explore it in the Arenas section. Enjoy your learning journey with Marma AI.</p>
        </Modal>
      )}
      {showNetworkConn && (
        <Modal
          isOpen={showNetworkConn}
          onClose={() => setShowNetworkConn(false)}
          title="Check your Internet connection">
          <p className="text-gray-400 text-sm mt-2">Reconnect to submit/run your solutions</p>
        </Modal>
      )}
    </div>
  )
}

export default QuestionPage
