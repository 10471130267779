import React, { useState,useEffect } from 'react';
import { fetchfaqList } from '../../redux/actions/faqs';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../assets/loader';

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="my-2 ">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="text-left w-full bg-inherit hover:text-blue-500  rounded-2xl font-semibold py-2 px-4 focus:outline-none"
      >
        {question}
      </button>
      {isOpen && (
        <div className="px-4 py-2">
          <h4 className=" text-md">{answer}</h4>
        </div>
      )}
    </div>
  );
};

const FaqsPage = () => {
  const dispatch = useDispatch();
  const faqsData = useSelector(state => state.faqs.faqs);
  
  useEffect(() => {
      dispatch(fetchfaqList());
  },[dispatch]);

  if (!faqsData || !faqsData.length) {
    return(
    <div className="pre-login-faqs-page page-background ">
      <Loader/>
    </div>)

  }
  return (
    <div className="pre-login-faqs-page page-background ">
    <div className="pre-login-faqs-container my-24 w-full max-w-4xl mx-auto">
      <div className="">
        <div className="card glowing-card shadow-md  p-4 ">
        {faqsData.map((item,index) => (
          <div key={index}>
            <h2 className=" font-bold text-3xl">{ "generalQuestions".replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}</h2>
            {item[Object.keys(item)[0]].map((faq, idx) => (
              <FAQItem key={idx} question={faq.question} answer={faq.answer} />
            ))}
          </div>
        ))}
        </div>
      </div>
    </div>
    </div>
  );
};

export default FaqsPage;
