import React,{useState} from 'react'

const blogs = [{title:'Unveiling Insights: The Power of Data Analytics',content:"In today's fast-paced digital landscape, data is the new currency, and harnessing its potential is key to unlocking valuable insights. Enter data analytics, a game-changer in the business world. This transformative discipline involves the systematic examination of raw data to draw conclusions, identify trends, and make informed decisions.Data analytics allows organizations to sift through vast datasets to extract meaningful patterns and correlations. Whether it's understanding customer behavior, optimizing business processes, or predicting future trends, data analytics plays a pivotal role in steering businesses towards success. One of the major strengths of data analytics lies in its ability to empower evidence-based decision-making. By leveraging statistical models, machine learning algorithms, and visualization tools, businesses gain a comprehensive understanding of their operations. This, in turn, enables them to identify areas for improvement, capitalize on opportunities, and mitigate potential risks.Moreover, data analytics isn't just reserved for large enterprises. Small and medium-sized businesses can also harness its benefits to enhance efficiency and competitiveness. With user-friendly analytics tools and cloud-based solutions, the barriers to entry are lower than ever. In conclusion, the era of data analytics is upon us, promising a future where organizations can thrive on informed decision-making. As we navigate this data-driven landscape, embracing the power of analytics becomes not just a choice but a necessity for staying ahead in the business game."},
{title:'Navigating Success Through Data Analytics: A Journey of Insights',content:"In the dynamic world of business, where information is abundant but insights are invaluable, data analytics emerges as the compass guiding organizations towards success. This transformative discipline involves examining raw data to uncover hidden patterns, extract meaningful insights, and drive informed decision-making.Data analytics empowers businesses to convert raw data into actionable intelligence, offering a competitive edge in today's data-centric landscape. From market trends and customer preferences to operational efficiency, organizations can harness analytics to make strategic decisions that fuel growth.One of the key benefits of data analytics is its role in enhancing operational efficiency. By analyzing historical data and identifying patterns, businesses can streamline processes, allocate resources effectively, and optimize overall performance. This not only saves time and resources but also positions organizations to adapt swiftly to market changesThe predictive power of data analytics is another game-changer. Through advanced algorithms and machine learning models, businesses can anticipate future trends, customer behavior, and potential challenges. This foresight empowers organizations to proactively position themselves for success and make data-driven decisions that have a lasting impact.Whether you're a multinational corporation or a startup, data analytics is a democratizing force, offering insights to organizations of all sizes. The journey to success begins with understanding the data at your disposal and leveraging it strategically.In conclusion, data analytics isn't just a tool; it's a compass guiding businesses through the complex waters of today's markets. As we embrace this data-driven era, organizations that master the art of analytics are poised not only to survive but to thrive in the ever-evolving landscape of business."},
{title:"SQL multiple joins for beginners with examples",content:"In this article, we will learn the SQL multiple joins concept and reinforce our learnings with pretty simple examples, which are explained with illustrations. In relational databases, data is stored in tables. Without a doubt, and most of the time, we need a result set that is formed combining data from several tables. The joins allow us to combine data from two or more tables so that we are able to join data of the tables so that we can easily retrieve data from multiple tables. You might ask yourself how many different types of join exist in SQL Server. The answer is there are four main types of joins that exist in SQL Server. "}
]

const Blogs = () => {
    const [blogItem,setBlogItem] = useState('Explore Our Vast Library of Blogs..')
  return (
    <div className='blogs-page page-background'>
        <div className='mt-28 flex w-full justify-around items-center'>
            <div className='w-[25%] m-3 h-auto'>
            <ul className='flex flex-col card glowing-card h-auto'>
                {blogs.map((item) => 
                <li key ={item.title} onClick = {() => setBlogItem(item)} 
                className='text-white font-bold text-2xl my-2 hover:text-blue-400 hover:text-shadow-blue-glow'>
                    {item.title}</li>)}
            </ul>
            </div>
            <div className='p-6 w-[75%]'>
                    <h2 className='text-white mb-4'>{blogItem.title}</h2>
                    <p className='text-white'>
                        {blogItem.content}
                    </p>
            </div>
        </div>
        
      
    </div>
  )
}

export default Blogs
