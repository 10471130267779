const initialState = {
  plans: []
};

  
  const pricingReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_PLANS':
        return {
          ...state,
          plans: action.payload
        };
      default:
        return state;
    }
  };
  
  export default pricingReducer;
  