import React from 'react'
import { ContributionCalendar } from 'react-contribution-calendar'


const data = [
    { '2023-12-12': { level: 2 } },
    { '2024-01-01': { level: 1 } },
    { '2024-01-03': { level: 4, data: {} } },
    { '2024-01-08': { level: 3, data: { myKey: 'my data' } } },
    { '2024-01-10': { level: 2 } },
    { '2024-01-12': { level: 3 } },
    { '2024-01-15': { level: 1 } },
    { '2024-01-17': { level: 4 } },
    { '2024-01-20': { level: 2 } },
    { '2024-01-22': { level: 1 } },
    { '2024-02-01': { level: 3 } },
    { '2024-02-03': { level: 2 } },
    { '2024-02-07': { level: 4 } },
    { '2024-02-09': { level: 1 } },
    { '2024-02-12': { level: 3 } },
    { '2024-02-15': { level: 2 } },
    { '2024-03-01': { level: 4 } },
    { '2024-03-03': { level: 1 } },
    { '2024-03-07': { level: 2 } },
    { '2024-03-10': { level: 3 } },
    { '2024-03-12': { level: 1 } },
]

const GithubChart = () => {
    return (
        <div className="px-4 h-[19rem]">
        {/* <h3 className='text-xl text-gray-200 font-bold text-center mb-2'>Your Submissions</h3> */}
        <ContributionCalendar
            data={data}
            start="2024-01-01"
            end="2024-12-31"
            daysOfTheWeek={[ 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']}
            textColor="#fff"
            startsOnSunday={true}
            includeBoundary={true}
            theme="dark_grass"
            cx={-10}
            cy={16}
            cr={0}
            onCellClick={(e, data) => console.log(data)}
            scroll={true}
            style={{width:"full"}}
        />
        </div>
    )
}

export default GithubChart