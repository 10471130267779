import { Text } from "recharts";

export const CustomXAxisTick = ({ x, y, payload }) => {
    if (payload && payload.value) {
        return (
            <Text
                fontSize={"12px"}
                width={60}
                x={x}
                y={y}
                angle={0}
                textAnchor="middle"
                verticalAnchor="start"
                fill="white"
                dy={4}
            >
                {payload.value}
            </Text>
        );
    }
    return null;
};

export const CustomToolTip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div
                className="custom-tooltip"
                style={{
                    backgroundColor: "#1d1d1d",
                    borderRadius: 5,
                    padding: 4,
                    color: "#e5e7eb",
                }}>
                <p className="label">{`${label}`}</p>
                {payload.toReversed().map((entry, index) => (
                    <p
                        key={`item-${index}`}
                        style={{ color: entry.color }}>
                        {entry.name} : {entry.value}
                    </p>
                ))}
            </div>
        )
    }
    return null
}