import React from 'react'
import { Outlet } from 'react-router-dom'
import NewNavbar from '../new_home_page/NewNavbar'
import BackToTop from '../new_home_page/BackToTop'
const PublicLayout = () => {
  return (
    <div>
      <NewNavbar />
      <div className='min-h-dvh' style={{ background: "#151A22"}}>
        <Outlet style={{ height: "100%" }} />
      </div>
      <BackToTop />
    </div>
  )
}

export default PublicLayout