const initialState = {
    questionHistory: []
  };
  
    
    const questionHistoryReducer = (state = initialState, action) => {
      switch (action.type) {
        case 'SET_QUESTION_HISTORY':
          return {
            ...state,
            questionHistory: action.payload
          };
        default:
          return state;
      }
    };
    
    export default questionHistoryReducer;
    