import { Tooltip } from '@material-ui/core';
import { ArrowUpward } from '@mui/icons-material';
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
const BackToTop = () => {
  const useStyles = makeStyles((theme) => ({
    tooltip: {
      fontSize: '12px',
    }}))

    const classes = useStyles();

  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    console.log(window.scrollY)
    if (window.scrollY > 150) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    console.log("Calling")
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <>
    <Tooltip title="Back to Top" classes={{ tooltip: classes.tooltip }}>
    <button
    data-tooltip-target="tooltip-default"
      className={`back-to-top fixed bottom-6 right-4 text-white cta-btn rounded-full animate__animated p-2 z-50 ${isVisible ? 'animate__fadeInRight' : 'hidden'}`}
      onClick={scrollToTop}
    >
      <ArrowUpward/>
    </button>
    </Tooltip>
    </>

    
  );
};

export default BackToTop;
