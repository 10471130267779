import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Routes, Route, useNavigate } from "react-router-dom"
import CommonHeader from "../assets/common-header"
import { fetchUserDetails } from "../redux/actions/user"
import Loader from "../assets/loader"
import { Tooltip, Button, Badge } from "@mui/material"
import { CircleNotificationsRounded, AccountCircleRounded, InfoRounded, PaymentRounded, Close } from "@mui/icons-material"
import BasicInfo from "../assets/basic-info.js"
import Account from "../assets/account-details.js"
import SubscriptionsOrders from "../assets/subscriptions.js"
import Notifications from "../assets/notifications.js"
import { unseenNotificationsCount } from "../utils/apis.js"
import "../assets/css_files/profile.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons"

const Profile = () => {
  const dispatch = useDispatch()
  const reduxUser = useSelector((state) => state.user.userDetails)
  const [user, setUser] = useState(null)
  const [inboxCount, setInboxCount] = useState(null)
  const [close, setClose] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(fetchUserDetails())
    setUser(reduxUser)
  }, [dispatch, reduxUser === null])

  useEffect(() => {
    fetchUnseenCount()
  }, [])

  const fetchUnseenCount = async () => {
    const response = await unseenNotificationsCount()
    if (response.status === 200) setInboxCount(response.unseenCount)
    else setInboxCount(null)
  }

  if (user === undefined || user === null) {
    return <Loader />
  }

  return (
    <>
      <CommonHeader />
      {user && !user.subscription.isActive && !close && (
        <div className="fixed bg-[#343839] w-full z-50 p-3">
          <div className="flex-row-bw-container w-full px-3">
            <button
              to="/pricing"
              className="hover:!text-yellow-600 text-gray-400">
              Try out Premium
            </button>
            <Button
              onClick={() => setClose(true)}
              startIcon={<Close />}
              className="!text-yellow-600"></Button>
          </div>
        </div>
      )}

      <div className="profile-page-container min-h-screen">
        <div className="container mx-auto">
          <div className="flex-row-bw-container w-full">
            {/* Profile Navigation Menu */}
            <div className="profile-cards-container">
              <Tooltip title={user.isVerified ? "Valid User" : "Verify your profile"}>
                <div className="font-semibold text-base my-3 mx-2 text-[#e5e7eb]">
                  Hi, {user.name}
                  {user.isVerified ? (
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="pl-1"
                      color="green"
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      className="pl-1"
                      color="red"
                    />
                  )}
                </div>
              </Tooltip>

              {/* Menu Links for Sub-Routes */}
              <button
                onClick={() => navigate("/profile/notifications")}
                className={`profile-card ${window.location.pathname === "/profile/notifications" ? "active" : ""}`}>
                <CircleNotificationsRounded />
                Inbox
                <Badge
                  badgeContent={inboxCount}
                  color="primary"
                  className="!ml-auto history-button !justify-self-end me-3"
                  sx={{
                    "& .MuiBadge-badge": {
                      color: "#e5e7eb",
                      backgroundColor: "#4b5563",
                    },
                  }}></Badge>
              </button>

              <button
                onClick={() => navigate("/profile/basic-info")}
                className={`profile-card ${window.location.pathname === "/profile/basic-info" ? "active" : ""}`}>
                <InfoRounded />
                Basic Info
              </button>

              <button
                onClick={() => navigate("/profile/account")}
                className={`profile-card ${window.location.pathname === "/profile/account" ? "active" : ""}`}>
                <AccountCircleRounded />
                Account
              </button>

              <button
                onClick={() => navigate("/profile/subscriptions")}
                className={`profile-card ${window.location.pathname === "/profile/subscriptions" ? "active" : ""}`}>
                <PaymentRounded />
                Subscriptions & Orders
              </button>
            </div>

            {/* Profile Content Based on Route */}
            <div className="profile-details-container">
              <Routes>
                {/* Define sub-routes for profile sections */}
                <Route
                  path="notifications"
                  element={<Notifications fetchUnseenCount={fetchUnseenCount} />}
                />
                <Route
                  path="basic-info"
                  element={<BasicInfo name={reduxUser.name} />}
                />
                <Route
                  path="account"
                  element={
                    <Account
                      name={reduxUser.name}
                      email={reduxUser.email}
                    />
                  }
                />
                <Route
                  path="subscriptions"
                  element={<SubscriptionsOrders />}
                />
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Profile
