import React, { useState, useEffect } from "react"
import { validateCouponCode } from "../utils/apis"
import RazorpayPaymentComponent from "./razorpaygateway"
import StripeComponent from "./stripegateway"
import { Button } from "@mui/material"
import CommonHeader from "../assets/common-header"

const Checkout = ({ tier, arena, user }) => {
  console.log("Tier:", tier)
  console.log("Arena:", arena)

  const [selectedPlan, setSelectedPlan] = useState("monthly")
  const [originalPrice, setOriginalPrice] = useState(arena ? (selectedPlan === "monthly" ? arena.price?.monthly?.amount : arena.price?.yearly?.amount) : tier ? tier.price : 0)
  const [totalAmount, setTotalAmount] = useState(originalPrice)
  const [couponCode, setCouponCode] = useState("")
  const [discount, setDiscount] = useState(0)
  const [error, setError] = useState("")
  const [isProcessing, setIsProcessing] = useState(false)
  const [isCouponApplied, setIsCouponApplied] = useState(false)

  useEffect(() => {
    if (arena) {
      const price = selectedPlan === "monthly" ? arena.price?.monthly?.amount : arena.price?.yearly?.amount
      setOriginalPrice(price)
      setTotalAmount(price)
      removeCoupon() // Remove coupon when plan is changed
    }
  }, [selectedPlan, arena])

  const removeCoupon = () => {
    setIsCouponApplied(false)
    setDiscount(0)
    setCouponCode("")
    setError("")
  }

  const applyCoupon = async (e) => {
    e.preventDefault()
    if (!couponCode) {
      setError("Please enter a coupon code")
      return
    }
    console.log("Applying coupon:", couponCode)
    const couponObj = {
      couponCode: couponCode,
      productId: tier ? tier._id : arena ? arena._id : null,
      userId: user.userId,
    }
    const response = await validateCouponCode(couponObj)
    if (response.status === 200) {
      setIsCouponApplied(true)
      let discountAmount
      if (response.type === "percentage") {
        discountAmount = totalAmount * (response.discountAmount / 100)
      }
      if (response.type === "amount") {
        discountAmount = response.discountAmount
      }
      setDiscount(discountAmount)
      setTotalAmount(totalAmount - discountAmount)
      setError("")
    } else {
      setError(response.error)
    }
  }

  const proceedToPayment = () => {
    setIsProcessing(true)
  }

  if (isProcessing) {
    const updatedItem = arena ? { ...arena, couponCode, type: "arena", planType: selectedPlan, currency: arena.price.monthly.currency } : { ...tier, couponCode, type: "tier" }
    const currency = arena ? (selectedPlan === "monthly" ? arena.price?.monthly?.currency : arena.price?.yearly?.currency) : tier.currency
    return (
      <StripeComponent
        tier={updatedItem}
        user={user}
      />
      // Use Razorpay for INR currency if needed
    )
  }

  return (
    <>
      <CommonHeader />
      <div className="bg-[#232627]  h-[calc(100vh-50px)]">
        <div className="shiny-border max-w-md mx-auto p-6  rounded-md shadow-md text-gray-200 mt-5">
          <div className=" myCard p-6  !bg-[#1f2021]">
            <h2 className="text-2xl font-semibold mb-4">Checkout</h2>
            <div className="mb-4">
              <div className="flex justify-between">
                <span>{tier ? tier.title : arena ? arena.name : "Item"}</span>
                <span className="font-semibold">
                  {tier ? (tier.currency === "USD" ? "$" : "Rs") : arena ? (selectedPlan === "monthly" ? (arena.price?.monthly?.currency === "USD" ? "$" : "Rs") : arena.price?.yearly?.currency === "USD" ? "$" : "Rs") : undefined}
                  {totalAmount?.toFixed(2)}
                </span>
              </div>
              <div className="mt-2 text-sm text-gray-400">
                Original Price {tier ? (tier.currency === "USD" ? "$" : "Rs") : arena ? (selectedPlan === "monthly" ? (arena.price?.monthly?.currency === "USD" ? "$" : "Rs") : arena.price?.yearly?.currency === "USD" ? "$" : "Rs") : undefined}:{" "}
                {tier ? tier.price.toFixed(2) : arena ? (selectedPlan === "monthly" ? arena.price?.monthly?.amount?.toFixed(2) : arena.price?.yearly?.amount?.toFixed(2)) : 0}
              </div>
              {discount > 0 && (
                <div className="mt-2 text-sm text-green-500">
                  Discount: {tier ? (tier.currency === "USD" ? "$" : "Rs") : arena ? (selectedPlan === "monthly" ? (arena.price?.monthly?.currency === "USD" ? "$" : "Rs") : arena.price?.yearly?.currency === "USD" ? "$" : "Rs") : undefined}
                  {discount.toFixed(2)}
                </div>
              )}
            </div>
            <div className="mb-4">
              <input
                type="text"
                className="input"
                placeholder="Enter coupon code"
                value={couponCode}
                onChange={(e) => setCouponCode(e.target.value)}
              />
              {isCouponApplied ? (
                <button
                  className="mt-2 bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600"
                  onClick={removeCoupon}>
                  Remove Coupon
                </button>
              ) : (
                <Button
                  variant="contained"
                  className="mt-2 bg-blue-500 text-white  hover:bg-blue-600 !my-2 !rounded-lg !text-sm !px-2 !py-1.5 !capitalize"
                  onClick={applyCoupon}>
                  Apply Coupon
                </Button>
              )}
              {isCouponApplied && discount > 0 && <div className="text-sm text-green-500 mt-2">Coupon applied successfully</div>}
              {error && <div className="text-red-500 mt-2">{error}</div>}
            </div>
            <div>
              <label className="block mb-2 text-gray-400">Select Plan:</label>
              <select
                value={selectedPlan}
                onChange={(e) => setSelectedPlan(e.target.value)}
                className="input mb-3">
                <option value="monthly">Monthly</option>
                <option value="yearly">Yearly</option>
              </select>
            </div>
            {/*<Button variant="contained" className="w-full !bg-gray-600 !text-gray-200 !shadow-none" size="small"
                          onClick={(e) => e.stopPropagation()}
                        >Enroll</Button> */}
            <Button
              variant="outlined"
              className="!font-medium !text-green-400  !border-green-600 !rounded-lg !text-sm !px-2 !py-1.5 !capitalize "
              onClick={proceedToPayment}
              disabled={isProcessing}>
              {isProcessing ? "Processing..." : "Proceed to Payment"}
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default Checkout
