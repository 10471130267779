import React from 'react'
import { Checkbox } from '@mui/material'

const SettingCheckbox = ({ name, checked, onChange, label, checkedClass, uncheckedClass }) => {
  return (
    <div className="flex items-center gap-2">
      <Checkbox 
        name={name}
        checked={checked} 
        onChange={onChange}
        className={`${checked ? checkedClass : uncheckedClass} !p-0`} 
      />
      <h3 className='text-sm text-gray-400'>{label}</h3>
    </div>
  )
}

export default SettingCheckbox
