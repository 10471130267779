import React, { useState, useEffect } from "react"
import { FormControl, InputLabel, Select, MenuItem, Tooltip, Button, IconButton } from "@mui/material"
import { Controlled as CodeMirror } from "react-codemirror2"

import "codemirror/lib/codemirror.css"
import "codemirror/theme/material.css"
import "codemirror/mode/sql/sql.js"
import "codemirror/addon/hint/show-hint.css"
import "codemirror/addon/hint/sql-hint.js"
import AceEditor from "react-ace"
import { getExcelFile, addMRQuestion } from "../utils/apis.js"
import { toast } from "react-toastify"
import "ace-builds/src-noconflict/mode-python"
import "ace-builds/src-noconflict/theme-tomorrow_night"
import Modal from "../assets/modal"
import PlayArrowIcon from "@mui/icons-material/PlayArrow"
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExternalLink, faFileExcel, faUpload } from "@fortawesome/free-solid-svg-icons"
import CleaningServicesIcon from "@mui/icons-material/CleaningServices"
import { Bookmark, DeveloperMode, TimerRounded } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import Dropdown from "../components/ui/Dropdown.jsx"
import { toastConfig } from "../utils/constants.js"

const SolutionUploadComponent = ({ mode, tool, handleFileUpload, handleTextInput, answer, LANGUAGES, handleModeChange, onSubmit, onChangeTechStack, techStacks, selectedTechStack, isQuestionFetched, primaryTool, questionMeta, isArena, handleSummaryContent, summaryContent, timer, stopTimer, startTimer, resultLoader, handleCurrentAnswer }) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [confirmationModalMessage, setConfirmationModalMessage] = useState("")
  const [showArenaConfirmationModal, setShowArenaConfirmationModal] = useState(false)
  const [arenaConfirmationModalMessage, setArenaConfirmationModalMessage] = useState("")
  const [submittedKey, onSetSubKey] = useState("")
  const [pendingTechStack, setPendingTechStack] = useState("")
  const [isSummaryVisible, setSummaryVisible] = useState(false)
  const [showPrevAnswer, setShowPrevAnswer] = useState(false)
  const [raisedForSol, setRaisedForSol] = useState(false)

  const techStacks1 = techStacks.filter((stack) => stack !== primaryTool)
  techStacks1.push(primaryTool)

  const [watermarkActive, setWatermarkActive] = useState(true)
  const [currentAnswer, setCurrentAnswer] = useState("")

  useEffect(() => {
    if (questionMeta && questionMeta.answerSubmitted && questionMeta.answerSubmitted.length > 0) {
      tool === "SQL"
        ? setCurrentAnswer(`/*--Your Previous submission -- */ \n${questionMeta.answerSubmitted[questionMeta.answerSubmitted.length - 1].answer}
        `)
        : setCurrentAnswer(`"""
        --Your Previous submission --
        """
      ${questionMeta.answerSubmitted[questionMeta.answerSubmitted.length - 1].answer}
      
      
      `)
    }
    setRaisedForSol(questionMeta?.raisedForReview || false)
    const existingAnswer = localStorage.getItem(`${questionMeta.userQuestionHistoryId}-solution`)

    if (answer === null && existingAnswer) {
      handleTextInput(existingAnswer)
    }
  }, [questionMeta])

  const handleTextInputWrapper = (value) => {
    localStorage.setItem(`${questionMeta.userQuestionHistoryId}-solution`, value)
    handleTextInput(value)
  }

  const handleTabPress = (editor) => {
    if (watermarkActive) {
      setWatermarkActive(false)
      editor.setValue(currentAnswer)
    }
  }

  const styles = {
    languageList: {
      display: "flex",
      justifyContent: "center",
    },
  }

  const onClickSubmit = (k) => {
    onSetSubKey(k)
    stopTimer()

    if (isArena && k === "submit" && (summaryContent === "" || summaryContent === null)) {
      setSummaryVisible(true)
      setShowArenaConfirmationModal(true)
      setArenaConfirmationModalMessage(`Provide your insights for current question, before submission`)
      return
    }
    setShowConfirmationModal(true)

    setConfirmationModalMessage(`You have solved in ${formatTime(timer)}, Are you sure you want to submit the question ?`)
  }

  const confirmUpdate = async (confirm) => {
    setShowConfirmationModal(false)
    if (confirm) {
      if (submittedKey) {
        onSubmit(submittedKey)
        onSetSubKey("")
      }
      if (pendingTechStack) {
        onChangeTechStack(pendingTechStack)
        setPendingTechStack("")
      }
    } else {
      onSetSubKey("")
      setPendingTechStack("")
      startTimer()
    }
  }

  const handleExcelDownload = async () => {
    try {
      let response = await getExcelFile(new URLSearchParams({ questionId: questionMeta.questionId }))
      if (response && response.status == 200) {
        if (response.data) {
          const element = document.createElement("a")
          element.href = response.data.url
          element.download = response.data.filename
          document.body.appendChild(element)
          element.click()
          document.body.removeChild(element)
          return
        }
      }
      toast.error("Some error occurred while generating excel file", toastConfig)
    } catch (error) {
      toast.error(error, toastConfig)
    }
  }

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60)
    const seconds = timeInSeconds % 60
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`
  }

  const handleMRQuestion = async () => {
    const currentUrl = window.location.href
    const url = new URL(currentUrl)
    const questionId = url.searchParams.get("questionId")
    try {
      let response = await addMRQuestion(questionId)
      if (response && response.status == 201) {
        toast.success("Question raised for manual review successfully", toastConfig)
        setRaisedForSol(true)
        return
      }
      toast.error("Some error occured while raising question for manual review", toastConfig)
    } catch (error) {
      toast.error(error, toastConfig)
    }
  }

  //   const Editor = styled(AceEditor)`
  //     * {
  //         font-family: inherit;
  //     }
  // `;
  const confirmArenaUpdate = async (confirm) => {
    setShowArenaConfirmationModal(false)
    if (confirm) {
      setSummaryVisible(true)
    } else {
      confirmUpdate(true)
    }
  }

  const handleKeyDown = (e) => {
    if (e.ctrlKey && e.key === "Enter") {
      onSubmit("run")
    }

    if (e.metaKey && e.key === "Enter") {
      onSubmit("run")
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown)

    return () => {
      document.removeEventListener("keydown", handleKeyDown)
    }
  }, [])

  return isArena && showArenaConfirmationModal ? (
    <Modal
      isOpen={showArenaConfirmationModal}
      onClose={() => setShowArenaConfirmationModal(false)}
      title="Confirm Action">
      <p className="text-gray-400 text-sm mt-2">{arenaConfirmationModalMessage}</p>
      <div className="flex gap-2 mt-4">
        <Button
          variant="contained"
          size="small"
          className="!shadow-none !bg-gray-600 !text-gray-200 !border-0"
          onClick={() => confirmArenaUpdate(true)}>
          Yes, I will provide my insights.
        </Button>
        <Button
          variant="outlined"
          size="small"
          className="!shadow-none !text-gray-200 !border !border-gray-600"
          onClick={() => confirmArenaUpdate(false)}>
          No, proceed to next question.
        </Button>
      </div>
    </Modal>
  ) : showConfirmationModal ? (
    <Modal
      isOpen={showConfirmationModal}
      onClose={() => {
        setShowConfirmationModal(false)
        startTimer()
      }}
      title="Confirm Action">
      <p className="text-gray-400 text-sm mt-2">{confirmationModalMessage}</p>
      <div className="flex gap-2 mt-4">
        <Button
          variant="contained"
          size="small"
          className="!shadow-none !bg-gray-600 !text-gray-200 !border-0"
          onClick={() => confirmUpdate(true)}>
          Yes
        </Button>
        <Button
          variant="outlined"
          size="small"
          className="!shadow-none !text-gray-200 !border !border-gray-600"
          onClick={() => confirmUpdate(false)}>
          No
        </Button>
      </div>
    </Modal>
  ) : (
    <div className="solution-upload-component w-full">
      <div className="input-area">
        {isQuestionFetched ? (
          <div className="flex justify-center items-center w-full px-3 py-1">
            {tool === "SQL" && (
              <Dropdown
                title={LANGUAGES.find((item) => item.key === mode).value}
                children={LANGUAGES.map((language) => (
                  <MenuItem
                    className="!text-gray-200 !font-light !text-xs"
                    key={language.key}
                    onClick={() => {
                      handleModeChange(language.key)
                    }}>
                    {language.value}
                  </MenuItem>
                ))}
              />
              // <select
              //   id="language-select"
              //   value={mode}
              //   onChange={handleModeChange}
              //   className="drop-down-container">
              //   {LANGUAGES.map((language) => (
              //     <option
              //       key={language.key}
              //       value={language.key}>
              //       {language.value}
              //     </option>
              //   ))}
              // </select>
            )}
            {isArena && (
              <Button
                onClick={() => {
                  setSummaryVisible(!isSummaryVisible)
                  !isSummaryVisible ? stopTimer() : startTimer()
                }}
                style={{fontFamily:"inherit"}}
                className="!ms-2 !text-gray-400 !bg-[#343839] !rounded !capitalize !text-sm !px-2.5 !px-1.5 !rounded">
                {isSummaryVisible ? "Code Editor" : "Insights Summary"}
              </Button>
            )}
            {(tool === "SQL" || tool === "Python") && (
              <>
                {questionMeta.answerSubmitted && (
                  <Button
                    variant="outlined"
                    size="small"
                    style={{ fontFamily: "inherit" }}
                    className={`hover:!bg-transparent !font-medium !text-xs !px-2 !py-1.5 !capitalize !ml-3 !rounded-none
                    !border-0  !border-b
                    ${showPrevAnswer ? "!text-green-400 !border-green-400 hover:!text-green-200" : "!text-gray-400 !border-gray-400 hover:!text-gray-200"}
                    `}
                    onClick={() => setShowPrevAnswer(!showPrevAnswer)}>
                    {showPrevAnswer ? "Go Live" : "Previous Solution"}
                  </Button>
                )}
                <div className="ml-auto flex-row-container">
                  <div className="text-gray-400 p-2">
                    <TimerRounded className="timer-icon me-1 !text-base" />
                    <span className="timer-span text-sm text-gray-200 select-none">{formatTime(timer)}</span>
                  </div>
                  <div className="text-gray-400">
                    <Tooltip
                      title="Clean up your progress"
                      placement="bottom-end"
                      arrow>
                      <IconButton onClick={handleCurrentAnswer}>
                        <CleaningServicesIcon
                          sx={{ height: "18px" }}
                          className="!text-gray-400"
                        />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              </>
            )}
          </div>
        ) : null}
        {isArena && isSummaryVisible ? (
          <div className="m-2">
            <h3 className="text-md m-2">Provide your Insights</h3>
            <textarea
              placeholder="Type your summary "
              value={summaryContent}
              onChange={(e) => handleSummaryContent(e.target.value)}
              className="w-full rounded p-2 bg-gray-600 bg-opacity-20 focus:border-none focus:outline focus:outline-gray-600"
              style={{ height: "250px" }}></textarea>
          </div>
        ) : tool === "Excel" ? (
          <div className="excel-solution-area">
            <div className="excel-download-area">
              <h5>Download this file to solve question</h5>
              <button
                className="excel-download"
                onClick={handleExcelDownload}>
                <FontAwesomeIcon icon={faFileExcel} />
                Download Excel
              </button>
            </div>
            <div className="file-upload-area">
              <h5>Upload your solution here...</h5>
              <label
                htmlFor="file-upload"
                className="file-upload-label">
                <input
                  id="file-upload"
                  type="file"
                  onChange={(e) => handleFileUpload(e.target.files[0])}
                  className="excel-download"
                />
                <FontAwesomeIcon icon={faUpload} /> Choose a file...
              </label>
              {answer && <p>Uploaded file: {answer?.name}</p>}
            </div>
          </div>
        ) : tool === "SQL" ? (
          <>
            <CodeMirror
              value={showPrevAnswer ? `${currentAnswer}` : answer}
              options={{
                mode: mode,
                theme: "material",
                lineNumbers: true,
                backdrop: "#ffffff",
                tabSize: 2,
                readOnly: showPrevAnswer ? "nocursor" : false,
                extraKeys: {
                  "Ctrl-Space": "autocomplete",
                  Tab: (cm) => handleTabPress(cm),
                },
                lineWrapping: true,
              }}
              onBeforeChange={(editor, data, value) => {
                handleTextInputWrapper(value)
              }}
            />
          </>
        ) : tool === "Python" ? (
          <AceEditor
            value={showPrevAnswer ? `${currentAnswer}` : answer}
            onChange={(value) => handleTextInputWrapper(value)}
            mode="python"
            theme="tomorrow_night"
            name="UNIQUE_ID_OF_DIV"
            height="400px"
            width="100%"
            fontSize="16px"
            className={`ace_editor w-full ${watermarkActive ? "watermark" : ""}`}
            editorProps={{ $blockScrolling: true }}
            setOptions={{
              enableBasicAutocompletion: true,
              enableLiveAutocompletion: true,
              enableSnippets: true,
              showLineNumbers: true,
              lineWrapping: true,
              tabSize: 4,
            }}
            readOnly={showPrevAnswer}
            commands={[
              {
                name: "Tab",
                bindKey: { win: "Tab", mac: "Tab" },
                exec: (editor) => handleTabPress(editor),
              },
            ]}
          />
        ) : null}
      </div>

      <div className="run-submit-btn-container px-3 py-2">
        {raisedForSol ? (
          <Button
            variant="outlined"
            style={{ fontFamily: "inherit" }}
            className="!mr-auto !text-xs !text-gray-400 !border-gray-600"
            onClick={handleMRQuestion}
            endIcon={<Bookmark />}
            disabled>
            Flagged for Review
          </Button>
        ) : (
          <Button
            variant="outlined"
            style={{ fontFamily: "inherit" }}
            className="!mr-auto !text-xs !text-gray-400 !border-gray-600"
            onClick={handleMRQuestion}
            disabled={raisedForSol}>
            Raise for Manual Review{" "}
            <FontAwesomeIcon
              icon={faExternalLink}
              style={{ height: "14px" }}
            />
          </Button>
        )}

        {!resultLoader && (
          <Button
            size="small"
            variant="outlined"
            style={{ fontFamily: "inherit" }}
            className="!me-2 !text-xs !border !border-gray-600 !text-gray-400 !capitalize"
            onClick={(e) => onSubmit("run")}>
            <PlayArrowIcon className="!text-base" /> Run
          </Button>
        )}

        {resultLoader && (
          <LoadingButton
            size="small"
            style={{
              fontFamily: "inherit",
              border: "1px solid #4B5563", // Gray border color
              color: "#6B7280", // Gray text color (when not loading)
              "--loading-color": "#9CA3AF", // Custom property for loader color
            }}
            loading
            classes={{
              loading: "loading-custom-color",
            }}
            className="!me-2 !text-xs !border !border-gray-600"></LoadingButton>
        )}

        <Button
          variant="contained"
          size="small"
          style={{ fontFamily: "inherit" }}
          className="!bg-emerald-600 !text-xs !capitalize"
          onClick={(e) => onClickSubmit("submit")}>
          <CloudUploadIcon className="!text-base" /> Submit
        </Button>
      </div>
    </div>
  )
}

export default SolutionUploadComponent
