import React, { useState, useEffect } from "react"
import "chart.js/auto"
import { Tooltip, Legend, PieChart, Pie, Cell, ResponsiveContainer } from "recharts"
import { fetchPieChartData } from "../utils/apis.js"
import { toast } from "react-toastify"
import Loader from "../assets/loader"
import { getUniqueId } from "../utils/common-function.js"

const DashboardPieChart = ({ metric, forPublic, filterParams }) => {
  const [data, setData] = useState([])
  const [status, setStatus] = useState("idle")

  const CustomPieTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#232627",
            borderRadius: 5,
            padding: 5,
          }}>
          <p className="label">{`${payload[0].name}`}</p>
          <p
            className="value"
            style={{ fontWeight: "bold" }}>{`Questions ${metric === "Attempted" ? "Attempted" : "Solved"}: ${payload[0].value}`}</p>
        </div>
      )
    }

    return null
  }

  const CustomLegend = () => {
    const payload = [
      { color: "#c4b5fd", value: "SQL" },
      { color: "#ffc658", value: "Python" },
      { color: "#22c55e", value: "Excel" },
    ]
    return (
      <ul
        style={{
          listStyle: "none",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}>
        {payload.map((entry, index) => (
          <li
            key={`item-${index}`}
            style={{
              marginRight: 10,
              display: "flex",
              alignItems: "center",
            }}>
            <span
              style={{
                backgroundColor: entry.color,
                width: 10,
                height: 10,
                display: "block",
                marginRight: 10,
                borderRadius: "10px",
              }}></span>
            {entry.value}
          </li>
        ))}
      </ul>
    )
  }

  const fetchData = async (metric) => {
    // Replace this URL with your actual endpoint
    let uniqueId
    if (forPublic) uniqueId = getUniqueId(window.location.href)
    const response = await fetchPieChartData(metric, uniqueId, filterParams)

    if (response.status === 200) {
      if (response.data.length > 0) {
        setData(response.data)
        console.log(response.data)
      }
      response.data.length == 0 ? setStatus("no_data") : setStatus("success")
    } else {
      setStatus("failure")
      console.error("Failed to fetch data")
      toast.error("Unable to Fetch Your Progress!")
    }
  }

  useEffect(() => {
    const fetchAsyncPieData = async () => {
      await fetchData(metric, filterParams)
    }
    fetchAsyncPieData()
  }, [metric, filterParams])

  let content

  if (status === "idle") {
    content = <Loader />
  } else if (status === "no_data") {
    content = <h1 className="text-center text-sm text-gray-400">No Data to Display <br></br> Solve Questions to see your Progress!</h1>
  } else if (status === "failure") {
    content = <h1>Unable to Fetch Data Try Again!</h1>
  } else {
    content = (
      <>
        {/* <h6 className="text-center text-xl font-semibold p-4">Overall Progress</h6> */}
        <ResponsiveContainer height="100%">
          <PieChart>
            <defs>
              <linearGradient id="SQL" x1="0%" y1="0%" x2="100%" y2="100%">
                <stop offset="0%" style={{ stopColor: '#c4b5fd', stopOpacity: 1 }} />
                <stop offset="100%" style={{ stopColor: '#8b5cf6', stopOpacity: 1 }} />
              </linearGradient>
              <linearGradient id="Python" x1="0%" y1="0%" x2="100%" y2="100%">
                <stop offset="0%" style={{ stopColor: '#ffc658', stopOpacity: 1 }} />
                <stop offset="100%" style={{ stopColor: '#ff7300', stopOpacity: 1 }} />
              </linearGradient>
              <linearGradient id="Excel" x1="0%" y1="0%" x2="100%" y2="100%">
                <stop offset="0%" style={{ stopColor: '#86efac', stopOpacity: 1 }} />
                <stop offset="100%" style={{ stopColor: '#22c55e', stopOpacity: 1 }} />
              </linearGradient>
            </defs>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              innerRadius={60}
              outerRadius={90}
              dataKey="value"
              paddingAngle={3}
              label>
              {data.map((entry, index) => {
                return (
                  <Cell
                    key={`cell-${index}`}
                    fill={`url(#${entry.name})`}
                    stroke="none"
                  />
                )
              })}
            </Pie>
            <Tooltip content={<CustomPieTooltip />} />
            <Legend
              iconType="circle"
              content={<CustomLegend />}
              layout="vertical"
              verticalAlign="middle"
              align="right"
            />
          </PieChart>
        </ResponsiveContainer>

      </>
    )
  }

  return <div className="chart-area">{content}</div>
}

export default DashboardPieChart
