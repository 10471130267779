import React from 'react';
import { Link } from 'react-router-dom';

const recruitFeatures = [
  {
    icon: '🔍', // Replace with actual icon components or images as needed
    title: 'Discover Top Talent',
    description: 'Access profiles of our premium data analysts, curated through rigorous challenges.'
  },
  {
    icon: '🔐',
    title: 'Exclusive Access',
    description: 'Only view premium users, ensuring you see the best of the best.'
  },
  {
    icon: '✅',
    title: 'Verified Skills',
    description: 'Trust in our AI-validated solutions, with an option for manual validation for utmost accuracy.'
  },
  {
    icon: '🔗',
    title: 'Direct Connect',
    description: 'Engage with potential candidates and fill your data analytics roles with confidence.'
  },
  {
    icon: '🌟',
    title: 'Join Marma AI',
    description: 'Elevate your recruitment game. Find the data stars of tomorrow, today!'
  }
];

const FeatureItem = ({ feature }) => {
  return (
    <div className="flex items-center text-left mb-4">
      <div className="text-2xl mr-2">{feature.icon}</div>
      <div>
        <h3 className="font-semibold text-lg text-white ">{feature.title}</h3>
        <p className="text-white ">{feature.description}</p>
      </div>
    </div>
  );
};

const Recruit = () => {
  return (
    <div className='recruit-page page-background'>
      <div className="my-24 text-white">
        <div className="card glowing-card">
      <p className="mt-1 text-4xl font-extrabold sm:text-4xl sm:tracking-tight lg:text-6xl text-center mb-3">
            Hello Recruiters!
          </p>
      <div className="max-w-4xl mx-auto px-4">
        <div className="space-y-6">
          {recruitFeatures.map((feature, index) => (
            <FeatureItem key={index} feature={feature} />
          ))}
        </div>
        <div className="mt-10 text-center">
          <h2 className="text-3xl font-bold  mb-3 ">Let us get in touch with you!</h2>
          <div className='flex'>
            <button className='mx-auto button mb-3'>
              <Link to ="/recruitform" className='no-underline text-white text-[24px] font-semibold'>Tell Us More</Link>
            </button>
          </div>
        </div>
      </div>
      </div>
    </div>
    </div>
  );
};

export default Recruit;
