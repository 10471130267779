import { gePlans } from "../../utils/apis";
  
  export const setPricingPlans = (plans) => {
    return {
      type: 'SET_PLANS',
      payload: plans,
    };
  };
  

  export const fetchPlans = () => {
    return async dispatch => {
      let response = await gePlans();  
      if (response.status === 200) {
        dispatch(setPricingPlans(response.data));
      }
    };
  }