const initialState = {
    faqs:[]
};

const faqsReducer =  (state = initialState, action) => {
    switch(action.type){
        case 'SET_FAQS':
            return {
                ...state,
                faqs: action.payload
            };
        default:
            return state;    
    }
};

export default faqsReducer;