import React from 'react'
import RibbonBadge from './RibbonBadge'




const LeaderCard = ({ rank = 1, name, points, sqlSolved, pythonSolved, excelSolved, totalQuestionSolved }) => {
    return (<>
        <div className={`w-full h-48 rounded-md bg-gradient-to-b ${rank == 1 ? "from-[#4F454C]" : "from-gray-600"} to-transparent p-[2px] hover:-translate-y-1.5 transition-all duration-300`}>
            <div className="flex h-full w-full items-center justify-center rounded-md bg-[#303436]">
                <div className="flex flex-col items-center w-full relative">
                    <div className='absolute -top-14'>
                        <RibbonBadge rank={rank} />
                    </div>
                    <h5 className="mb-1 text-xl font-medium text-gray-300">{name}</h5>
                    <span className="text-sm text-gray-400">{points}</span>
                    <hr className="w-48 h-1 mx-auto my-4 border-0 rounded bg-[#3d4143]" />
                    <div className="flex justify-between w-4/5">
                        <div className='flex flex-col text-center'>
                            <span className='text-xs text-gray-500 font-semibold'>Total</span>
                            <span className='text-sm text-gray-300 font-light'>{totalQuestionSolved ? totalQuestionSolved : "NA"}</span>
                        </div>
                        {
                            sqlSolved !== 0 &&
                            <div className='flex flex-col text-center'>
                                <span className='text-xs text-gray-500 font-semibold'>SQL</span>
                                <span className='text-sm text-gray-300 font-light'>{sqlSolved}</span>
                            </div>
                        }

                        {
                            pythonSolved !== 0 &&
                            <div className='flex flex-col text-center'>
                                <span className='text-xs text-gray-500 font-semibold'>Python</span>
                                <span className='text-sm text-gray-300 font-light'>{pythonSolved}</span>
                            </div>
                        }

                        {
                            excelSolved !== 0 &&
                            <div className='flex flex-col text-center'>
                                <span className='text-xs text-gray-500 font-semibold'>Excel</span>
                                <span className='text-sm text-gray-300 font-light'>{excelSolved}</span>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>

    )
}

export default LeaderCard