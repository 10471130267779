import React, { useState, useEffect } from 'react'
import Card from './InsightCard.jsx'
import curve from '../../assets/curve.png'
import { useIsMediumOrLarger } from './useIsMediumOrLarger.js'

const MarmaInsightDisplay = () => {
  const isMediumOrLarger = useIsMediumOrLarger();
  const headingStyle = {
    backgroundImage: "linear-gradient(96deg, #dddcff, #c1bfff)",
    WebkitTextFillColor: "transparent",
    WebkitBackgroundClip: "text"
  }

  const [text, setText] = useState('MARMA');
  const [animateClasses, setAnimateClasses] = useState("animate__animated animate__fadeIn")
  const handleMouseEnter = () => {
    setText('CRUX');
    setAnimateClasses('animate__animated animate__flipInX');
  };

  const handleMouseLeave = () => {
    setText('MARMA');
    setAnimateClasses('animate__animated animate__fadeIn');
  };

  useEffect(() => {
    let interval;
    if (!isMediumOrLarger) {
      interval = setInterval(() => {
        setText((prevText) => (prevText === 'MARMA' ? 'CRUX' : 'MARMA'));
        setAnimateClasses((pre) => (pre === "animate__animated animate__fadeIn" ? "animate__animated animate__flipInX" : "animate__animated animate__fadeIn"))
      }, 2000); // Change text every 2 seconds
    }
    return () => clearInterval(interval);
  }, [isMediumOrLarger]);

  return (
    <div className='container mx-auto mb-12 mt-9'>

      <h3
        style={{...headingStyle}}
        className='text-[28px] lg:text-[36px] text-center font-medium mb-4'
        onMouseEnter={isMediumOrLarger ? handleMouseEnter : null}
        onMouseLeave={isMediumOrLarger ? handleMouseLeave : null}
      >
        Understand the{' '}
        <span
          className={`font-semibold ${animateClasses}`}
          style={{
            display: 'inline-block',
            transition: 'width .5s ease-in-out',
            width: "7ch",
            position: "relative",
            verticalAlign: 'bottom',
            backgroundImage: "linear-gradient(180deg, #9e9cff, #562E7E)",
            WebkitTextFillColor: "transparent",
            WebkitBackgroundClip: "text"
          }}
        >
          {text}
          <img
            src={curve}
            className="absolute top-full left-0 w-full"
            width={624}
            height={28}
            alt="Curve"
          />
        </span>{' '}
        of Data and Business

      </h3>
{/* 
      <h2
        style={headingStyle}
        className="text-center dark:text-white leading-none mb-9"
        onMouseEnter={isMediumOrLarger ? handleMouseEnter : null}
        onMouseLeave={isMediumOrLarger ? handleMouseLeave : null}
      >
        Understand the{' '}
        <span
          className={`font-semibold ${animateClasses}`}
          style={{
            display: 'inline-block',
            transition: 'width .5s ease-in-out',
            width: "7ch",
            position: "relative",
            verticalAlign: 'bottom',
            backgroundImage: "linear-gradient(180deg, #9e9cff, #562E7E)",
            WebkitTextFillColor: "transparent",
            WebkitBackgroundClip: "text"
          }}
        >
          {text}
          <img
            src={curve}
            className="absolute top-full left-0 w-full"
            width={624}
            height={28}
            alt="Curve"
          />
        </span>{' '}
        of Data and Business
      </h2> */}

      <div className='grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-6 mx-auto'
      >
        <Card varient={"myCard1"} title="Explore" data="Real Data Created With AI
Data Across Industries and Verticals (Ecommerce, Healthcare, Finance, Supply Chain, IC Etc.)
Personalized Learning With Adaptive AI Algorithm"/>

        <Card varient={"myCard2"} title="Solve" data="AI Generated Fascinating Business Cases
Problems Focused On Data Complexity and Business Insights
Problems With Different Complexity Levels" />

        <Card varient={"myCard3"} title="Earn" data="Performance Score With AI Powered Validation On Excel, SQL and Python
Attention By Showcasing Progress and Performance
Confidence By Improving Skills With SW Analysis"/>

      </div>
    </div>
  )
}

export default MarmaInsightDisplay