import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Link, useNavigate, useParams } from "react-router-dom"
import CommonHeader from "../assets/common-header"
import { fetchCurrentArenaData } from ".//../redux/actions/arena"
import LaunchRoundedIcon from "@mui/icons-material/LaunchRounded"
import { Tooltip } from "@mui/material"
import { Chrono } from "react-chrono"
import { fetchUserDetails } from "../redux/actions/user"
import Checkout from "./checkout"

const QuestionComponent = ({ questionStatement, isEnrolled, alignment }) => {
  const [showEntireQues, setShowEntireQues] = useState(false)
  const navigate = useNavigate()
  let { name, roadmap } = useParams()

  return (
    <>
      <button
        style={{ padding: "0.3rem 0rem" }}
        onClick={() => navigate(`/arena-question/${name}/${roadmap}?questionId=${questionStatement.questionId}`)}
        disabled={!isEnrolled}>
        {!showEntireQues ? `${questionStatement.ProblemStatement.substring(0, 100)}...` : questionStatement.ProblemStatement}
      </button>
      <div className={`flex-row-container ${alignment}`}>
        <button
          onClick={() => {
            setShowEntireQues(!showEntireQues)
          }}
          className="drop-down-container"
          style={{ padding: "0.2rem", backgroundColor: "#fefefe8a", color: "#232627" }}>
          {showEntireQues ? "Read Less" : "Read More"}
        </button>
      </div>
    </>
  )
}
const description = `E-commerce has revolutionized the way businesses operate, offering unparalleled convenience and a vast array of products at the click of a button. In today's digital age, an
e-commerce platform not only serves as a virtual storefront but also as a vital tool for data-driven decision-making and personalized customer experiences. With features like
advanced search algorithms, secure payment`

function Roadmap() {
  let { name, roadmap } = useParams()
  const decodedRoadmapName = decodeURIComponent(roadmap)
  const navigate = useNavigate()
  const [paymentComponent, setPaymentComponent] = useState(false)

  const dispatch = useDispatch()
  const { currentArena, arenaPrice, userDetails } = useSelector((state) => ({
    currentArena: state.arena.currentArena,
    arenaPrice: state.arena.currentArena.price,
    userDetails: state.user.userDetails,
  }))

  useEffect(() => {
    dispatch(fetchUserDetails())
    if (currentArena.length === 0) dispatch(fetchCurrentArenaData(name))
  }, [dispatch, name])

  let currentRoadmap = currentArena.roadmaps.find((roadmap) => roadmap.name === decodedRoadmapName)
  let arenaObject

  const handleJoin = (e) => {
    e.preventDefault()
    // Add logic to join the arena
    arenaObject = {
      ...currentArena,
      price: arenaPrice,
    }
    // Print the combined object
    console.log(arenaObject)
    setPaymentComponent(true)
  }

  if (paymentComponent) {
    return (
      <Checkout
        arena={{
          ...currentArena,
          price: arenaPrice,
        }}
        user={userDetails}
      />
    )
  }

  return (
    <div>
      <CommonHeader />
      <div className="arenas-homepage-container">
        <h3 className="arenas-moto-heading">
          {currentArena.name} Arena - {roadmap} Roadmap
        </h3>
        <div className="ind-roadmaps-container">
          <div className="roadmap-desc-container">
            <div
              className="title-line enrl-avl-heading flex-row-bw-container"
              style={{ zIndex: 1500 }}>
              <h2 className="enrl-avl-heading">Roadmap Questions</h2>
              {currentArena && (
                <button
                  className="join-btn"
                  onClick={currentArena && currentArena.userEnrolled ? () => navigate(`/arena-question/${name}/${roadmap}?questionId=`) : handleJoin}>
                  {" "}
                  {currentArena && currentArena.userEnrolled ? "Initiate Roadmap" : "Join Roadmap"}{" "}
                </button>
              )}
            </div>
            <div style={{ width: "100%" }}>
              {currentRoadmap && (
                <Chrono
                  mode="VERTICAL_ALTERNATING"
                  activeItemIndex={currentRoadmap.questionStatements.length - 1}
                  focusActiveItemOnLoad={true}
                  highlightCardsOnHover={true}
                  theme={{
                    cardBgColor: "#232627",
                    cardForeColor: "#fefefe",
                    primary: "#fefefe1a",
                    secondary: "#fefefe",
                  }}
                  timelinePointDimension={25}
                  style={{
                    minWidth: "400px",
                  }}
                  disableToolbar
                  scrollTo="bottom"
                  scrollable={{ scrollbar: true }}
                  cardHeight={""}
                  cardWidth={"400"}
                  className="chrono-container"
                  classNames={{
                    card: "chrono-card",
                  }}
                  useReadMore={true} // Enable read more functionality
                >
                  {currentArena &&
                    currentRoadmap &&
                    currentRoadmap.questionStatements &&
                    currentRoadmap.questionStatements.map((questionStatement, index) => (
                      <div key={questionStatement.questionId}>
                        <div className={`chrono-card-content ${index % 2 === 0 ? "card-left" : "card-right"}`}>
                          <li style={{ padding: "0.2rem" }}>{questionStatement.industry.slice(0, 16)}</li>
                        </div>
                        <QuestionComponent
                          questionStatement={questionStatement}
                          isEnrolled={currentArena.userEnrolled}
                          alignment={`${index % 2 === 0 ? "card-right" : "card-left"}`}
                        />

                        <ul className={`chrono-card-content ${index % 2 === 0 ? "card-left" : "card-right"}`}>
                          {questionStatement.SQLFunctions && questionStatement.SQLFunctions.map((functionItem) => <li style={{ padding: "0.2rem" }}>{functionItem.slice(0, 16)}</li>)}
                          {questionStatement.ExcelFunctions && questionStatement.ExcelFunctions.map((functionItem) => <li style={{ padding: "0.2rem" }}>{functionItem.slice(0, 16)}</li>)}
                          {questionStatement.PythonFunctions && questionStatement.PythonFunctions.map((functionItem) => <li style={{ padding: "0.2rem" }}>{functionItem.slice(0, 16)}</li>)}
                        </ul>
                      </div>
                    ))}
                </Chrono>
              )}
            </div>
          </div>
          <div className="references-announcements-section">
            <div className="ref-links">
              <h2 className="title-line enrl-avl-heading">Reference Links</h2>
              {currentRoadmap?.referenceLinks &&
                currentRoadmap.referenceLinks.map((linkItem) => (
                  <Tooltip
                    placement="top"
                    arrow
                    title={`${description}`}>
                    <div className="flex-row-bw-container">
                      <p>{description.length > 100 ? description.substring(0, 100) + "..." : description}</p>
                      {currentArena.userEnrolled ? (
                        <a
                          href={linkItem.url}
                          target="_blank">
                          <LaunchRoundedIcon />
                        </a>
                      ) : (
                        <span>
                          <LaunchRoundedIcon />
                        </span>
                      )}
                    </div>
                  </Tooltip>
                ))}
            </div>
            <div className="announcement-board">
              <h4 className="title-line enrl-avl-heading">Favourite Pick Question</h4>
              <div className="arena-announcement-container">
                {currentRoadmap.favPickDailyQuestions &&
                  currentRoadmap.favPickDailyQuestions.map((questionStatement) => (
                    <div
                      className="arena-item"
                      key={questionStatement.questionId}>
                      <button
                        onClick={() => navigate(`/arena-question/${name}/${roadmap}?questionId=${questionStatement.questionId}`)}
                        disabled={!currentArena.userEnrolled}>
                        {questionStatement.ProblemStatement}
                      </button>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Roadmap
