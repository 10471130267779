import React from 'react'
import Golden from '../../assets/image_files/badges/Golden.png'
import Silver from '../../assets/image_files/badges/Silver.png'
import Bronz from '../../assets/image_files/badges/Bronz.png'

const RibbonBadge = ({ rank = 1 }) => {
    return (
        <>
            {
                rank == 1 ? <GoldenBadge /> :
                rank == 2 ? <SilverBadge /> :
                rank == 3 ? <BronzBadge /> :
                            null
            }
        </>
    )
}

const SilverBadge = () => {
    return (
        <div className="relative w-[50px]">
            <img src={Silver} alt="Silver Badge" className='w-full' />
            <div className="absolute top-[50%] left-[50%] text-lg text-white font-extrabold" style={{ transform: "translate(-50%, -50%)" }}>2</div>
        </div>
    )
}

const BronzBadge = () => {
    return (
        <div className="relative w-[50px]">
            <img src={Bronz} alt="Bronz Badge" className='w-full' />
            <div className="absolute top-[50%] left-[50%] text-lg text-white font-extrabold" style={{ transform: "translate(-50%, -50%)" }}>3</div>
        </div>
    )
}

const GoldenBadge = () => {
    return (
        <div className="relative w-[50px]">
            <img src={Golden} alt="Golden Badge" className='w-full' />
            <div className="absolute top-[50%] left-[50%] text-lg text-white font-extrabold" style={{ transform: "translate(-50%, -50%)" }}>1</div>
        </div>
    )
}

export default RibbonBadge