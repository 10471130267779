import React,{useState,useEffect}  from 'react';
import CommonHeader from '../assets/common-header';
import { fetchfaqList } from '../redux/actions/faqs';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../assets/loader';

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="my-2" >
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="text-left w-full text-[#fefefe] hover:text-blue-500 font-semibold py-2 px-4 focus:outline-none"
      >
        {question}
      </button>
      {isOpen && (
        <div className="text-[#fefefe8a] text-sm px-4 pb-2">
          <h4 >{answer}</h4>
        </div>
      )}
    </div>
  );
};

const FAQs = () => {

  const dispatch = useDispatch();
  const faqsData = useSelector(state => state.faqs.faqs);
  
  
  useEffect(() => {
      dispatch(fetchfaqList());
  },[dispatch]);

  if (!faqsData || !faqsData.length) {
    return <Loader/>
  }

  return (
    <>
    <CommonHeader/>
    <div className ='p-3 bg-[#232627] text-[#fefefe]'>
    <div className="container py-5">
      <h1 className="text-left mb-5 w-full text-blue-400 text-shadow-blue-glow">Frequently Asked Questions(FAQs)</h1>
      <div className="accn" id="faqsAccordion">
        {faqsData.map((item,index) => (
          <div key={index}>
            <h6 style={{ color: '#fefefe', padding:'8px 0px',fontSize:"25px",fontWeight:"600" }}>{ Object.keys(item)[0].replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}</h6>
            {item[Object.keys(item)[0]].map((faq, idx) => (
              <FAQItem key={idx} question={faq.question} answer={faq.answer} />
            ))}
          </div>
        ))}
      </div>
    </div>
    </div>
    </>
  );
};

export default FAQs;

