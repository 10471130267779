import React, { useState } from 'react'
import SettingCheckbox from './SettingCheckbox' // Adjust the path if necessary
import { Button } from '@material-ui/core'

const DashboardSettings = () => {
  const checkboxClasses = {
    checked: "!text-green-500",
    unChecked: "!text-gray-500"
  }

  const [settings, setSettings] = useState({
    showStrengthAnalysis: true,
    showRelativePercentile: true,
    showDailyProgress: true,
    showOverallProgress: true
  })

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target
    setSettings(prevSettings => ({
      ...prevSettings,
      [name]: checked
    }))
  }

  const handleSaveChanges = ()=> {
    console.log("Saving Changes...")
  }

  return (
    <div className='p-2'>
      <div className='text-gray-100 mb-4'>
        <h2 className='text-2xl'>Settings</h2>
        <span className='text-sm text-gray-400 font-light'>Manage your public dashboard settings and preferences</span>
        <hr class="h-px my-2 bg-gray-200 border-0 dark:bg-gray-700"/>
      </div>

      <div className='flex flex-col gap-4'>
        <SettingCheckbox 
          name="showStrengthAnalysis"
          checked={settings.showStrengthAnalysis}
          onChange={handleCheckboxChange}
          label="Show Strength Analysis"
          checkedClass={checkboxClasses.checked}
          uncheckedClass={checkboxClasses.unChecked}
        />

        <SettingCheckbox 
          name="showRelativePercentile"
          checked={settings.showRelativePercentile}
          onChange={handleCheckboxChange}
          label="Show Relative Percentile"
          checkedClass={checkboxClasses.checked}
          uncheckedClass={checkboxClasses.unChecked}
        />

        <SettingCheckbox 
          name="showDailyProgress"
          checked={settings.showDailyProgress}
          onChange={handleCheckboxChange}
          label="Show Daily Progress"
          checkedClass={checkboxClasses.checked}
          uncheckedClass={checkboxClasses.unChecked}
        />

        <SettingCheckbox 
          name="showOverallProgress"
          checked={settings.showOverallProgress}
          onChange={handleCheckboxChange}
          label="Show Overall Progress"
          checkedClass={checkboxClasses.checked}
          uncheckedClass={checkboxClasses.unChecked}
        />
      </div>

      <Button className='!capitalize !mt-6 !bg-gray-200' size='small' variant='contained' onClick={handleSaveChanges}>Save Changes</Button>
    </div>
  )
}

export default DashboardSettings
