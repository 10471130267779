import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank"
import CheckBoxIcon from "@mui/icons-material/CheckBox"
import React, { useState, useEffect } from "react"
import { Button, Tooltip } from "@mui/material"
import { FilterIcon } from "@heroicons/react/outline"
import { Cancel } from "@mui/icons-material"

const styles = {
  filterContainer: {
    position: "absolute",
    zIndex: "999",
    backgroundColor: "#232627",
    border: "0.5px solid #FEFEFE1A",
    borderRadius: "4px",
    width: "400px",
    height: "clamp(25vh, 300px, 50vh)",

    color: "#fefefe",
    marginTop: "5px",
    display: "flex",
    flexDirection: "column",
    padding: "8px",
  },
  contentArea: {
    overflowY: "auto", // Make only this area scrollable
    flexGrow: 1, // Allow it to take up available space
  },
  header: {
    display: "flex",
    justifyContent: "flex-start",
    paddingBottom: "0.275rem",
    borderBottom: "1px solid #141718",
    marginLeft: "2px",
    marginRight: "2px",
    flexWrap: "wrap",
    gap: "0.2rem",
  },
  tab: {
    cursor: "pointer",
    padding: "0.2rem",
    borderRadius: "8px",

    backgroundColor: "#343839",
    fontSize: "0.75rem",
    margin: "3px",
  },
  activeTab: {
    backgroundColor: "#141718",
    color: "#21618C",
  },
  selectedStyle: {
    color: "#D6EAF8",
    cursor: "pointer",
    margin: 0,
    padding: "0.2rem",
  },
  submitButton: {
    padding: "0.2rem 0.3rem",
    borderRadius: "8px",
    border: "none",
    backgroundColor: "#4CAF50",
    color: "white",
    cursor: "pointer",
    fontSize: "0.875rem",
    fontWeight: 500,
    alignSelf: "center", // Center the button
  },
  optionStyle: {
    cursor: "pointer",
    margin: 0,
    padding: "0.275rem",
    fontWeight: "500",
    fontSize: "0.875rem",
  },
}

const Filter = ({ topics = [], industries = [], onSubmit, selectedTopic = [], selectedIndustry = [], handleShowBadge }) => {
  const [selectedTopics, setSelectedTopics] = useState(selectedTopic)
  const [selectedIndustries, setSelectedIndustries] = useState(selectedIndustry)
  const [activeTab, setActiveTab] = useState("topics")

  const toggleSelection = (item, list, setList) => {
    const index = list.indexOf(item)
    if (index > -1) {
      setList(list.filter((_, i) => i !== index))
    } else {
      setList([...list, item])
    }
  }

  const handleSubmit = () => {
    onSubmit({ functions: selectedTopics, industries: selectedIndustries })
  }

  useEffect(() => {
    handleShowBadge(selectedTopics.length > 0)
  }, [selectedTopics])

  return (
    <div>
      <div style={styles.filterContainer}>
        {/* Scrollable content area */}
        {selectedTopics.length > 0 && (
          <nav style={styles.header}>
            {selectedTopics.map((topic) => (
              <Button
                key={topic}
                style={{ fontFamily: "inherit" }}
                variant="outlined"
                className="!font-normal !text-xs !px-2 !py-1 !text-gray-400 !rounded-xl !border-gray-600"
                endIcon={<Cancel />}
                onClick={() => toggleSelection(topic, selectedTopics, setSelectedTopics)}>
                {topic}
              </Button>
            ))}
            {/*<Button
            variant="contained"
            size="small"
            className="!text-xs !capitalize"
            //style={{ fontFamily: "inherit" }}
            style={{ ...styles.tab, ...(activeTab === "topics" && styles.activeTab) }}
            onClick={() => setActiveTab("topics")}>
            Topics
          </Button>
          <Button
            variant="contained"
            size="small"
            className="!text-xs !capitalize"
            style={{ ...styles.tab, ...(activeTab === "industries" && styles.activeTab) }}
            onClick={() => setActiveTab("industries")}>
            Industries
          </Button>*/}
          </nav>
        )}
        <div style={styles.contentArea}>
          {/* Header section */}

          {/* Content section */}
          {activeTab === "topics" && (
            <div>
              {topics.map((topic) => (
                <button
                  key={topic.name}
                  className="text-sm leading-6 text-gray-400"
                  onClick={() => toggleSelection(topic.name, selectedTopics, setSelectedTopics)}
                  style={selectedTopics.includes(topic.name) ? styles.selectedStyle : styles.optionStyle}>
                  {selectedTopics.includes(topic.name) ? <CheckBoxIcon className="!text-sm" /> : <CheckBoxOutlineBlankIcon className="!text-sm" />}
                  {topic.name}
                </button>
              ))}
            </div>
          )}

          {activeTab === "industries" && (
            <div>
              {industries.map((industry) => (
                <button
                  key={industry.name}
                  className="text-sm leading-6 text-gray-400"
                  onClick={() => toggleSelection(industry.name, selectedIndustries, setSelectedIndustries)}
                  style={selectedIndustries.includes(industry.name) ? styles.selectedStyle : styles.optionStyle}>
                  {selectedIndustries.includes(industry.name) ? <CheckBoxIcon className="!text-base" /> : <CheckBoxOutlineBlankIcon className="!text-base" />}
                  {industry.name}
                </button>
              ))}
            </div>
          )}
        </div>

        {/* Submit button - Fixed at the bottom */}
        <div style={{ marginLeft: "auto", background: "transparent", padding: "3px" }}>
          <Tooltip title={selectedTopics.length === 0 ? "Select Functions to Filter" : ""}>
            <Button
              variant="contained"
              size="small"
              disabled={selectedTopics.length === 0}
              style={{ fontFamily: "inherit" }}
              className="!bg-emerald-600 !text-xs !capitalize !text-gray-200"
              onClick={handleSubmit}>
              <FilterIcon className="h-3 w-3" /> Filter
            </Button>
          </Tooltip>
        </div>
      </div>
    </div>
  )
}

export default Filter
