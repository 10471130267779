import dataDashboard from '../assets/image_files/dataDashboard.svg'
import AI from '../assets/image_files/AI.svg'
import Questions from '../assets/image_files/Questions.svg'
import { Zoom } from 'react-toastify';

const SUBJECTS = ['SQL', 'Excel', 'Python']
const INDUSTRIES = ['e-commerce', 'Digital Marketing']
const LEVELS = 10
const LANGUAGES = [
    // {key : 'sql', value : 'SQL'},
    // {key : 'mysql', value : 'SQL'},
    {key : 'text/x-pgsql', value : 'PostgreSQL'},
    // {key : 'mssql', value : 'MSSQL'},
    // {key : 'sqlite', value : 'SQLite'},
]
const features = [
  {key :'Explore',
    title:'Explore',
    imageLink: dataDashboard,
    values:[
      'Real data created with AI',
      'Data across Industries & verticals (ecommerce, Healthcare, Finance, supply chain, IC etc.)',
      'Personalized learning with Adaptive AI algorithm'
    ]
  },
  {key :'Solve',
    title:"Solve",
    imageLink:AI,
    values:[
      'AI generated Fascinating business cases ',
      'Problems focused on data complexity and business insights ',
      'Problems with different complexity levels'
    ]
  },
  {
    key:'Earn',
    title:"Earn",
    imageLink:Questions,
    values:[
      'Performance score with AI Powered validation on Excel, SQL & Python',
      'Attention by showcasing progress & performance ',
      'Confidence by Improving skills with SW analysis'
    ]
  }

]

const aboutLinks = [
  { label: 'Our Story', path: '/about' },
  { label: 'Blog', path: '/blog' },
  { label: 'References', path: '/references' }
];
const usefulLinks = [
  { label: 'Terms', path: '/terms' },
  { label: 'Privacy', path: '/privacy' }
];
const knowMoreLinks = [
  { label: "FAQ's", path: '/preloginfaqs' },
  { label: 'Recruit With Us', path: '/recruit' }
];
const socialLinks = [
  { label: 'Facebook', path: '/facebook' },
  { label: 'TwitterX', path: '/twitter' },
  { label: 'Instagram', path: '/instagram' },
  { label: 'LinkedIn', path: '/linkedin' }
];

const toastConfig = {
  position: "top-center",
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
  transition: Zoom,
};

export {
features,
SUBJECTS,
INDUSTRIES,
LEVELS,
LANGUAGES,
aboutLinks,
knowMoreLinks,
socialLinks,
usefulLinks,
toastConfig
}