import React, { useState } from 'react';
import { Badge, Button, Tooltip } from '@mui/material';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { useNavigate } from 'react-router-dom';

const PremiumButton = ({ questionsSolved, maxFreeQuestions = 50 }) => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false); // State to track hover
  const percentageUsed = (questionsSolved / maxFreeQuestions) * 100;
  const questionsLeft = maxFreeQuestions - questionsSolved;

  const handleSubscriptionClick = () => {
    navigate('/pricing'); // Redirect to subscription page
  };

  // Mouse hover handlers
  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <Tooltip title={``} arrow>
      <Button
        variant="outlined"
        className="!text-sm !text-gray-400 !capitalize !px-2 !py-1.5 hover:!text-gray-200"
        onClick={handleSubscriptionClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        startIcon={<LockOpenIcon className="!text-base"/>}
        style={{
            fontFamily: "inherit",
            border: isHovered ? "0.5px solid #45c2709a" : "none",
            cursor: "pointer",
            background: isHovered
              ? `linear-gradient(to right, #45c2709a, transparent ${percentageUsed}%)` // Fading effect on hover
              : 'transparent', // Normal background when not hovered
            transition: 'background 0.6s ease', // Smooth transition for hover
          }}
        >
        Premium
        <span className='!text-xs !text-gray-300 !bg-[#343839] !rounded !capitalize px-2 py-0.5'>{50 - questionsLeft}/50</span>
      </Button>
    </Tooltip>
  );
};

export default PremiumButton;
