import React from 'react'
import './InsightCard2.css'
import './InsightCard.css'
import './Newsletter.css'
const InsightCard = ({ title, data, varient }) => {
  const dataList = data.split("\n").map((item, index) => (
    <li key={index} className="mb-4 px-3">
      {item.trim()}
    </li>
  ));
  return (
    <div className='shiny-border insightCard'>
      <div className="notiglow"></div>
      <div className="notiborderglow"></div>
      <div className={`myCard h-full ${varient}`}>
        <div className="content">
          <div className="title">{title}</div>
          <div className="description text-slate-300">
            <ul className='ml-3 flex-1 flex flex-col justify-around list-outside list-disc pl-8 font-light'>
              {dataList}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InsightCard