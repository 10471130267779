import React, { useState } from 'react'
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { useNavigate } from 'react-router-dom';
import Modal from '../../../assets/modal';
import { Button } from '@material-ui/core';
const RoadMapTimeline = ({ arenaName, roadmapName, roadmapQuestions, isUserEnrolled }) => {
    const [isOpen, setIsOpen] = useState(false)
    const colors = ["#f43f5e", "#ec4899", "#a855f7", "#6366f1", "#06b6d4", "#22c55e", "#eab308", "#ef4444"]
    const getRandomColor = () => {
        const randomIndex = Math.floor(Math.random() * colors.length);
        return colors[randomIndex];
    };

    const navigate = useNavigate()
    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                title="Enrollment Required"
            >
                <p className='text-gray-400 text-sm mt-2'>To solve the questions, you first need to enroll in the {arenaName} Arena.</p>
                <div className='flex gap-2 mt-4'>
                    <Button variant='contained' size='small' className='!shadow-none !bg-gray-600 !text-gray-200 !border-0'>Enroll</Button>
                </div>
            </Modal>
            <Timeline position="alternate-reverse" className='!p-0 overflow-auto'>
                {
                    roadmapQuestions.map((question, index) => {
                        return (
                            <TimelineItem key={question.questionId}>
                                <TimelineSeparator>
                                    <TimelineDot sx={{ bgcolor: getRandomColor() }} />
                                    <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent className='!text-gray-300 hover:bg-gray-800 hover:bg-opacity-70 rounded-md transition-all cursor-pointer'
                                    onClick={() => {
                                        if (isUserEnrolled) {
                                            navigate(`/arena-question/${arenaName}/${roadmapName}?questionId=${question.questionId}`)
                                        }else{
                                            setIsOpen(true)
                                        }

                                    }}
                                >
                                    <span className="relative mb-3 inline-flex transistion overflow-hidden rounded-lg p-[1px] focus:outline-none">
                                        <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#e7029a_0%,#f472b6_50%,#bd5fff_100%)]">
                                        </span>
                                        <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-lg bg-black bg-opacity-80 text-sm font-medium text-white backdrop-blur-3xl gap-2 p-1">
                                            {question?.industry}
                                        </span>
                                    </span>

                                    <p className='text-sm'>{`${question.ProblemStatement.substring(0, 100)}...`}</p>

                                    <div className='my-3'>
                                        <ul className={`flex gap-2 flex-wrap ${index % 2 === 0 ? "card-left" : "card-right"}`}>
                                            {question.SQLFunctions && question.SQLFunctions.map((functionItem) => <li
                                                className={`p-0 text-xs me-2 px-2 py-1 rounded-md bg-red-600 bg-opacity-40`}
                                            >{functionItem.slice(0, 16)}</li>)}
                                            {question.ExcelFunctions && question.ExcelFunctions.map((functionItem) => <li
                                                className={`p-0 text-xs me-2 px-2 py-1 rounded-md bg-red-600 bg-opacity-40`}
                                            >{functionItem.slice(0, 16)}</li>)}
                                            {question.PythonFunctions && question.PythonFunctions.map((functionItem) => <li
                                                className={`p-0 text-xs me-2 px-2 py-1 rounded-md bg-red-600 bg-opacity-40`}
                                            >{functionItem.slice(0, 16)}</li>)}
                                        </ul>
                                    </div>
                                </TimelineContent>
                            </TimelineItem>
                        )
                    })
                }
            </Timeline>
        </>
    )
}

export default RoadMapTimeline