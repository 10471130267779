import React, { useState, useEffect } from "react"
import "chart.js/auto"
import CommonHeader from "../assets/common-header"
import { useDispatch, useSelector } from "react-redux"
import { fetchUserDetails } from "../redux/actions/user"
import "../assets/css_files/dashboard.css"
import DashboardStackedBarChart from "../assets/progress-barchart.js"
import DashboardPieChart from "../assets/progress-piechart.js"
import SWAccuracyChart from "../assets/strength-chart.js"
import LeaderBoard from "../assets/leader-board.js"
import Fchart from "../assets/frequency-chart.js"
import { fetchUserMetrics } from "../redux/actions/usermetrics.js"
import { fetchArenasList } from "../redux/actions/arena.js"

const PersonalDashboard = () => {
  const [interval, setInterval] = useState("Daily")
  const [metric, setMetric] = useState("Solved")

  const dispatch = useDispatch()

  const reduxUser = useSelector((state) => state.user.userDetails)
  const userMetrics = useSelector((state) => state.userMetrics.userMetrics)
  const enrolledArenas = useSelector((state) => state.arena.enrolledArenas)

  const [user, setUser] = useState(null)
  const [rewardPoints, setRewardPoints] = useState("")

  const [active, setActive] = useState("lboard")

  const [filterBy, setFilterBy] = useState("Free Style")
  const [filterParams, setFilterParams] = useState({ filterType: "Free Style", arenaId: "", roadmapName: "" })
  const [mode, setMode] = useState("Free Style")

  const handleActive = () => {
    if (active === "lboard")
      return (
        <LeaderBoard
          userRewardPoints={(data) => setRewardPoints(data)}
          filterParams={filterParams}
        />
      )
    else if (active === "schart")
      return (
        <SWAccuracyChart
          forPublic={false}
          filterParams={filterParams}
        />
      )
    else
      return (
        <Fchart
          userRewardPoints={(data) => setRewardPoints(data)}
          currUserData={() => {}}
          forPublic={false}
          filterParams={filterParams}
        />
      )
  }

  useEffect(() => {
    dispatch(fetchUserDetails())
    dispatch(fetchUserMetrics())
    dispatch(fetchArenasList())
    setUser(reduxUser)
  }, [dispatch])

  const handleFilterChange = (value) => {
    if (value === "Free Style") {
      setFilterParams({ filterType: "Free Style", arenaId: "", roadmapName: "" })
    } else if (value.includes("|")) {
      const [arenaId, roadmapName] = value.split("|")
      setFilterParams({ filterType: "Roadmap", arenaId, roadmapName })
    } else {
      setFilterParams({ filterType: "Arena", arenaId: value, roadmapName: "" })
    }
    setFilterBy(value)
  }

  
  const handleMode = () => {
    if (mode === "Free Style") {
      handleFilterChange(enrolledArenas[0]._id)
      setMode("Arena")
    } else {
      handleFilterChange("Free Style")
      setMode("Free Style")
    }
  }

  useEffect(()=>{
    console.log("redux user", reduxUser)
    console.log("matrix", userMetrics)
    console.log("enrolled ", enrolledArenas)
  },[])

  return (
    <div className="dashboard-container">
      <CommonHeader />
      {!userMetrics ? (
        <div className="dashboard-content flex-grow">
          <h1>Solve problems to level up!</h1>
        </div>
      ) : (
        <div className="dashboard-page">
          <nav className="dashboard-nav">
            <button
              onClick={handleMode}
              className={`toggle-button ${mode === "Free Style" ? "active" : ""}`}
              disabled={enrolledArenas.length > 0 ? false : true}>
              <span className="px-7">{mode === "Free Style" ? "Free Style" : "Arena"}</span>
              <div className="toggle-indicator"></div>
            </button>
            {mode === "Arena" && (
              <select
                value={filterBy}
                onChange={(e) => handleFilterChange(e.target.value)}
                className="drop-down-container interval">
                {enrolledArenas &&
                  enrolledArenas.map((arena) => (
                    <optgroup
                      key={arena.arenaId}
                      label={arena.name}>
                      <option
                        style={{ color: "#fefefe", fontWeight: "bold" }}
                        value={arena._id}>
                        {arena.name}
                      </option>
                      {arena.roadmaps.map((roadmap) => (
                        <option
                          key={roadmap.name}
                          value={`${arena._id}|${roadmap.name}`}>
                          {roadmap.name}
                        </option>
                      ))}
                    </optgroup>
                  ))}
              </select>
            )}
          </nav>
          {/*<nav className="dashboard-nav2"></nav> */}

          <div className="dashboard-content">
            {/* Bar Chart */}
            <div className="dashboard-left">
              <div
                className="chart-parameters-container"
                style={{ width: "100%", backgroundColor: "transparent", border: "none", margin: 0, padding: 0 }}>
                <select
                  value={interval}
                  onChange={(e) => setInterval(e.target.value)}
                  className="drop-down-container interval"
                  style={{ width: "100%", borderTopLeftRadius: "16px", padding: "0.3rem", height: "2.1rem" }}>
                  <option value="Daily">Daily</option>
                  <option value="Weekly">Weekly</option>
                  <option value="Monthly">Monthly</option>
                </select>
                <select
                  value={metric}
                  onChange={(e) => setMetric(e.target.value)}
                  style={{ width: "100%", borderTopRightRadius: "16px", padding: "0.3rem", height: "2.1rem" }}
                  className="drop-down-container interval">
                  <option value="Attempted">Problems Attempted</option>
                  <option value="Solved">Problems Solved</option>
                </select>
              </div>
              <div className="dashboard-background bar-chart">
                <DashboardStackedBarChart
                  interval={interval}
                  metric={metric}
                  forPublic={false}
                  filterParams={filterParams}
                />
              </div>
              <div className="dashboard-background pie-chart">
                <div className="pie-chart-center-container">
                  <DashboardPieChart
                    metric={metric}
                    forPublic={false}
                    filterParams={filterParams}
                  />
                </div>
              </div>
            </div>
            <div className="dashboard-right">
              <div
                className="nav-rewards-container"
                style={{ justifyContent: "space-evenly", width: "100%", backgroundColor: "transparent", border: "none", margin: "0.1rem", padding: 0 }}>
                <button
                  className={`sw-analysis-btn ${active === "schart" ? "active-btn" : ""}`}
                  style={{ borderTopLeftRadius: "16px" }}
                  value="schart"
                  onClick={(e) => setActive(e.target.value)}>
                  Strength Analysis
                </button>
                <button
                  className={`sw-analysis-btn ${active === "fchart" ? "active-btn" : ""}`}
                  value="fchart"
                  onClick={(e) => setActive(e.target.value)}>
                  Relative Percentile
                </button>
                <button
                  className={`sw-analysis-btn ${active === "lboard" ? "active-btn" : ""}`}
                  value="lboard"
                  style={{ borderTopRightRadius: "16px" }}
                  onClick={(e) => setActive(e.target.value)}>
                  Leader Board
                </button>
              </div>
              <div
                className={`dashboard-background ${active === "lboard" ? "leader-board" : "fchart-container"}`}
                style={{ display: active === "lboard" && "flex", flexDirection: active === "lboard" && "column" }}>
                {handleActive()}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default PersonalDashboard
