import React, { useState } from 'react';
import {isEmailValid} from '../../utils/common-function';
import { toast } from "react-toastify";
import { recruitFormData } from '../../utils/apis';
import { isValidUrl } from '../../utils/common-function';

const RecruitForm = () => {
  
  const [name, setName] = useState('');
  const [experience, setExperience] = useState('');
  const [links, setLinks] = useState(['']); 
  const [email, setEmail] = useState('');
  const [number, setNumber] = useState('');

  const handleLinkChange = (index, event) => {
    const newLinks = [...links];
    newLinks[index] = event.target.value;
    setLinks(newLinks);
  };

  const addLinkInput = () => {
    const newLinks = [...links];
    const latestLink = newLinks[newLinks.length -1];
    
    if (!isValidUrl(latestLink)){
      toast.error(`${latestLink} is not a valid URL`);
      newLinks.pop();
      setLinks(newLinks);
    }
    else {
      setLinks([...links, '']);  
    }
    
  };

  const handleSubmit = async(event) => {
    event.preventDefault();
    console.log({name,experience,email,links,number});
    if(!isEmailValid(email)){
        toast.error('Email is not Valid');
    }
    if(!name || !experience || !email || !links || !number){
      toast.error('All the fields are mandatory');
    }
    
    try {
      const response = await recruitFormData({name,experience,email,links,number});

      if(response.status === 200){
        toast.success(`Dear ${name}\n Welcome to Marma.ai\n Further instructions will be conveyed through email.`)
      }

    }catch(e){
      console.log(e);
      toast.error('Unable to submit your details, try again later!');
    }finally {
      setEmail('');
      setExperience('');
      setLinks(['']);
      setName('');
      setNumber('');
    }
  };

  return (
    <div className="recruit-form-page page-background">
    <div 
    className="recruit-form my-24 w-full max-w-xl mx-auto "
    
    > 
    <div className="card glowing-card" >
      <h1 className="text-2xl font-bold ">Start recruiting with MARMA AI</h1>
      <h2  className="block  text-sm font-bold mb-2">Tell us a bit more about you: </h2>
      
      <form onSubmit={handleSubmit} className="mb-4">
        <div className="mb-4">
          <input
            type="text"
            id="name"
            className="input-field"
            placeholder="Company's Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="mb-4">
          <textarea
            id="experience"
            className="input-field"
            rows="4"
            placeholder="Tell us about your Company"
            value={experience}
            onChange={(e) => setExperience(e.target.value)}
            required
          />
        </div>
        <div className="mb-4 flex flex-col">
        {links.map((link, index) => (
        <input
          key={index}
          type="text"
          value={link}
          onChange={(e) => handleLinkChange(index, e)}
          className="input-field"
          placeholder="Enter URL's you wish to share"
        />
      ))}
      <button
        type="button"
        onClick={() => addLinkInput()} // Logic to add more link fields
        className="button  font-semibold hover:font-bold mr-auto"
      >
        Add another link
      </button>
          
        </div>
        <div className="mb-4">
          <input
            type="email"
            className="input-field"
            placeholder="Contact Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="mb-6">
          <input
            type="tel"
            className="input-field"
            placeholder="Contact Number"
            value={number}
            onChange={(e) => setNumber(e.target.value)}
            required
          />
        </div>
        <div className="flex items-center justify-between">
          <button
            type="submit"
            className="button text-white  font-semibold hover:font-bold"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
    </div>
    <p className="text-center text-xl font-bold mb-3">
        Thank you for being part of the revolution. We will get in touch with you shortly
      </p>
    </div>
  );
};

export default RecruitForm;
