import { useState } from "react"
import "./css_files/Sidebar.css"
import { CancelRounded } from "@mui/icons-material"
import { Chrono } from "react-chrono"
import { makeStyles } from "@material-ui/core/styles"
import { Backdrop, Drawer } from "@mui/material"

const useStyles = makeStyles(() => ({
  button: {
    background: "none",
    border: "none",
    color: "inherit",
    textAlign: "center",
    padding: "0.2rem",
    cursor: "pointer",
    width: "100%",

    borderRadius: "8px",
  },
  hidden: {
    display: "none",
  },
}))

function QuestionTimeline({ questions, currentQuestionId, handleClick }) {
  const classes = useStyles()
  const [visibleIndex, setVisibleIndex] = useState(null)

  const handleIconClick = (cardItem) => {
    setVisibleIndex(cardItem.index)
  }

  const items = questions.map((question, index) => ({
    icon: (
      <div
        style={{
          width: "20px",
          height: "20px",
          borderRadius: "50%",
          backgroundColor: question.questionId === currentQuestionId ? "#000" : "#47ab50",
        }}
        onClick={() => handleIconClick(index)}
      />
    ),
  }))
  console.log({ questions })

  return (
    <div style={{ width: "100%", height: "90vh" }}>
      <Chrono
        mode="VERTICAL"
        theme={{
          cardBgColor: "transparent",
          cardForeColor: "#fefefe",
          primary: "#fefefe1a",
          secondary: "#fefefe",
        }}
        style={{
          minWidth: "400px",
        }}
        disableToolbar
        scrollable={{ scrollbar: true }}
        cardHeight={""}
        cardWidth={"400"}
        className="chrono-container"
        useReadMore={true}
        items={items} // Enable read more functionality
        onItemSelected={handleIconClick}>
        {questions.map((quesItem, index) => (
          <div key={quesItem.questionId}>
            <button
              className={classes.button}
              onClick={() => handleClick(quesItem.questionId)}
              style={{
                display: visibleIndex === index || visibleIndex === index - 1 || visibleIndex === index + 1 ? "block" : "none",
                backgroundColor: quesItem.isVisited ? "#47ab508a" : visibleIndex === index ? "#2c2012" : "",
                color: visibleIndex === index ? "#ff9f2a" : "#fefefe",
              }}>
              {visibleIndex === index ? quesItem.ProblemStatement : `${quesItem.ProblemStatement.slice(0, 100)}...`}
            </button>
          </div>
        ))}
      </Chrono>
    </div>
  )
}

/*function QuestionTimeline({ questions, currentQuestionId, handleClick }) {
  

  return (
    <div style={{ width: "100%", height: "90vh" }}>
      <Chrono
        mode="VERTICAL"
        theme={{
          cardBgColor: "#232627",
          cardForeColor: "#fefefe",
          primary: "#fefefe1a",
          secondary: "#fefefe",
        }}
        style={{
          minWidth: "400px",
        }}
        disableToolbar
        scrollable={{ scrollbar: true }}
        cardHeight={"200"}
        cardWidth={"400"}
        className="chrono-container"
        classNames={{
          card: "chrono-card",
        }}
        useReadMore={true} // Enable read more functionality
      >
        {questions.map((quesItem) => (
          <div>
            <button onClick={() => handleClick(quesItem.questionId)}>{quesItem.ProblemStatement}</button>
          </div>
        ))}
      </Chrono>
    </div>
  )
} */

const Sidebar = ({ isOpen, onClose, questionStatements, arenaName, roadmapName, handleQuestionHistory, currQuestionId }) => {
  const [activeCurrId, setActiveCurrId] = useState(currQuestionId)
  
  const useStyles = makeStyles({
    paper: {
      backgroundColor: '#191919'
    },
  });

  const classes = useStyles()
  const handleClick = (questionId) => {
    setActiveCurrId(questionId)
    handleQuestionHistory(questionId)
  }

  return (
    <Drawer open={isOpen} onClose={onClose} anchor="right" classes={{ paper: classes.paper }}>
      <div className="px-4 py-2 bg-[#171721] flex items-center justify-between text-gray-200">
        <h4 style={{ fontWeight: "bold" }}>
          {arenaName} - {roadmapName}
        </h4>
        <button
          className="close-btn"
          onClick={onClose}>
          <CancelRounded />
        </button>
      </div>
      <QuestionTimeline
        questions={questionStatements}
        currentQuestionId={currQuestionId}
        handleClick={handleClick}
      />
    </Drawer>
  )
}

export default Sidebar
