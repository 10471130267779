import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Button } from '@material-ui/core';
import RoadMapTimeline from './RoadMapTimeline';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export default function RoadMapsTab({ currentArena, enrollButton }) {
    const [value, setValue] = React.useState(0);
    
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    console.log(currentArena)
    return (
        <Box
            sx={{ flexGrow: 1, display: 'flex' }}
            className="rounded-md my-2"
        >
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{
                    borderRight: 1, 
                    borderColor: 'divider', 
                    position: 'sticky', 
                    top: 20, 
                    height:"100%",
                    backgroundColor: '#191919'
                  }}
                TabIndicatorProps={{ style: { background: '#D0AA45', color: "#D0AA45" } }}
            >
                {currentArena?.roadmaps &&
                    currentArena?.roadmaps?.map((roadmap) => (
                        <Tab label={roadmap?.name + " Roadmap"} key={roadmap?.name} className='!p-2 !capitalize'
                            sx={{
                                '&.Mui-selected': {
                                    color: '#fff !important',
                                },
                                fontFamily: "inherit",
                                color: "gray !important"
                            }}
                        >
                        </Tab>
                    ))}
            </Tabs>

            {
                currentArena?.roadmaps?.map((roadmap, i) => {
                    return (
                        <TabPanel value={value} index={i} className="overflow-auto w-full px-5">
                            <div className='flex justify-between items-center mb-3'>
                                <h2 className='text-gray-200 text-base'>{roadmap?.name} Roadmap</h2>
                                {enrollButton}
                            </div>
                            <p className='text-sm text-gray-400'>
                                {roadmap?.objective && roadmap?.objective}
                                {!roadmap?.objective && <>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet voluptatibus odit non vel ipsum qui, ab, distinctio pariatur facilis totam sequi eaque aliquam vitae. Aliquam et quia quidem, quam ducimus, exercitationem ad quisquam sint quo laborum delectus. Ratione laborum incidunt dolorem quo vitae consequatur obcaecati, harum cum velit alias doloremque voluptate tempore minima magnam porro iste totam deserunt commodi sit perferendis quis perspiciatis. Error fugit repudiandae ipsam magni ab debitis.
                                </>}
                            </p>
                            <h2 className='text-gray-200 text-base my-3'> Questions</h2>

                            <RoadMapTimeline arenaName={currentArena?.name} roadmapName={roadmap?.name} roadmapQuestions={roadmap?.questionStatements} isUserEnrolled={currentArena?.userEnrolled} />
                        </TabPanel>
                    )
                })
            }
        </Box>
    );
}
