import React, { useState } from 'react';
import Table from '../assets/table.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeOpen } from '@fortawesome/free-solid-svg-icons'

import { Button, MenuItem, Tooltip } from '@mui/material';
import Modal from './modal.js';
import ShareOnSocial from 'react-share-on-social';
import { marmaOnlyLogo } from './image_files/index.js';
import ShareIcon from '@mui/icons-material/Share';
import Dropdown from '../components/ui/Dropdown.jsx';

const HintComponent = ({ hintsArray }) => {

  return (
    <div>

      <ul className="tooltip-component text-sm">
        {hintsArray.map(item => <li className='mb-2 text-gray-400'>{item}</li>)}
      </ul>
    </div>
  )
}



const QuestionComponent = ({ questionMeta, techStack, onSubmit, onChangeTechStack, techStacks, primaryTool }) => {

  const [showTable, setShowTable] = useState(true);
  const [showTemplate, setShowTemplate] = useState(false);
  const [showHint, setShowHint] = useState(false);
  const [activeTable, setActive] = useState(0);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [confirmationModalMessage, setConfirmationModalMessage] = useState('');
  const [submittedKey, onSetSubKey] = useState('');
  const [pendingTechStack, setPendingTechStack] = useState('')




  const confirmUpdate = async (confirm) => {
    setShowConfirmationModal(false);
    if (confirm) {
      if (submittedKey) {
        onSubmit(submittedKey)
        onSetSubKey('');
      }
      if (pendingTechStack) {
        onChangeTechStack(pendingTechStack)
        setPendingTechStack('')
      }
    } else {
      onSetSubKey('');
      setPendingTechStack('')
    }
  };
  const handleTechStackChange = (techStack) => {
    const value = techStack
    setPendingTechStack(value)
    setShowConfirmationModal(true)
    setConfirmationModalMessage(`Are you sure you want to attempt this question in ${value} techstack?`);

  }

  const handleActiveTable = (tableheading, index) => {
    setActive(index);
    setShowTable(true);
    setShowTemplate(false);
    setShowHint(false);
  }
  const handleTemplate = () => {
    setShowTable(false);
    setShowTemplate(true);
    setShowHint(false);
  }
  const handleHint = () => {
    setShowHint(true);
    setShowTable(false);
    setShowTemplate(false);
  }

  return (
    <>
    {
      showConfirmationModal &&
      <Modal
        isOpen={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
        title="Confirm Action"
      >
        <p className='text-gray-400 text-sm mt-2'>{confirmationModalMessage}</p>
        <div className='flex gap-2 mt-4'>
        <Button variant='contained' size='small' className='!shadow-none !bg-gray-600 !text-gray-200 !border-0' onClick={() => confirmUpdate(true)}>Yes</Button>
        <Button variant='outlined' size='small' className='!shadow-none !text-gray-200 !border !border-gray-600' onClick={() => confirmUpdate(false)}>No</Button>
          </div>
      </Modal>
    } 
    <div className="problem-statement">
      <div className='problem-description-container max-h-[500px]'>
        <div className='flex-row-bw-container problem-heading-container'>
          <div className='flex-row-container'>
            <h2 className='text-gray-200 text-base'>Situation</h2>
            <ShareOnSocial
              textToShare="Checkout this Question"
              link={window.location.href}
              linkTitle="Help me out to Solve this Question"
              linkMetaDesc="practice and solve industry grade problems"
              linkFavicon={marmaOnlyLogo}
              noReferer
            >
              <button className='hints-button rounded-full'><ShareIcon sx={{ height: "18px", width: "18px" }} /></button>
            </ShareOnSocial>
          </div>

            {
              questionMeta &&
              <Dropdown
              title="Also Available in"
              tooltip={`Solve the situation using different languages`}
              children=
                {techStacks.map((techStack, index) => (
                  techStack !== primaryTool ?
                  <MenuItem
                  onClick={()=>{handleTechStackChange(techStack)}}
                  className='!text-gray-200 !font-light !text-xs'
                  value={techStack} key={techStack}>{techStack}</MenuItem> : ''
                ))}
              />
            }
        </div>
        <p className='problem-statement-description p-0 text-sm leading-6 text-gray-400'>{questionMeta.ProblemStatement}</p>
      </div>

      <div className='utilities max-h-fit overflow-auto'>
        <div className='dataset-heading-list-container px-3 py-2'>
          {questionMeta && questionMeta?.Data.Tables && questionMeta?.Data.Tables[0] &&
            questionMeta.Data.Tables?.length !== 0 ?
            <ul className='table-headings flex justify-center items-center'>
              {questionMeta.Data.Tables.map((item, index) =>
                <li key={index}
                  onClick={() => handleActiveTable(item, index)}
                ><button className={`bg-gray-transparent text-gray-300 font-medium px-2 py-1.5 rounded text-sm mr-2 ${activeTable === index && 'bg-gray-700 text-gray-300'}`}>{item}</button></li>)}
            </ul> : ''

          }
          <div className='flex-row-container'>
            <Button
              variant='text'
              style={{ fontFamily: "inherit" }}
              className='
            !text-gray-400 hover:!bg-transparent hover:!text-gray-200 !font-medium !rounded-lg !text-sm !px-2 !py-1.5 !capitalize !mr-1'
              onClick={handleTemplate}> Answer Format
            </Button>

            <Button
              style={{ fontFamily: "inherit" }}
              variant='text'
              className='
            !text-gray-400 !font-medium hover:!text-yellow-600 hover:!bg-transparent !rounded-lg !text-sm !px-2 !py-1.5 !capitalize'
              endIcon={<FontAwesomeIcon className='!text-sm' icon={faEnvelopeOpen} />}
              onClick={handleHint}>Hints
            </Button>
          </div>
        </div>
        <div className='display-container p-3 w-full'>
          {showTable && <Table tablesData={questionMeta.tableDataList[activeTable]} tables={questionMeta.Data.Tables[activeTable]} />}
          {
            showTemplate && questionMeta && questionMeta.answerTemplate.count && questionMeta.answerTemplate.fields ?
              (<div>
                <h6 className='font-semibold text-gray-400 text-sm mb-2'>Columns Expected:</h6>
                <div className="flex flex-wrap">
                  {questionMeta.answerTemplate.fields.map((field, index) => (
                    <span
                      key={index}
                      className="mb-2 me-2 bg-gray-700 text-gray-300 text-sm font-normal py-1 px-2 rounded"
                    >
                      {field}
                    </span>
                  ))}
                </div>
                <div className='my-3'>
                  <h6 className='inline font-semibold text-gray-400 text-sm'>Total No of rows expected: <span className='text-gray-400 font-normal'> {questionMeta.answerTemplate.count}</span></h6>
                </div>
              </div>) : ''

          }
          {showHint && <HintComponent hintsArray={questionMeta[`${techStack}Hints`]} />}
        </div>





      </div>

    </div>
    </>


  );
};

export default QuestionComponent;