import React, { useState } from 'react';
import { isEmailValid, validateName, isValidUrl, validatePhoneNumber } from '../../utils/common-function';
import { toast } from "react-toastify";
import { arenaFormData } from '../../utils/apis';
import { Typography, CircularProgress, Box } from '@mui/material';
import { toastConfig } from '../../utils/constants.js'
import BackgroundDiv from '../background/BackgroundDiv.jsx';


const ArenaForm = () => {

  const [name, setName] = useState('');
  const [experience, setExperience] = useState('');
  const [links, setLinks] = useState(['']);
  const [email, setEmail] = useState('');
  const [number, setNumber] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false)



  //states for error handling
  //input error handling
  const [nameError, setNameError] = useState({ status: false, msg: "" })
  const [phoneNumberError, setPhoneNumberError] = useState({ status: false, msg: "" })


  const handleLinkChange = (index, event) => {
    const newLinks = [...links];
    newLinks[index] = event.target.value;
    setLinks(newLinks);
  };

  const addLinkInput = () => {
    const newLinks = [...links];
    const latestLink = newLinks[newLinks.length - 1];

    if (!isValidUrl(latestLink)) {
      toast.dismiss();
      toast.error(`${latestLink} is not a valid URL`,
        toastConfig
      );
      newLinks.pop();
      setLinks(newLinks);
    }
    else {
      setLinks([...links, '']);
    }

  };

  const checkValidName = (name) => {
    const response = validateName(name)
    setNameError(response)
    setName(name)
  }

  const checkValidPhoneNumber = (number) => {
    const response = validatePhoneNumber(number)
    setPhoneNumberError(response)
    setNumber(number)
  }


  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    console.log({ name, experience, email, links, number });
    if (!name || !experience || !email || !links || !number) {
      toast.dismiss();
      toast.error('All the fields are mandatory',
        toastConfig
      );
      setIsSubmitting(false);

      return
    }
    if (nameError.status) {
      toast.dismiss();
      toast.error(nameError.msg,
        toastConfig
      )
      setIsSubmitting(false);

      return
    }

    if (!isEmailValid(email)) {
      toast.dismiss();
      toast.error('Email is not Valid',
        toastConfig
      );
      setIsSubmitting(false);

      return
    }

    if (phoneNumberError.status) {
      toast.dismiss();
      toast.error(phoneNumberError.status,
        toastConfig
      )
      setIsSubmitting(false);
      return
    }

    try {
      const response = await arenaFormData({ name, experience, email, links, number });

      if (response.status === 200) {
        toast.dismiss();
        toast.success(`Dear ${name}\n Welcome to Marma.ai\n Thank you for being part of the revolution. We will get in touch with you shortly`,
          toastConfig
        )
      }

    } catch (e) {
      console.log(e);
      toast.dismiss();
      toast.error('Unable to submit your details, try again later!',
        toastConfig
      );
    } finally {
      setEmail('');
      setExperience('');
      setLinks(['']);
      setName('');
      setNumber('');
      setIsSubmitting(false)
    }
  };



  return (
    <BackgroundDiv>
      <div className="text-white flex justify-center items-center">

        <div className='container mx-auto'>
          <Typography
            variant="h4"
            component="h4"
            my={2}
            align="center"
            sx={{ fontWeight: "bold" }}
            fontFamily="inherit"
          >
            Wish to start your own Arena with MARMA AI
          </Typography>
          <form onSubmit={handleSubmit} className='myCard p-6 w-full sm:w-full md:w-[450px] m-auto'>
            <h2 className="text-sm font-medium mb-2">Tell us a bit more about you</h2>
            <div className='mb-2'>
              <input
                type="text"
                id="name"
                className="input"
                placeholder="Name"
                value={name}
                onChange={(e) => checkValidName(e.target.value)}
                required
              />

              {
                nameError.status &&
                <Typography my={1} color="#BB2C35" variant="subtitle2" fontFamily="inherit" gutterBottom>
                  {nameError.msg}
                </Typography>
              }

            </div>

            <div>
              <textarea
                id="experience"
                className="input"
                rows="4"
                placeholder="Mentorship Experience"
                value={experience}
                onChange={(e) => setExperience(e.target.value)}
                required
              />
            </div>

            <div className="">
              {links.map((link, index) => (
                <input
                  key={index}
                  type="text"
                  value={link}
                  onChange={(e) => handleLinkChange(index, e)}
                  className="input mb-3"
                  placeholder="Enter URL's you wish to share"
                />
              ))}
              <button
                className='cta-btn-outlined rounded-full'
                onClick={() => addLinkInput()} // Logic to add more link fields
              >
                Add Another Link
              </button>

            </div>

            <section className='my-3'>
              <div className='mb-2'>
                <input
                  type="email"
                  className="input"
                  placeholder="Contact Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>

              <div className='mb-2'>
                <input
                  type="tel"
                  className="input"
                  placeholder="Contact Number"
                  value={number}
                  onChange={(e) => checkValidPhoneNumber(e.target.value)}
                  required
                />

                {
                  phoneNumberError.status &&
                  <Typography my={1} color="#BB2C35" variant="subtitle2" fontFamily="inherit" gutterBottom>
                    {phoneNumberError.msg}
                  </Typography>
                }
              </div>

              <Box>
                {isSubmitting ? (
                  <>
                    <svg
                      width={0}
                      height={0}>
                      <defs>
                        <linearGradient
                          id="my_gradient"
                          x1="0%"
                          y1="0%"
                          x2="0%"
                          y2="100%">
                          <stop
                            offset="0%"
                            stopColor="#e01cd5"
                          />
                          <stop
                            offset="100%"
                            stopColor="#1CB5E0"
                          />
                        </linearGradient>
                      </defs>
                    </svg>
                    <CircularProgress sx={{ "svg circle": { stroke: "url(#my_gradient)" } }} />
                  </>
                ) : (
                  <button
                    className="cta-btn rounded-full px-4 py-2"
                    style={{
                      fontFamily: "inherit",
                      textAlign: "center"
                    }}
                  >
                    Submit
                  </button>
                )}
              </Box>
            </section>

          </form>
        </div>
      </div>
    </BackgroundDiv>
  );
};

export default ArenaForm;
