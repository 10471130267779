import React from 'react'
import Newsletter from './Newsletter'
import EarthIllu from '../../assets/image_files/illustrationEarth.svg'

const NewsletterSection = () => {
    return (
        <div className='w-full container mx-auto my-9'>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-center">
                <Newsletter />
                <div className="flex justify-center items-center hidden md:flex sm:hidden ">
                    <img
                        src={EarthIllu}
                        alt="Earth Illustration"
                        className="w-1/2 h-60 sm:w-1/2 sm:h-60 md:w-1/2 md:h-80 pt-4 object-contain"
                    />
                </div>
            </div>
        </div>
    )
}

export default NewsletterSection