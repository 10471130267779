import { useState, useEffect } from "react"

function useNetworkStatus() {
  const [isOnline, setIsOnline] = useState(navigator.onLine)
  const [isNetworkActive, setIsNetworkActive] = useState(true)

  useEffect(() => {
    function handleOnline() {
      setIsOnline(true)
    }

    function handleOffline() {
      setIsOnline(false)
    }

    window.addEventListener("online", handleOnline)
    window.addEventListener("offline", handleOffline)

    return () => {
      window.removeEventListener("online", handleOnline)
      window.removeEventListener("offline", handleOffline)
    }
  }, [])

  useEffect(() => {
    let intervalId

    function checkNetwork() {
      fetch("https://httpbin.org/get", { method: "HEAD", cache: "no-cache" })
        .then(() => {
          setIsNetworkActive(true)
        })
        .catch(() => {
          setIsNetworkActive(false)
        })
    }

    checkNetwork()

    intervalId = setInterval(checkNetwork, 15000) // Check every 15 seconds

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  return { isOnline, isNetworkActive }
}

export default useNetworkStatus
