import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import { ExpandMoreOutlined } from '@mui/icons-material';
import { Tooltip } from '@mui/material';

export default function Dropdown({ title, icon, tooltip=null, children }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className=''>
            {
                tooltip &&
                <Tooltip
                    title={tooltip}
                    placement="bottom"
                    arrow>
                    <Button
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        style={{ fontFamily: "inherit" }}
                        size='small'
                        className='!text-xs !text-gray-400 !bg-[#343839] !rounded !capitalize'
                    >
                        {icon && icon}
                        {title} <ExpandMoreOutlined aria-hidden="true" className="h-5 w-5 text-gray-400" />
                    </Button>
                </Tooltip>
            }
            {
                !tooltip &&
                <Button
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        style={{ fontFamily: "inherit" }}
                        size='small'
                        className='!text-xs !text-gray-400 !bg-[#343839] !rounded !capitalize'
                    >
                        {icon && icon}
                        {title} <ExpandMoreOutlined aria-hidden="true" className="h-5 w-5 text-gray-400" />
                    </Button>
            }
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                className="!p-0 !text-gray-200 !font-light !text-sm"
                PaperProps={{
                    sx: {
                        mt: 1.5,
                        width: '14rem',
                        background: "#343839",
                        borderRadius: '0.375rem',
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                        transition: 'transform 0.2s ease, opacity 0.2s ease',
                        transformOrigin: 'top right',
                        '&.MuiPaper-root': {
                            transform: open ? 'scale(1)' : 'scale(0.95)',
                            opacity: open ? 1 : 0,
                        },
                    },
                }}
            >
                {children}
            </Menu>
        </div>
    );
}
