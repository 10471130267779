import React, { useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { createOrder, verifyOrder } from '../utils/apis';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const StripeComponent = ({ tier, user }) => {
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)

    useEffect(() => {
        paymentHandler()
    }, [])

    const paymentHandler = async() => {
        try {
            let order = await createOrder({ tier, gateway: 'STRIPE' })
            if (order && order.status === 200 && order.data) {
                const stripe = await stripePromise
                await stripe.redirectToCheckout({ sessionId: order.data.id })
            }
        } catch (err) {
            toast.error('Some error in payment process, please contact admin')
        }
    }
    return <></>
};

export default StripeComponent;