import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import SideNavDashboard from '../dashboard/SideNavDashboard.jsx'
import CommonHeader from '../../assets/common-header.js'
import { useDispatch, useSelector } from 'react-redux';
import { fetchArenasList } from '../../redux/actions/arena';

const PublicLayout = () => {
  const dispatch = useDispatch()
  const enrolledArenas = useSelector((state) => state.arena.enrolledArenas)

  const [rewardPoints, setRewardPoints] = useState("")
  const [filterBy, setFilterBy] = useState("Free Style")
  const [mode, setMode] = useState("Free Style")
  const [filterParams, setFilterParams] = useState({ filterType: mode, arenaId: "", roadmapName: "" })

  useEffect(() => {
    dispatch(fetchArenasList())
  }, [])

  const handleFilterChange = (value) => {
    if (value === "Free Style") {
      setFilterParams({ filterType: "Free Style", arenaId: "", roadmapName: "" })
    } else if (value.includes("|")) {
      const [arenaId, roadmapName] = value.split("|")
      setFilterParams({ filterType: "Roadmap", arenaId, roadmapName })
    } else {
      setFilterParams({ filterType: "Arena", arenaId: value, roadmapName: "" })
    }
    setFilterBy(value)
  }

  return (
    <div className='bg-[#232627]'>
      <CommonHeader />
      <SideNavDashboard setMode={setMode} enrolledArenas={enrolledArenas} handleFilterChange={handleFilterChange} filterBy={filterBy} />
      <div className="sm:ml-64 min-h-screen">
        <Outlet context={{ setFilterParams, filterParams, mode, setRewardPoints }} />
      </div>
    </div>
  )
}

export default PublicLayout