import React from 'react';
import { motion } from 'framer-motion';
import { slideInFromLeft } from '../../utils/framer_motion';
import illustrationHero from '../../assets/image_files/hero2024.png'
import './Hero.css'
import '../../assets/css_files/homepage.css'
const MainContent = () => {
    const style = {
        backgroundImage: "linear-gradient(96deg, #dddcff, #c1bfff)",
        WebkitTextFillColor: "transparent",
        WebkitBackgroundClip: "text"
    }

    const styles = {
        textGlow: {
            textShadow: "rgba(112,67,156,1) 0px 0px 15px"
        },
        textGradientWhite: {
            backgroundImage: "linear-gradient(96deg, #dddcff, #c1bfff)",
            WebkitTextFillColor: "transparent",
            WebkitBackgroundClip: "text"
        }
    }

    return (
        // text-shadow-blue-glow
        <div className='css-typing sm:flex flex-col items-center flex md:block gap-4'>
            <h4 className="text-[20px] md:text-[22px] lg:text-[34px] text-white font-light text-center sm:text-center md:text-left" style={{ ...styles.textGradientWhite }}>
                <span className="font-medium" style={styles.textGlow}>Grow </span> Data Skills
            </h4>

            <h4 className="text-[20px] md:text-[22px] lg:text-[34px] text-white font-light text-center sm:text-center md:text-left" style={style}>
                <span className="font-medium" style={styles.textGlow}>Explore </span> Industry Data
            </h4>

            <h4 className="text-[20px] md:text-[22px] lg:text-[34px] text-white font-light text-center sm:text-center md:text-left" style={style}>
                <span className="font-medium" style={styles.textGlow}> Leap </span> With Confidence!
            </h4>
        </div>
    )

}


const Hero = () => {
    const bgStyle = {
        backgroundImage: "radial-gradient(circle farthest-side at 80% 15%, rgba(70, 122, 255, .36), rgba(0, 0, 0, 0) 53%)"
    }
    return (
        <div style={bgStyle}>
            <div className='container mx-auto flex flex-col md:flex-row justify-center items-center h-auto pt-16 pb-6'>
                <motion.div
                    className="flex flex-col md:flex-row justify-center px-5 md:px-16 items-center w-full"
                    initial="hidden"
                    whileInView="visible"
                    variants={{ slideInFromLeft }}
                    viewport={{ once: true }}
                >

                    <div className="home-page-hero-description w-full h-[100%]">
                        <MainContent />

                    </div>

                    <div className="home-page-main-illustration w-full">
                        <img
                            src={illustrationHero}
                            alt="Arena Illustration"
                            className="w-full md:h-full sm:h-[300px] h-[300px] object-contain glow-effect"
                        />
                    </div>
                </motion.div>
            </div>

        </div>

    )
}

export default Hero


