import { getUserDetails } from "../../utils/apis";

export const setUser = (user) => {
    return {
        type: 'SET_USER',
        payload: user,
    };
};

export const fetchUserDetails = () => {
    return async dispatch => {
        let response = await getUserDetails();
        if (response.status === 200) {
            response.data.token = undefined
            response.data.refreshToken = undefined
            dispatch(setUser(response.data));
            return response.data;
        } else {
            localStorage.removeItem('usertoken');
            localStorage.removeItem('hasShownFreeArenaPopup');
            localStorage.removeItem('refreshToken');
            window.location.reload('')
        }
    };
}