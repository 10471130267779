import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import CommonHeader from '../assets/common-header';
import Loader from '../assets/loader';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';


function FailedPage() {
  const navigate = useNavigate();
  
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center">
      <CommonHeader />
      <div className="flex-grow flex flex-col justify-center items-center p-4">
        <div className="bg-white shadow-md rounded-lg p-6 max-w-md w-full text-center">
          <h1 className="text-2xl font-bold text-red-500 mb-4">Payment Failed :(</h1>
          <p className="text-gray-700 mb-6">
            Your payment has failed. Please try again.
          </p>
          <Link to="/" className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700 transition duration-300">Try Again</Link>
        </div>
      </div>
    </div>
  );
}

export default FailedPage;
