import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
    root: {
        width: 300 + theme.spacing(3) * 2,
        position: 'relative',  // Ensure relative positioning for absolute children
    },
    margin: {
        height: theme.spacing(3),
    },
    label: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
    },
    startLabel: {
        left: 0,
    },
    endLabel: {
        right: 0,
    },
}));

function ValueLabelComponent(props) {
    const { children, open, value } = props;

    return (
        <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
            {children}
        </Tooltip>
    );
}

ValueLabelComponent.propTypes = {
    children: PropTypes.element.isRequired,
    open: PropTypes.bool.isRequired,
    value: PropTypes.number.isRequired,
};

const PrettoSlider = withStyles({
    root: {
        color: '#4ade80',
        height: 4,
    },
    thumb: {
        height: 12,
        width: 12,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        marginTop: -4,
        marginLeft: -4,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        height: 4,
        borderRadius: 4,
    },
    rail: {
        height: 4,
        borderRadius: 4,
    },
})(Slider);

export default function DatePickerSlider({ sliderData, onChange, value }) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div className={`${classes.label} ${classes.startLabel} mt-4 text-xs text-gray-400 -m-2`}>{sliderData[0]?.name}</div>
            <PrettoSlider
                valueLabelDisplay="auto"
                aria-label="pretto slider"
                min={0}
                max={sliderData.length - 1}
                onChange={onChange}
                value={value}
                defaultValue={value}
                valueLabelFormat={(value) => (
                    <div className='p-2 text-center bg-[#52af77] rounded-lg'>
                        {sliderData[value].name}
                    </div>
                )}
            />
            <div className={`${classes.label} ${classes.endLabel} mt-4 text-xs text-gray-400 -m-2`}>{sliderData[sliderData.length-1]?.name}</div>
        </div>
    );
}
