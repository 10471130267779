import React from 'react'
import { Link } from 'react-router-dom'
import './GetIn.css'
import { Typography } from '@mui/material'
const GetIn = () => {
  const bgStyle = {
    backgroundImage: "radial-gradient(circle, rgba(70, 122, 255, .36), rgba(0, 0, 0, 0) 25%)"
  }
  return (
    <div className='container mx-auto mb-12 mt-9' style={bgStyle}>
      <div className='getInCard1 w-3/4 mx-auto'>
        <div className='flex flex-col justify-center items-center py-16 getInCard2'>
          {/* <h2 className="text-center"
            style={{
              backgroundImage: "linear-gradient(96deg, #dddcff, #c1bfff)",
              WebkitTextFillColor: "transparent",
              WebkitBackgroundClip: "text"
            }}
          >
            Get in tune with the Data
          </h2> */}

          <h3
            className='text-[28px] lg:text-[36px] text-center font-medium'
            style={{
              backgroundImage: "linear-gradient(96deg, #dddcff, #c1bfff)",
              WebkitTextFillColor: "transparent",
              WebkitBackgroundClip: "text"
            }}
            fontFamily="inherit"
          >
            Get in tune with the Data
          </h3>
          <a
            className="inline-flex mt-12 justify-center md:mt-8 items-center px-4 py-2 font-semibold text-white sm:mb-0 rounded-full cta-btn">
            <svg className="w-5 h-5 mr-2 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <g fill="none" stroke="none">
                <path
                  d="M6 12l5.485-5.485A12 12 0 0 1 19.971 3H21v1.029a12 12 0 0 1-3.515 8.486L12 18m-6-6l6 6m-6-6L3 9l.828-.828A4 4 0 0 1 6.657 7H11l-5 5zm6 6l3 3 .828-.828A4 4 0 0 0 17 17.343V13l-5 5zm-4.5-4.5l-2.379 2.379A7.242 7.242 0 0 0 3 21a7.243 7.243 0 0 0 5.121-2.121L10.5 16.5"
                  stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                <path d="M16.778 7.916a.5.5 0 1 1-.556-.832.5.5 0 0 1 .556.832z" fill="#212121"
                  stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
              </g>
            </svg>
            <Link to="/signup">Start Now</Link>
          </a>
        </div>
      </div>
    </div>
  )
}

export default GetIn