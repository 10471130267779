const isEmailValid = (string) => {
  const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  if (!emailRegex.test(string)) {
    return false
  }
  return true
}

const isPasswordValid = (string) => {
  const passwordRegEx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{9,}$/

  if (!passwordRegEx.test(string)) {
    return false
  }
  return true
}

const isValidUrl = (url) => {
  const regex = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+)(:[0-9]{1,5})?(\/[^\s]*)?$/
  return regex.test(url)
}

const createArray = (n) => Array.from({ length: n }, (_, index) => index + 1)

const getUniqueId = (urlFromUser) => urlFromUser.split("?id=")[1]

const validatePassword = (password) => {
  if (password.length < 8) {
    return {
      status: true,
      msg: "At least 8 characters long",
    }
  }
  if (!/[A-Z]/.test(password) || !/[a-z]/.test(password)) {
    return {
      status: true,
      msg: "Contains both uppercase and lowercase letters (A-Z)",
    }
  }
  if (!/[0-9]/.test(password)) {
    return {
      status: true,
      msg: "Contains numbers (0-9)",
    }
  }
  if (!/[!@#$%^&*]/.test(password)) {
    return {
      status: true,
      msg: "Contains special characters (like !, @, #, $, etc.)",
    }
  }
  return {
    status: false,
    msg: "",
  }
}

const validateName = (name) => {
  if (name.trim() === "") {
    return {
      status: true,
      msg: "Name is required",
    }
  }
  if (!/^[a-zA-Z\s]+$/.test(name)) {
    return {
      status: true,
      msg: "Name should contain only letters (A-Z)",
    }
  }
  return {
    status: false,
    msg: "",
  }
}

const validatePhoneNumber = (number) => {
  if (!number) {
    return {
      status: true,
      msg: "Phone Number is Required",
    }
  }
  if (!/^\+?[1-9][0-9]{7,14}$/.test(number)) {
    return {
      status: true,
      msg: "Phone Number should be in valid format",
    }
  }
  return {
    status: false,
    msg: "",
  }
}

const validateLinkedInUrl = (url) => {
  const linkedInRegex = /^(https?:\/\/)?(www\.)?(linkedin\.com\/(in|pub|company)\/[a-zA-Z0-9_-]+\/?)$/
  return linkedInRegex.test(url)
}

const validateGitHubUrl = (url) => {
  const gitHubRegex = /^(https?:\/\/)?(www\.)?(github\.com)\/[a-zA-Z0-9_-]+\/?$/
  return gitHubRegex.test(url)
}

export { isEmailValid, createArray, isPasswordValid, isValidUrl, getUniqueId, validatePassword, validateName, validatePhoneNumber, validateLinkedInUrl, validateGitHubUrl }
