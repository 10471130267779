import React, { useState } from "react"
import "chart.js/auto"
import { marmaLogo } from "../../assets/image_files/index.js"
import { CodeRounded, ShowChartRounded } from "@mui/icons-material"
import StarsIcon from "@mui/icons-material/Stars"
import { Tooltip as MaterialTooltip } from "@material-ui/core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDatabase, faFileExcel } from "@fortawesome/free-solid-svg-icons"
import DashboardStackedBarChart from "../../assets/progress-barchart.js"
import DashboardPieChart from "../../assets/progress-piechart.js"
import SWAccuracyChart from "../../assets/strength-chart.js"
import Fchart from "../../assets/frequency-chart.js"

const PublicDashboard = () => {
  // This data would be fetched from a backend service
  const [interval, setInterval] = useState("Daily")
  const [metric, setMetric] = useState("Solved")
  const [data, setCurruserData] = useState()
  const [rewardPoints, setRewardPoints] = useState("")
  const handleUserData = (data) => setCurruserData(data)
  const [active, setActive] = useState("fchart")

  return (
    <div className="public-dashboard-container">
      <div className="dashboard-page">
        <nav
          className="dashboard-nav"
          style={{ top: "0px", height: "50px" }}>
          <img
            src={marmaLogo}
            alt="Marma AI"
            className="h-8 object-fit bg-transparent px-2 ml-1 "
          />
          <div
            className="navigation flex items-center"
            style={{ marginRight: "0.75rem" }}>
            <ul className="metrics-container">
              {data &&
                data.map((entry, index) => (
                  <li
                    key={index}
                    className="">
                    <div className="flex-row-container gap-[0.25rem]">
                      {entry.totalRewardPoints && (
                        <MaterialTooltip
                          placement="bottom"
                          arrow="true"
                          title="Total Reward Points">
                          <h6 className="header gap-1">
                            {" "}
                            <StarsIcon />
                            {entry.totalRewardPoints}
                          </h6>
                        </MaterialTooltip>
                      )}
                      {entry.totalQuestionsSolved > 0 && (
                        <MaterialTooltip
                          placement="bottom"
                          title="Total Questions Solved"
                          arrow="true">
                          <div className="header gap-1">
                            <ShowChartRounded />
                            <span>{entry.totalQuestionsSolved}</span>
                          </div>
                        </MaterialTooltip>
                      )}
                      {entry.totalPythonQuestionsSolved > 0 && (
                        <MaterialTooltip
                          placement="bottom"
                          title="Total Python Questions Solved"
                          arrow="true">
                          <div className="header gap-1">
                            <CodeRounded />
                            <span>{entry.totalPythonQuestionsSolved}</span>
                          </div>
                        </MaterialTooltip>
                      )}
                      {entry.totalSQLQuestionsSolved > 0 && (
                        <MaterialTooltip
                          placement="bottom"
                          title="Total SQL Questions Solved"
                          arrow="true">
                          <div className="header gap-1 flex-row-container">
                            <FontAwesomeIcon icon={faDatabase} />
                            <span>{entry.totalSQLQuestionsSolved}</span>
                          </div>
                        </MaterialTooltip>
                      )}
                      {entry.totalExcelQuestionsSolved > 0 && (
                        <MaterialTooltip
                          placement="bottom"
                          title="Total Excel Questions Solved"
                          arrow="true">
                          <div className="header gap-1">
                            <FontAwesomeIcon icon={faFileExcel} />
                            <span>{entry.totalExcelQuestionsSolved}</span>
                          </div>
                        </MaterialTooltip>
                      )}
                    </div>
                  </li>
                ))}
            </ul>
            <a href="/signup">Register</a>
            <a href="/Signin">Log In</a>
          </div>
        </nav>
        <nav className="dashboard-nav">
          <div
            className="chart-parameters-container"
            style={{ width: "auto" }}>
            <select
              value={interval}
              onChange={(e) => setInterval(e.target.value)}
              className="drop-down-container interval">
              <option value="Daily">Daily</option>
              <option value="Weekly">Weekly</option>
              <option value="Monthly">Monthly</option>
            </select>
            <select
              value={metric}
              onChange={(e) => setMetric(e.target.value)}
              className="drop-down-container interval">
              <option value="Attempted">Problems Attempted</option>
              <option value="Solved">Problems Solved</option>
            </select>
          </div>
          {data && (
            <div
              className=""
              style={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "center" }}>
              <p style={{ fontWeight: "600", fontSize: "20px", textAlign: "center", width: "100%" }}>{data[0].name}'s Progress</p>
            </div>
          )}
          <div
            className="nav-rewards-container"
            style={{ width: "40%", justifyContent: "space-evenly" }}>
            <button
              className={`sw-analysis-btn ${active === "schart" ? "active-btn" : ""}`}
              value="schart"
              onClick={(e) => setActive(e.target.value)}>
              Strength Analysis
            </button>
            <button
              className={`sw-analysis-btn ${active === "fchart" ? "active-btn" : ""}`}
              value="fchart"
              onClick={(e) => setActive(e.target.value)}>
              Frequency Chart
            </button>
          </div>
        </nav>
        <div className="dashboard-content">
          <div className="dashboard-left">
            <div className="dashboard-background bar-chart">
              <DashboardStackedBarChart
                interval={interval}
                metric={metric}
                forPublic={true}
              />
            </div>
            <div className="dashboard-background pie-chart">
              <div className="pie-chart-center-container">
                <DashboardPieChart
                  metric={metric}
                  forPublic={true}
                />
              </div>
            </div>
          </div>
          <div className="dashboard-right">
            <div className="dashboard-background fchart-container">
              {active === "fchart" ? (
                <Fchart
                  userRewardPoints={(data) => setRewardPoints(data)}
                  currUserData={handleUserData}
                  forPublic={true}
                />
              ) : (
                <SWAccuracyChart forPublic={true} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PublicDashboard
