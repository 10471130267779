import React from 'react';
import './BG.css'; // Import your CSS file for styling

const BackgroundDiv = ({ children }) => {
    return (
        <div className="mirror-background">
            <div className="bg-pattern-left"></div>
            <div className="bg-pattern-right sm:hidden hidden md:block"></div>
            <div className="container mx-auto z-20 pt-16 pb-6">
                {children}
            </div>
        </div>
    );
}

export default BackgroundDiv;
