import React, { useState } from "react"
import { Cancel, CancelRounded } from "@mui/icons-material"
import { Chrono } from "react-chrono"
import { Backdrop } from "@mui/material"

const QuestionComponent = ({ questionStatement, handleQuestionSelect, alignment }) => {
  const [showEntireQues, setShowEntireQues] = useState(false)

  const problemStatement = questionStatement?.ProblemStatement || ""

  return (
    <>
      {questionStatement && (
        <>
          <button
            className="text-sm text-gray-200 text-left my-2"
            onClick={() => handleQuestionSelect(questionStatement.questionId)}>
            {!showEntireQues ? `${problemStatement.substring(0, 100)}...` : problemStatement}
          </button>
          <div className={`flex-row-container ${alignment}`}>
            <button
              onClick={() => {
                setShowEntireQues(!showEntireQues)
              }}
              className="text-xs text-gray-400">
              {showEntireQues ? "Read Less" : "Read More"}
            </button>
          </div>
        </>
      )}
    </>
  )
}

// Filter component
const QuestionList = ({ questionHistory = [], handleQuestionsHistory, isOpen, onClose }) => {
  const handleQuestionSelect = (questionId) => {
    handleQuestionsHistory(questionId)
  }
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isOpen}
      className="backdrop-blur"
      onClick={onClose}>
      <div
        className={`sidebar ${isOpen ? "open" : ""}`}
        style={{
          backgroundColor: "#232926",
          width: "35vw",
          height: "100vh",
          position: "fixed",
          top: 0,
          left: "65vw",
          zIndex: 1,
          color: "#fefefe",
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.3)",

          overflowY: "auto",
          borderRadius: "8px",
          opacity: "95%",
          backdropFilter: "blur(30px)",
          WebkitBackdropFilter: "blur(30px)",
          zIndex: "1500",
        }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
            position: "sticky",
            top: 0,
            backgroundColor: "#171721",
            zIndex: 1500,
          }}
          className="p-3">
          <h3 className="font-medium">
            <span className="text-gray-200">Your Journey</span>
          </h3>
          <button
            className="close-btn p-0 text-gray-200"
            onClick={onClose}>
            <Cancel />
          </button>
        </div>

        <div
          className="arena-question-list bg-[#0F1015]"
          style={{ padding: "20px" }}>
          <Chrono
            mode="VERTICAL"
            focusActiveItemOnLoad={true}
            theme={{
              cardBgColor: "#16171B",
              cardForeColor: "#fefefe",
              primary: "#fefefe1a",
              secondary: "#fefefe",
            }}
            timelinePointDimension={25}
            style={{
              minWidth: "400px",
            }}
            disableToolbar
            cardHeight={""}
            cardWidth={"400"}
            className="chrono-container"
            classNames={{
              card: "chrono-card",
              cardMedia: (questionItem) => (questionItem.isAnswerCorrect ? "is-correct" : "is-incorrect"),
            }}
            useReadMore={true} // Enable read more functionality
          >
            {questionHistory &&
              questionHistory.map((questionItem, index) => {
                const cardClass = "" //questqionItem.isAnswerAttempted ? (questionItem.isAnswerCorrect ? "chrono-card-correct" : "chrono-card-incorrect") : "chrono-card-attempted"
                if (questionItem.Level)
                  return (
                    <div
                      key={index}
                      className={`chrono-icons ${cardClass}`}>
                      <div className={`chrono-card-content card-left min-w-full`}>
                        <li className="text-sm text-gray-400 bg-[#242529] px-2 py-1 rounded">Solved in : {questionItem.ToolSelected}</li>

                        <li className="text-sm text-gray-400 bg-[#242529] px-2 py-1 rounded">Level : {questionItem.Level ? (questionItem.Level[questionItem.ToolSelected] ? questionItem.Level[questionItem.ToolSelected] : questionItem.Level.Python) : ""}</li>

                        {questionItem.isAnswerCorrect && <li className="text-sm text-gray-400">Reward points : {questionItem.rewardPoints}</li>}
                      </div>
                      <QuestionComponent
                        questionStatement={questionItem}
                        handleQuestionSelect={handleQuestionSelect}
                        alignment={"card-right min-w-full"}
                      />
                    </div>
                  )
              })}
          </Chrono>
        </div>
      </div>
    </Backdrop>
  )
}

export default QuestionList
