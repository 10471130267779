import React,{useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleLeft,faCircleRight } from '@fortawesome/free-solid-svg-icons';

const SingleTable = ({data, tableName}) => {
  const headers = data.length > 0 ? Object.keys(data[0]) :[];
  return (
    <>
    <table className="leading-normal m-0">
    <thead className=""> 
        <tr>
          {headers.map((header, index) => (
            <th key={index} className="border-b-2 bg-[#232627] text-left text-xs font-medium text-gray-200">{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((item, rowIndex) => (
          <tr key={rowIndex} className={`border-b border-gray-200 ${rowIndex % 2 === 0 ? 'bg-gray-100' : ''}`}> 
            {headers.map((header, index) => (
              <td key={index} className="text-sm text-gray-400">{item[header]}</td> 
            ))}
          </tr>
        ))}
      </tbody>
    </table>
    </>     
  );

}

const Table = ({tablesData, tables}) => {
  // Generate headers dynamically from the keys of the first object in the data array
  /*
  const [tablesCount,setTablesCount] = useState(tablesData.length > 0 ? tablesData.length - 1 : 0);
  return(
    <div className="overflow-auto max-h-[300px] rounded-lg shadow-lg flex items-center">
    <button onClick = {() => setTablesCount(tablesCount > 1 ? tablesCount -1: 0)} ><FontAwesomeIcon icon={faCircleLeft} /></button>
    <div className="overflow-auto max-h-[300px] rounded-lg"> 
    {<SingleTable data = {tablesData[tablesCount]} key = {Math.random()} tableName={tables[tablesCount]}/>}
    </div>      
    <button onClick={() => setTablesCount(tablesCount < (tablesData.length-1) ? tablesCount + 1 : tablesData.length-1)} ><FontAwesomeIcon icon={faCircleRight} /></button>
    </div>
  ) 
  */
 return( 
 <div className='table-container'>
 <SingleTable data = {tablesData} key = {Math.random()} tableName={tables}/>
 </div>)
};

export default Table;
