import React, { useEffect, useState } from 'react'
import DashboardStackedBarChart from '../../assets/progress-barchart'
import DashboardPieChart from '../../assets/progress-piechart'
import SWAccuracyChart from '../../assets/strength-chart'
import Fchart from '../../assets/frequency-chart'
import { ShareOutlined, TimerOutlined } from '@mui/icons-material'
import { Button, MenuItem } from '@mui/material'
import Dropdown from '../ui/Dropdown'
import { useOutletContext } from 'react-router-dom'
import ShareOnSocial from 'react-share-on-social'
import { marmaOnlyLogo } from '../../assets/image_files'
import { fetchUserDetails } from '../../redux/actions/user'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { toastConfig } from '../../utils/constants'
import GithubChart from './GithubChart'

const Performance = () => {
    const { filterParams, setFilterParams, setRewardPoints } = useOutletContext()
    const dispatch = useDispatch()
    const [userUniqueId, setUserUniqueId] = useState("")

    const fetchUserUniqueId = async () => {
        try {
            const data = await dispatch(fetchUserDetails());
            setUserUniqueId(data?.uniqueId);
        } catch (error) {
            toast.error('Failed to fetch user details', toastConfig);
        }
    }

    const INTERVALS = ["Daily", "Weekly", "Monthly"]
    const METRICS = [{
        key: "Problems Attempted",
        value: "Attempted",
    },
    {
        key: "Problems Solved",
        value: "Solved",
    }
    ]

    const [interval, setInterval] = useState("Daily")
    const [metric, setMetric] = useState("Solved")
    const [isSubmissionChartVisible, setIsSubmissionChartVisible] = useState(true);

    useEffect(() => {
        fetchUserUniqueId()
    }, [])

    return (
        <div className='p-2'>
            <div className='text-gray-100 mb-2 flex items-center gap-4'>
                <h2 className='text-2xl'>Performance</h2>
                <button className='bg-gray-200 p-2 rounded-full bg-opacity-10 hover:bg-amber-400 hover:bg-opacity-50 transition-all text-gray-400 hover:text-gray-200'>
                <ShareOnSocial
                    textToShare="find a detailed overview of my recent progress and achievements. Whether you're a prospective employer, a fellow professional, or just curious, I invite you to delve into the analytics of my journey."
                    link={`${window.location.origin}/publicdashboard?id=${userUniqueId}`}
                    linkTitle="Welcome to Public Profile"
                    linkMetaDesc=""
                    linkFavicon={marmaOnlyLogo}
                    noReferer>
                    <ShareOutlined className='!text-md'/>
                </ShareOnSocial>
                </button>
            </div>
            <hr className="h-px my-2 bg-gray-200 border-0 dark:bg-gray-700" />

            <div className='flex gap-3 mb-3'>
                <Dropdown
                    title={interval}
                    icon={<TimerOutlined className='!text-base' />}
                    children={INTERVALS.map((interval) => (
                        <MenuItem
                            key={interval}
                            value={interval}
                            onClick={(e) => setInterval(interval)}
                            className="!text-gray-200 !font-light !text-xs">
                            {interval}
                        </MenuItem>
                    ))}
                />


                <Dropdown
                    title={metric}
                    icon={<TimerOutlined className='!text-base' />}
                    children={METRICS.map((metric) => (
                        <MenuItem
                            key={metric?.key}
                            value={metric?.value}
                            onClick={(e) => setMetric(metric.value)}
                            className="!text-gray-200 !font-light !text-xs">
                            {metric.key}
                        </MenuItem>
                    ))}
                />
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 gap-4">
                <div className='text-gray-300 bg-gray-700 bg-opacity-20 rounded-lg max-h-50'>
                    <DashboardStackedBarChart
                        interval={interval}
                        metric={metric}
                        forPublic={false}
                        filterParams={filterParams}
                    />
                </div>


                <div className='text-gray-300 bg-gray-700 bg-opacity-20 rounded-lg max-h-50 overflow-hidden'>
                    
                    <div className='mb-3 flex gap-4 w-max'>
                        <Button
                            variant="outlined"
                            size="small"
                            className={`!border-0 !shadow-none !rounded-none !text-gray-200 !capitalize ${isSubmissionChartVisible ? "!border-b !border-gray-200 !bg-transparent" : "!border-transparent !text-gray-400"}`}
                            onClick={() => setIsSubmissionChartVisible(true)}
                        >
                            Submissions 
                        </Button>

                        <Button
                            variant="outlined"
                            size="small"
                            className={`!border-0 !shadow-none !rounded-none !text-gray-200 !capitalize ${!isSubmissionChartVisible ? "!border-b !border-gray-200 !bg-transparent" : "!border-transparent !text-gray-400"}`}
                            onClick={() => setIsSubmissionChartVisible(false)}
                        >
                            Overall Progress
                        </Button>
                    </div>
                    {
                        isSubmissionChartVisible ?
                            <GithubChart /> :
                            <DashboardPieChart
                                metric={metric}
                                forPublic={false}
                                filterParams={filterParams}
                            />
                    }
                </div>


                <div className='text-gray-300 bg-gray-700 bg-opacity-20 rounded-lg max-h-50 overflow-hidden'>
                    <SWAccuracyChart
                        forPublic={false}
                        filterParams={filterParams}
                    />
                </div>


                <div className='text-gray-300 bg-gray-700 bg-opacity-20 rounded-lg max-h-50 overflow-hidden'>
                    <Fchart
                        userRewardPoints={(data) => setRewardPoints(data)}
                        currUserData={() => { }}
                        forPublic={false}
                        filterParams={filterParams}
                    />
                </div>
            </div>
        </div>
    )
}

export default Performance