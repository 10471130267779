import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { fetchPlans } from "../redux/actions/pricing"
import { Card, CardContent, Typography, Button, Grid, Box } from "@mui/material"
import CheckIcon from "@mui/icons-material/Check"
import { useNavigate } from "react-router-dom"
import CommonHeader from "../assets/common-header"
import Loader from "../assets/loader"
import RazorpayPaymentComponent from "./razorpaygateway"
import StripeComponent from "./stripegateway"
import Checkout from "./checkout"
import ToggleButton from "@mui/material/ToggleButton"
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup"
import { AutoAwesome } from "@mui/icons-material"

const PricingPlan = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [tier, setTier] = useState()
  const [paymentComponent, setPaymentComponent] = useState(false)
  const [pricingType, setPricingType] = useState("Monthly")

  const plans = useSelector((state) => state.pricing.plans)
  const user = useSelector((state) => state.user.userDetails)

  const handleChange = (val) => {
    //console.log({ type, pricingType, bo: type === pricingType, val: event.target.value })
    if (pricingType !== val) {
      setPricingType(val)
    }
  }

  useEffect(() => {
    dispatch(fetchPlans())
  }, [])

  const onClickBuyNow = (tier) => {
    if (tier.price === 0) {
      navigate("/question")
    } else {
      setTier(tier)
      console.log(tier)
      setPaymentComponent(true)
    }
  }

  if (!plans || !plans.length) {
    return <Loader />
  }

  if (paymentComponent) {
    return (
      <Checkout
        tier={tier}
        user={user}
      />
    )
  }

  return (
    <>
      <CommonHeader />
      {/*<Box sx={{ padding: "40px" }}>
        <Typography
          variant="h2"
          align="center"
          gutterBottom>
          Pricing Plans
        </Typography>
        <Grid
          container
          spacing={5}
          justifyContent="center">
          {plans.map((tier, index) => (
            <Grid
              item
              key={tier.title}
              xs={12}
              sm={tier.highlighted ? 6 : 4}
              md={4}>
              <Card
                raised={tier.highlighted}
                sx={{ transition: "0.3s", "&:hover": { transform: "scale(1.05)" } }}>
                <CardContent sx={{ backgroundColor: tier.highlighted ? "#ffe0b2" : "#fff", paddingBottom: "16px" }}>
                  <Typography
                    variant="h5"
                    align="center"
                    color="textPrimary"
                    gutterBottom>
                    {tier.title}
                  </Typography>
                  <Typography
                    variant="h6"
                    align="center"
                    color="secondary">
                    {tier.subheader}
                  </Typography>
                  <Typography
                    variant="h4"
                    align="center"
                    sx={{ my: 2 }}>
                    {tier.currency}
                    {tier.price}
                    <Typography
                      variant="h6"
                      color="textSecondary"
                      display="inline">
                      /mo
                    </Typography>
                  </Typography>
                  <ul>
                    {tier.features.map((feature) => (
                      <Box
                        key={feature}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        my={1}>
                        <CheckIcon color="primary" />
                        <Typography
                          variant="subtitle1"
                          component="li"
                          sx={{ ml: 1 }}>
                          {feature}
                        </Typography>
                      </Box>
                    ))}
                  </ul>
                </CardContent>
                <Button
                  onClick={() => onClickBuyNow(tier)}
                  fullWidth
                  variant={tier.highlighted ? "contained" : "outlined"}
                  color="primary">
                  {tier.price === 0 ? "Get started for free" : "Buy now"}
                </Button>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box> */}
      <div className="flex flex-col items-center justify-start h-[calc(100vh-50px)] bg-[#232627] text-[#e5e7eb]">
        <div className="mx-auto my-4">
          <h2 className="font-bold text-3xl text-gray-200">Choose Your Analytics Journey</h2>
          <div className="gradient" />
        </div>
        <div className="mx-auto my-4">
          <div className="flex-row-container gap-4">
            <Button
              className={`!font-medium hover:!text-yellow-600 hover:!bg-transparent hover:!border-yellow-600 !rounded-lg !text-sm !px-2 !py-1.5 !capitalize  !text-gray-400 !border-gray-600 ${pricingType === "Monthly" ? "!text-yellow-600 !border-yellow-600" : ""}`}
              variant="outlined"
              onClick={() => handleChange("Monthly")}>
              Monthly
            </Button>
            <Button
              className={`!font-medium hover:!text-yellow-600 hover:!bg-transparent hover:!border-yellow-600 !rounded-lg !text-sm !px-2 !py-1.5 !capitalize  !text-gray-400 !border-gray-600 ${pricingType === "Yearly" ? "!text-yellow-600 !border-yellow-600" : ""}`}
              variant="outlined"
              onClick={() => handleChange("Yearly")}>
              Yearly
            </Button>
          </div>
        </div>

        <div className="container flex-row-container flex-wrap w-full gap-4">
          {plans
            .filter((item) => item.type === pricingType)
            .map((tier, index) => (
              <div className="shiny-border flex-1 insightCard max-w-md">
                <div className="myCard p-6 !bg-[#1f2021]">
                  <div className="text-left pt-12">
                    <h2 className="text-xl text-gray-200 flex items-center">
                      <AutoAwesome className="!text-green-600 !mr-2" />
                      {tier.title}
                    </h2>
                    <p className="text-lg text-gray-400">{tier.subheader}</p>

                    <h3 className="text-base font-semibold text-yellow-600">
                      {tier.currency} {tier.price}
                      <span className=""> {pricingType === "Monthly" ? "/mo" : "/yr"}</span>
                    </h3>
                  </div>
                  <ul className="text-gray-400">
                    {tier.features.map((feature) => (
                      <li
                        className="flex items-center my-2"
                        key={feature}>
                        <CheckIcon className="text-gray-400" />
                        <span className="ml-2">{feature}</span>
                      </li>
                    ))}
                  </ul>
                  <div className="text-center mt-4">
                    <Button
                      onClick={() => onClickBuyNow(tier)}
                      variant="outlined"
                      className="!font-medium hover:!text-yellow-600 hover:!bg-transparent hover:!border-yellow-600 !rounded-lg !text-sm !px-2 !py-1.5 !capitalize  !text-gray-400 !border-gray-600">
                      {tier.price === 0 ? "Get started for free" : "Buy now"}
                    </Button>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  )
}

export default PricingPlan
