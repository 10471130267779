import React, { useState, useEffect } from "react"
import "chart.js/auto"
import Loader from "../assets/loader.js"
import { fetchLeaderBoardData } from "../utils/apis"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCopy, faDatabase, faFileExcel, faSort } from "@fortawesome/free-solid-svg-icons"
import ShareOnSocial from "react-share-on-social"
import { marmaOnlyLogo } from "../assets/image_files/index.js"
import ShareIcon from "@mui/icons-material/Share"
import "../assets/css_files/dashboard.css"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import { CodeRounded, ShowChartRounded } from "@mui/icons-material"
import StarsIcon from "@mui/icons-material/Stars"
import { Tooltip as MaterialTooltip } from "@material-ui/core"
import ShowChartRoundedIcon from "@mui/icons-material/ShowChartRounded"

const LeaderBoard = ({ userRewardPoints, filterParams }) => {
  const [leaderboardData, setLeaderBoardData] = useState([])
  const [failureText, setFailureText] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [currentuserData, setCurrrentUserData] = useState()
  const [userRank, setuserRank] = useState()

  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems = leaderboardData.slice(indexOfFirstItem, indexOfLastItem)

  const totalPages = Math.ceil(leaderboardData.length / itemsPerPage)

  function changePage(number) {
    setCurrentPage(currentPage + number)
  }

  function handlePerPageChange(event) {
    setItemsPerPage(parseInt(event.target.value))
    setCurrentPage(1) // Reset to the first page
  }
  const createAvatar = (name) => {
    return name.charAt(0).toUpperCase()
  }

  useEffect(() => {
    const fetchTable = async () => {
      const response = await fetchLeaderBoardData(filterParams)

      if (response.status === 200) {
        let data = response.data.sort((a, b) => b.totalRewardPoints - a.totalRewardPoints)
        data = data.map((item, index) => ({ ...item, rank: index + 1 }))

        setLeaderBoardData(data)
        const user = data.filter((item) => item.isCurrentUser === true)
        setCurrrentUserData(user)
        setuserRank(user.rank)
        userRewardPoints(user[0].totalRewardPoints)
      } else setFailureText(true)
    }
    fetchTable()
  }, [leaderboardData !== null, filterParams])

  if (leaderboardData.length === 0 && !failureText) {
    return (
      <div className="flex-row-container">
        <Loader />
      </div>
    )
  }
  if (failureText) return <h1>Unable to Fetch Data</h1>

  return (
    <>
      <div className="leader-board-heading-cont">
        <h2 className="">Performance Rankings</h2>
        <div className="pagination">
          <button
            disabled={currentPage === 1}
            onClick={() => changePage(-1)}>
            <ArrowBackIosIcon />
          </button>
          <button
            disabled={currentPage === totalPages}
            onClick={() => changePage(1)}>
            <ArrowForwardIosIcon />
          </button>
          <select
            value={itemsPerPage}
            onChange={handlePerPageChange}
            className="drop-down-container">
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
          </select>
        </div>
      </div>
      <div
        className="leader-board-list"
        style={{ flexGrow: 1 }}>
        <div className="header">
          <h6 style={{ width: "40%", textAlign: "center" }}>Users</h6>
          <h6 style={{ width: "20%", textAlign: "center" }}>Points Scored</h6>
          <h6 style={{ width: "40%", textAlign: "center" }}>Progress</h6>
        </div>
        <ul>
          {currentItems.map((entry, index) => (
            <li key={index}>
              <h6>{entry.rank}</h6>
              <a
                style={{ width: "40%" }}
                href={`${window.location.origin}/publicdashboard?id=${entry.uniqueId}`}
                target="_blank"
                rel="noopener noreferrer">
                <span className="avatar">{createAvatar(entry.fullName)}</span>
                <h6>{entry.fullName}</h6>
              </a>
              <h6 className="header gap-1">
                {" "}
                <StarsIcon />
                {entry.totalRewardPoints}
              </h6>

              <div
                className="flex-row-container gap-[0.5rem]"
                style={{ width: "40%" }}>
                <MaterialTooltip
                  placement="bottom"
                  title="Total Questions Solved"
                  arow="true">
                  <span className="header">
                    {" "}
                    <ShowChartRoundedIcon /> {entry.totalQuestionsSolved}
                  </span>
                </MaterialTooltip>
                <div className="question-subject-progress">
                  {entry.totalPythonQuestionsSolved > 0 && (
                    <MaterialTooltip
                      placement="bottom"
                      title="Total Python Questions Solved"
                      arow="true">
                      <div style={{ backgroundColor: "#8884d8" }}>
                        <CodeRounded />
                        <span>{entry.totalPythonQuestionsSolved}</span>
                      </div>
                    </MaterialTooltip>
                  )}
                  {entry.totalSQLQuestionsSolved > 0 && (
                    <MaterialTooltip
                      placement="bottom"
                      title="Total SQL Questions Solved"
                      arow="true">
                      <div style={{ backgroundColor: "#ffc658" }}>
                        <FontAwesomeIcon icon={faDatabase} />
                        <span>{entry.totalSQLQuestionsSolved}</span>
                      </div>
                    </MaterialTooltip>
                  )}
                  {entry.totalExcelQuestionsSolved > 0 && (
                    <MaterialTooltip
                      placement="bottom"
                      title="Total Excel Questions Solved"
                      arow="true">
                      <div style={{ backgroundColor: "#82ca9d" }}>
                        <FontAwesomeIcon icon={faFileExcel} />
                        <span>{entry.totalExcelQuestionsSolved}</span>
                      </div>
                    </MaterialTooltip>
                  )}
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className="leader-board-footer-container">
        <ul>
          {currentuserData &&
            currentuserData.map((entry, index) => (
              <li
                key={index}
                className="current-user-data">
                <h6>{entry.rank}</h6>
                <a
                  href={`${window.location.origin}/publicdashboard?id=${entry.uniqueId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ width: "40%" }}>
                  <span className="avatar">{createAvatar(entry.fullName)}</span>
                  <h6>{entry.fullName}</h6>
                  <ShareOnSocial
                    textToShare="find a detailed overview of my recent progress and achievements. Whether you're a prospective employer, a fellow professional, or just curious, I invite you to delve into the analytics of my journey."
                    link={`${window.location.origin}/publicdashboard?id=${entry.uniqueId}`}
                    linkTitle="Welcome to Public Profile"
                    linkMetaDesc=""
                    linkFavicon={marmaOnlyLogo}
                    noReferer>
                    <button className="hints-button avatar ">
                      <ShareIcon />
                    </button>
                  </ShareOnSocial>
                </a>
                <h6 className="header gap-1">
                  {" "}
                  <StarsIcon />
                  {entry.totalRewardPoints}
                </h6>
                <div
                  className="flex-row-container gap-[0.5rem]"
                  style={{ width: "40%" }}>
                  <span className="header">
                    {" "}
                    <ShowChartRounded /> {entry.totalQuestionsSolved}
                  </span>
                  <div className="question-subject-progress">
                    {entry.totalPythonQuestionsSolved > 0 && (
                      <MaterialTooltip
                        placement="bottom"
                        title="Total Python Questions Solved"
                        arow="true">
                        <div style={{ backgroundColor: "#8884d8" }}>
                          <CodeRounded />
                          <span>{entry.totalPythonQuestionsSolved}</span>
                        </div>
                      </MaterialTooltip>
                    )}
                    {entry.totalSQLQuestionsSolved > 0 && (
                      <MaterialTooltip
                        placement="bottom"
                        title="Total SQL Questions Solved"
                        arow="true">
                        <div style={{ backgroundColor: "#ffc658" }}>
                          <FontAwesomeIcon icon={faDatabase} />
                          <span>{entry.totalSQLQuestionsSolved}</span>
                        </div>
                      </MaterialTooltip>
                    )}
                    {entry.totalExcelQuestionsSolved > 0 && (
                      <MaterialTooltip
                        placement="bottom"
                        title="Total Excel Questions Solved"
                        arow="true">
                        <div style={{ backgroundColor: "#82ca9d" }}>
                          <FontAwesomeIcon icon={faFileExcel} />
                          <span>{entry.totalExcelQuestionsSolved}</span>
                        </div>
                      </MaterialTooltip>
                    )}
                  </div>
                </div>
              </li>
            ))}
        </ul>
      </div>
    </>
  )
}

export default LeaderBoard
