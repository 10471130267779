import React, { useState, useEffect } from 'react'
import { marmaLogo } from '../../assets/image_files/index.js'
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Backdrop from '@mui/material/Backdrop';
import './GetIn.css'
import './NavBar.css'

import { useScroll } from './useScrollHook/useScroll.jsx'
import { Link } from 'react-router-dom';
import Headroom from 'react-headroom';
const NewNavbar = () => {

    //----------------- for scrolling -----------------

    const { scrollX, scrollY, scrollDirection } = useScroll();

    console.log("X chi val", scrollX);
    console.log("Y chi val", scrollY);
    console.log("scroll dir", scrollDirection)


    const styles = {
        active: {
            visibility: "visible",
            transition: "all 0.5s"
        },
        hidden: {
            visibility: "hidden",
            transition: "all 0.5s",
            transform: "translateY(-100%)"
        }
    }

    //----------------- scrolling end -----------------

    const [isNavOpen, setIsNavOpen] = useState(false)
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        document.body.style.overflow = 'auto'
        setOpen(false);
        setIsNavOpen(!isNavOpen);

    };
    const handleOpen = () => {
        document.body.style.overflow = 'hidden'
        setOpen(true);
        setIsNavOpen(!isNavOpen);
    };
    return (
        <>
            <Headroom
                className='fixed top-0 w-full z-50'
            >
                <header className={`p-2 sm:p-2 md:p-4 w-full backdrop-blur-md ${scrollY > 50 ? "shadow-md" : null}`}>
                    <div className='container mx-auto flex justify-between'>
                        <div className='flex items-center'>
                            {/* logo */}
                            <Link to="/">
                                <img src={marmaLogo} alt="marmaLogo" width={150} height={150} className='cursor-pointer' />
                            </Link>
                        </div>
                        <div className='hidden sm:hidden md:flex lg:flex justify-end items-center w-full'>
                            <ul className='flex gap-9 text-white justify-between items-center'>
                                <li>
                                    <Link to="/arena" className='text-white cursor-pointer hover:text-purple-300 transition-all'>
                                        Arena
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/signin" className='text-white cursor-pointer hover:text-purple-300 transition-all'>
                                        Sign In
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/signup" className='cta-btn py-2 px-6 rounded-full'>
                                        Register
                                    </Link>
                                </li>
                            </ul>
                        </div>

                        <div className='flex sm:flex md:hidden lg:hidden items-center'>
                            {!isNavOpen && (
                                <MenuIcon onClick={
                                    () => {
                                        handleOpen();
                                    }}
                                    className="flex text-white z-10" />
                            )}
                        </div>
                    </div>


                </header>
            </Headroom>

            <Backdrop
                sx={{
                    color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={open}
                className={`backdrop-blur-md ${open ? 'flex' : 'hidden'}`}
                onClick={handleClose}
            >

                <div className={`container mx-auto justify-center ${isNavOpen ? 'flex' : 'hidden'}`}>
                    <CloseIcon onClick={handleClose} className="flex text-white absolute right-14 top-8" />
                    <ul className='flex flex-col gap-9 text-white justify-between items-center'>
                        <li className='text-white cursor-pointer hover:text-purple-300 transition-all'>
                            <Link to="/">
                                Home
                            </Link>
                        </li>
                        <li className='text-white cursor-pointer hover:text-purple-300 transition-all'>
                            <Link to="/arena">
                                Arena
                            </Link>
                        </li>
                        <li className='text-white cursor-pointer hover:text-purple-300 transition-all'>
                            <Link to="/signin">
                                Sign In
                            </Link>
                        </li>
                        <li>
                            <Link to="/signup" className='cta-btn py-2 px-6 rounded-full'>
                                Register
                            </Link>
                        </li>
                    </ul>
                </div>

            </Backdrop>
        </>
    )
}

export default NewNavbar