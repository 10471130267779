import * as React from "react"
import CircularProgress from "@mui/material/CircularProgress"
import Box from "@mui/material/Box"

const styles = {
  loaderContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "inherit",
    // or the height of your container where the loader is used
  },
  loader: {
    width: 70,
    height: 70,
    borderRadius: "50%",
    backgroundColor: "#fff",
    boxShadow: `0px 0px 8px rgba(51, 51, 51, 0.6)`,
  },
}

const Loader = () => {
  return (
    <Box
      style={styles.loaderContainer}
      sx={{ display: "flex" }}>
      <CircularProgress sx={{ color: "#fefefe" }} />
    </Box>
  )
}

export default Loader
