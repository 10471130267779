import React from "react"
import "../assets/css_files/badges.css"
import { Tooltip } from "@mui/material"

function Badges({ badges }) {
  // Function to get the correct badge class based on type
  const getBadgeClass = (badgeType) => {
    if (badgeType.toLowerCase().includes("bronze")) return "bronze"
    if (badgeType.toLowerCase().includes("silver")) return "silver"
    if (badgeType.toLowerCase().includes("gold")) return "gold"
    if (badgeType.toLowerCase().includes("platinum")) return "platinum"
    return ""
  }

  return (
    <div className="badge-container">
      {badges &&
        badges.length > 0 &&
        badges.map((badge, index) => (
          <Tooltip
            title={`${badge.badgeType} badge awarded in ${badge.subject} on ${badge.dateAwarded.toLocaleString()}`}
            arrow>
            {badge.subject !== "overall" && (
              <div
                key={index}
                className={`badge ${getBadgeClass(badge.badgeType)}`}>
                {badge.subject[0].toUpperCase()}
              </div>
            )}
          </Tooltip>
        ))}
    </div>
  )
}

export default Badges
