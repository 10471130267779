import React, { useState, useEffect } from "react"
import "chart.js/auto"
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from "recharts"
import { fetchBarChartData } from "../utils/apis.js"
import { toast } from "react-toastify"
import Loader from "./loader.js"
import { getUniqueId } from "../utils/common-function.js"
import { Slider } from "@material-ui/core"
import DatePickerSlider from "../components/ui/data-picker-slider/DatePickerSlider.jsx"
import NewBarChart from "../components/dashboard/NewBarChart.jsx"
import { Button } from "@mui/material"
import { CustomToolTip, CustomXAxisTick } from "../utils/graphCustomeComponen.js"

const DashboardStackedBarChart = ({ interval, metric, forPublic, filterParams }) => {
  const [data, setData] = useState([])
  const [status, setStatus] = useState("idle")
  const [selectedMode, setSetSelectedMode] = useState("timeseries")

  // Helper functions
  const getMonthName = (month) => {
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
    return monthNames[month - 1]
  }

  const getWeekRange = (year, week) => {
    const startDate = new Date(year, 0, (week - 1) * 7 + 1) // Start from first day of the week
    const endDate = new Date(startDate)
    endDate.setDate(startDate.getDate() + 6)

    const startMonth = startDate.toLocaleString("default", {
      month: "short",
    })
    const endMonth = endDate.toLocaleString("default", {
      month: "short",
    })

    return `${startMonth} ${startDate.getDate()} ${startDate.getFullYear()} - ${endMonth} ${endDate.getDate()} ${startDate.getFullYear()}`
  }
  function sortDataByMonth(data) {
    return data.sort((a, b) => {
      const [monthA, yearA] = a.name.split(" ")
      const [monthB, yearB] = b.name.split(" ")

      const monthsOrder = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

      // Convert year to number for comparison
      const yearAInt = parseInt(yearA)
      const yearBInt = parseInt(yearB)

      // First compare the years
      if (yearAInt !== yearBInt) {
        return yearAInt - yearBInt
      }

      // If years are the same, compare the months
      const monthIndexA = monthsOrder.indexOf(monthA)
      const monthIndexB = monthsOrder.indexOf(monthB)

      return monthIndexA - monthIndexB
    })
  }

  function sortWeeklyData(data) {
    return data.sort((a, b) => {
      // Extract start and end dates from the name
      const [startDateA, endDateA] = a.name.split(" - ")
      const [startDateB, endDateB] = b.name.split(" - ")

      // Create Date objects for start dates
      const startDateObjectA = new Date(startDateA)
      const startDateObjectB = new Date(startDateB)

      // Compare start dates
      if (startDateObjectA - startDateObjectB !== 0) {
        return startDateObjectA - startDateObjectB
      }

      // Create Date objects for end dates
      const endDateObjectA = new Date(endDateA)
      const endDateObjectB = new Date(endDateB)

      return endDateObjectA - endDateObjectB
    })
  }

  const preprocessData = (rawData, interval, metric) => {
    let aggregatedData = {}

    // Filter rawData based on metric
    rawData = metric === "Attempted" ? rawData.filter((item) => item.questionsAttempted > 0) : rawData.filter((item) => item.questionsSolved > 0)

    // Sort and slice data for daily interval
    if (interval === "Daily") {
      rawData = rawData
        .sort((a, b) => {
          const dateA = new Date(a.day)
          const dateB = new Date(b.day)
          return dateB - dateA // Sort descending
        })
        .slice(0, 30)
      rawData.reverse() // Reverse to get the oldest first
    }

    // Get current week details
    const currentDate = new Date()
    const currentYear = currentDate.getFullYear()
    const currentWeek = Math.ceil((currentDate.getDate() + new Date(currentYear, 0, 1).getDay()) / 7)
    const currentWeekKey = getWeekRange(currentYear, currentWeek)

    // Initialize current week data
    let currentWeekData = {
      name: currentWeekKey,
      SQL: {},
      Python: {},
      Excel: {},
    }

    rawData.forEach((item) => {
      let intervalKey
      if (interval === "Daily") {
        intervalKey = getMonthName(new Date(`${item.day}`).getMonth() + 1).slice(0, 3) + " " + new Date(`${item.day}`).getDate()
      } else if (interval === "Weekly") {
        intervalKey = getWeekRange(item.year, item.week)
      } else if (interval === "Monthly") {
        intervalKey = `${getMonthName(item.month)} ${item.year}`
      }

      // Aggregate data for historical weeks
      if (!aggregatedData[intervalKey]) {
        aggregatedData[intervalKey] = {
          name: intervalKey,
          SQL: {},
          Python: {},
          Excel: {},
        }
      }

      const metricKey = metric === "Attempted" ? "questionsAttempted" : "questionsSolved"
      const toolSelected = item.toolSelected
      const level = item.level

      // Update historical data
      if (!aggregatedData[intervalKey][toolSelected][level]) {
        aggregatedData[intervalKey][toolSelected][level] = 0
      }
      aggregatedData[intervalKey][toolSelected][level] += item[metricKey]

      // Update current week data
      if (interval === "Weekly" && intervalKey === currentWeekKey) {
        if (!currentWeekData[toolSelected][level]) {
          currentWeekData[toolSelected][level] = 0
        }
        currentWeekData[toolSelected][level] += item[metricKey]
      }
    })

    // Add current week's data to aggregated data
    if (Object.keys(currentWeekData.SQL).length > 0 || Object.keys(currentWeekData.Python).length > 0 || Object.keys(currentWeekData.Excel).length > 0) {
      aggregatedData[currentWeekKey] = currentWeekData
    }

    // Convert aggregated data to array and sort if necessary
    let result = Object.values(aggregatedData)
    if (interval !== "Daily") {
      result = interval === "Weekly" ? sortWeeklyData(result) : sortDataByMonth(result)
      result =
        interval === "Weekly"
          ? result.map((item) => ({
              ...item,
              name: `${item.name.split(" - ")[0].slice(0, -4)} - ${item.name.split(" - ")[1].slice(0, -4)}`,
            }))
          : result
    }
    return result
  }

  const fetchDataForBarChart = async (interval, metric, filterParams) => {
    let uniqueId
    if (forPublic) uniqueId = getUniqueId(window.location.href)
    const response = await fetchBarChartData(interval, uniqueId, filterParams)

    if (response.status === 200) {
      if (response.data.length > 0) {
        const processedData = preprocessData(response.data, interval, metric)

        setData(processedData)

        processedData.length == 0 ? setStatus("no_data") : setStatus("success")
      } else {
        setStatus("no_data")
      }
    } else {
      setStatus("failure")
      console.error("Failed to fetch data")
      toast.error("Unable to Fetch Your Progress!")
    }
  }

  useEffect(() => {
    fetchDataForBarChart(interval, metric, filterParams)
  }, [interval, metric, filterParams])

  const subjects = ["SQL", "Python", "Excel"] // Adjust based on your data
  const colors = {
    // More vibrant colors for bars
    SQL: ["#9f1239", "#fb7185"],
    Python: ["#86198f", "#e879f9"],
    Excel: ["#166534", "#4ade80"],
  }

  const [sliderValue, setSliderValue] = React.useState([0, data?.length - 1])

  const handleSliderValueChange = (event, newValue) => {
    setSliderValue(newValue)
  }

  useEffect(() => {
    setSliderValue([0, data.length - 1])
  }, [data])

  const renderGradients = () => (
    <defs>
      {subjects.map((subject, index) => (
        <linearGradient
          key={subject}
          id={`gradient-${subject}`}
          x1="0%"
          y1="0%"
          x2="100%"
          y2="100%">
          <stop
            offset="0%"
            stopColor={colors[subject][0] || "#000"}
          />
          <stop
            offset="100%"
            stopColor={colors[subject][1] || "#fff"}
          />
        </linearGradient>
      ))}
    </defs>
  )

  const renderBars = subjects.flatMap((subject) => {

    const maxLevel = data.reduce((max, current) => {
      // Assuming each subject key in current maps to an object with level keys
      const levels = current[subject]
      const currentMaxLevel = levels ? Math.max(...Object.keys(levels).map(Number)) : 0
      return Math.max(max, currentMaxLevel)
    }, 0)
    const bars = Array.from({ length: maxLevel }, (_, i) => {
      const levelKey = `${subject}.${i + 1}`
      return (
        <Bar
          barSize={16}
          key={levelKey}
          dataKey={levelKey}
          stackId="a"
          radius={[6, 6, 0, 0]}
          fill={`url(#gradient-${subject})`}
        />
      )
    })
    return bars
  })

  const renderBars2 = data.map((entryItem, index) => {
    const entryItems = Object.entries(entryItem)

    const entry = entryItems.filter((item) => subjects.includes(item[0]))

    const bars = entry.map((subjectItem) => {
      const subject = subjectItem[0]
      const levelData = Object.entries(subjectItem[1])
      let totalCount = 0
      levelData.map((item) => (totalCount += parseInt(item[1], 10)))
      console.log(subject, totalCount)
      return (
        <Bar
          barSize={16}
          key={subject}
          data={subjectItem[1]}
          dataKey={totalCount}
          stackId="a"
          radius={[6, 6, 0, 0]}
          fill={`url(#gradient-${subject})`}
        />
      )
    })
    return bars
  })

  let content

  if (status === "idle") {
    content = <Loader />
  } else if (status === "no_data") {
    content = (
      <h1 className="text-sm text-center text-gray-400">
        No Data to Display <br></br>Solve Questions to see your Progress!
      </h1>
    )
  } else if (status === "failure") {
    content = <h1>Unable to Fetch Data Try Again!</h1>
  } else {
    content = (
      <>
        <div className="flex gap-3 mr-auto w-full items-center flex-wrap sm:justify-center justify-center md:justify-start mb-3">
          <Button
            variant="outlined"
            size="small"
            className={`!border-0 !shadow-none !rounded-none !text-gray-200 !capitalize ${selectedMode === "timeseries" ? "!border-b !border-gray-200 !bg-transparent" : "!border-transparent !text-gray-400"}`}
            onClick={() => setSetSelectedMode("timeseries")}
          >
            Time Series
          </Button>

          <Button
            variant="outlined"
            size="small"
            className={`!border-0 !shadow-none !rounded-none !text-gray-200 !capitalize ${selectedMode === "levelwise" ? "!border-b !border-gray-200 !bg-transparent" : "!border-transparent !text-gray-400"}`}
            onClick={() => setSetSelectedMode("levelwise")}
          >
            Level Wise
          </Button>

          {
            selectedMode === "timeseries" &&
            <div className="relative flex lg:gap-0 m-0 sm:m-0 md:px-4 my-1 items-center flex-col group">

              <span className="absolute top-7 text-gray-400 text-sm opacity-0 group-hover:opacity-100 transition-opacity duration-300 left-1/2 transform -translate-x-1/2">
                Select Duration
              </span>

              <DatePickerSlider sliderData={data} onChange={handleSliderValueChange} value={sliderValue} />
            </div>
          }
        </div>

        {
          selectedMode === "timeseries" ?

            <>
              {/* <div className="mr-auto flex px-4 my-2 justify-center items-center gap-3">
                <h6 className="text-gray-200 text-md font-semibold">{`${interval} Progress`} : </h6>
                <span className="text-gray-300">{data[sliderValue[0]]?.name} <span className="text-gray-400 text-xs mx-2">TO</span>  {data[sliderValue[1]]?.name}</span>
              </div> */}
              <ResponsiveContainer height="100%" className="flex justify-center items-center h-full">
                <BarChart
                  data={data.slice(sliderValue[0], sliderValue[1] + 1)}
                 >
                  {renderGradients()}
                  <XAxis
                    dataKey="name"
                    axisLine={{ stroke: "white" }}
                    tickLine={{ stroke: "white" }}
                    tick={<CustomXAxisTick />}
                    interval={0}
                    angle={-20}
                    textAnchor="middle"
                    height={50}
                    fontSize={12}
                    dy={10}
                  />
                  <YAxis
                    axisLine={{ stroke: "white" }}
                    tickLine={{ stroke: "white" }}
                    tick={{ fill: "white" }}
                    fontSize={12}
                  />
                  <Tooltip content={<CustomToolTip />} cursor={{fill: '#374151', opacity:".5"}}/>
                  {renderBars}
                </BarChart>
              </ResponsiveContainer>
            </> :
            <NewBarChart data={data} />
        }

      </>
    )
  }

  return <div className="chart-area">{content}</div>
}

export default DashboardStackedBarChart
