import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import CommonHeader from '../assets/common-header';
import Loader from '../assets/loader';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { fetchUpdatedToken } from '../utils/apis';


function SuccessPage() {
    const navigate = useNavigate();
    useEffect(() => {   
    fetchUpdatedToken().then((data) => {
        if (data.error) {
            toast.error(data.error);
        }
        else{
            localStorage.setItem('usertoken', data.token);
            window.location.href='/question';
        }
    });
    }, [navigate]);
  
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center">
      <CommonHeader />
      <div className="flex-grow flex flex-col justify-center items-center p-4">
        <div className="bg-white shadow-md rounded-lg p-6 max-w-md w-full text-center">
          <h1 className="text-2xl font-bold text-green-500 mb-4">Payment Successful!</h1>
          <p className="text-gray-700 mb-6">Thank you. Your payment has been processed successfully.</p>
        </div>
      </div>
    </div>
  );
}

export default SuccessPage;
