import React, { useEffect } from "react"
import { GoogleLogin, useGoogleLogin } from "react-google-login"
import { toast } from "react-toastify"
import { sendGoogleTokenToBackend } from "../utils/apis"
import { Avatar, Box, Button } from "@mui/material"
import "react-toastify/dist/ReactToastify.css" // Import toastify CSS
import { toastConfig } from "../utils/constants"

const GoogleLoginComponent = () => {
  useEffect(() => {
    window.google.accounts.id.initialize({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      callback: handleCallbackResponse,
    })

    // window.google.accounts.id.renderButton(
    //   document.getElementById('signInDiv'),
    //   { theme: 'outline', size: 'medium', text:"none",}  // customization attributes
    // );
  }, [])

  const handleCallbackResponse = async (respons) => {
    // Send this response.credential (JWT token) to your backend for verification and further processing
    try {
      const response = await sendGoogleTokenToBackend(respons.credential)
      console.log(response)
      if (response.status === 200) {
        toast.success("Signup successful", toastConfig) // Display success toast
        localStorage.setItem("usertoken", response.data.Token)
        localStorage.setItem("refreshToken", response.data.refreshToken)
        window.location.reload()
      } else {
        toast.error("Error while signing you up", toastConfig) // Display error toast
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Box
      onClick={() => window.google.accounts.id.prompt()}
      className="flex justify-center items-center">
      {/* <Avatar
        src="https://cdn3.iconfinder.com/data/icons/logos-brands-3/24/logo_brand_brands_logos_google-512.png"
        alt="Google Icon"
        sx={{ width: 40, height: 40, cursor: "pointer" }}
      /> */}
      {/* <button className='bg-transparent border-purple-500 border-solid border rounded-full p-3 hover:bg-[#dd4b39] transition-all text-purple-500 hover:text-white hover:border-[#dd4b39]'>
        <i class="fab fa-google"></i>
      </button> */}

      <button className="bg-transparent border-slate-700 border-solid border rounded-full p-3 hover:bg-[#dd4b39] transition-all text-slate-500 hover:text-white hover:border-[#dd4b39]">
        <i class="fab fa-google"></i>
      </button>
    </Box>
  )
}

export default GoogleLoginComponent
