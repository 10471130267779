import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { fetchUserDetails } from "../redux/actions/user"
import { marmaOnlyLogo, titleBarLogo } from "./image_files"
import { Tooltip } from "@material-ui/core"
import { Link } from "react-router-dom"
import { fetchUserMetrics } from "../redux/actions/usermetrics"
import StarsIcon from "@mui/icons-material/Stars"
import Badges from "./badges"
import { Avatar } from "@mui/material"

const styles = {
  headerDropdownContainer: {
    position: "absolute",
    zIndex: "10000",
    top: "3.1rem",
    right: "1.25rem",
    //backgroundColor: "#232627",
    //border: "1px solid #FEFEFE1A",
    borderRadius: "18px",
    //padding: "1rem",
    boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
    overflowY: "auto",
    color: "#fefefe",
    marginTop: "1px",
    fontSize: "14px",
  },
}

const CommonHeader = () => {
  const dispatch = useDispatch()
  const [showDropDown, setShowDropdown] = useState(false)

  const reduxUser = useSelector((state) => state.user.userDetails)
  const userMetrics = useSelector((state) => state.userMetrics.userMetrics) || {}
  const headerDropdownRef = useRef(null)

  useEffect(() => {
    dispatch(fetchUserDetails())
  }, [dispatch]) // Run only when dispatch changes

  useEffect(() => {
    dispatch(fetchUserMetrics())
  }, [dispatch]) // Run only when reduxUser or userMetrics changes

  const logout = () => {
    localStorage.removeItem("usertoken") // Assuming the JWT token is stored in localStorage
    localStorage.removeItem("hasShownFreeArenaPopup")
    localStorage.removeItem("refreshToken")
    window.location.reload()
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (headerDropdownRef.current && !headerDropdownRef.current.contains(event.target)) {
        setShowDropdown(false) // Close dropdown if clicking outside
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [headerDropdownRef])

  return (
    <div className="flex flex-col h-[3.125rem] fixed top-0  bg-[#232627]  w-full z-[1000]">
      <div className="question-page-links flex flex-row justify-between items-center px-5 my-1">
        <div className="flex flex-row items-center justify-start">
          <Link to="/home">
            <img
              src={marmaOnlyLogo}
              alt="Marma AI"
              className="h-7 bg-transparent px-2 m-1 cursor-pointer"
            />
          </Link>
        </div>
        <nav className="navigation flex items-center">
          {reduxUser && reduxUser.isVerified && userMetrics && userMetrics.totalRewardPoints > 0 && (
            <div className="bg-[#343839] text-[#D0AA45] text-xs font-medium px-2.5 py-0.5 rounded flex gap-3 justify-center items-center cursor-default">
              <Tooltip
                placement="bottom-start"
                arrow
                title="Reward Points">
                {userMetrics ? (
                  <p className="inline-flex text-sm gap-1 justify-center items-center">
                    {" "}
                    <StarsIcon sx={{ height: "18px", width: "18px" }} />
                    {userMetrics.totalRewardPoints}
                  </p>
                ) : (
                  0
                )}
              </Tooltip>
            </div>
          )}
          <Link to="/question">Free Style</Link>
          <Link to="/dashboard">Dashboard</Link>
          <Link to="/arenas">Arenas</Link>

          <div ref={headerDropdownRef}>
            {reduxUser ? (
              <Avatar
                onClick={() => setShowDropdown(!showDropDown)}
                className=" !bg-[#343839] !capitalize !text-lg !font-semibold !cursor-pointer">
                {" "}
                {reduxUser.name.split(" ")[0][0]}
              </Avatar>
            ) : (
              <Avatar
                onClick={() => setShowDropdown(!showDropDown)}
                className=" !bg-[#343839] !capitalize !text-lg !font-semibold !cursor-pointer"
                src={titleBarLogo}>
                {" "}
              </Avatar>
            )}
            {showDropDown && (
              <div
                style={styles.headerDropdownContainer}
                className="shiny-border">
                <ul className="list-none myCard space-y-2 p-6">
                  <li>
                    <Link to="/profile/notifications">Inbox </Link>
                  </li>
                  <li>
                    <Link to="/profile/basic-info">Profile</Link>
                  </li>
                  <li>
                    <Link to="/faqs">FAQs</Link>
                  </li>
                  <li>
                    <button
                      className="text-[#989898] hover:text-blue-500 m-0 p-0"
                      onClick={logout}>
                      Logout
                    </button>{" "}
                    {/* Use a button for logout */}
                  </li>
                </ul>
              </div>
            )}
          </div>
        </nav>
      </div>
    </div>
  )
}

export default CommonHeader
