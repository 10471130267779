import { getQuestionHistoryList } from "../../utils/apis";
  
  export const setQUestionHistory = (questionHistory) => {
    return {
      type: 'SET_QUESTION_HISTORY',
      payload: questionHistory,
    };
  };
  

  export const fetchQuestionHistory = () => {
    return async dispatch => {
      let response = await getQuestionHistoryList();  
      if (response.status === 200) {
        dispatch(setQUestionHistory(response.data));
      }
    };
}