import React from 'react';

const AboutUs = () => {
  return (
    <div className="recruit-page page-background">
    <div className="mt-24 mb-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 card glowing-card">
        <div className="text-center">
          <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">Welcome</h2>
          <h2 className="mt-1  sm:tracking-tight text-white">
            Welcome to Marma - Where Data Meets Dazzle!
          </h2>
          <p className="max-w-xl mt-5 mx-auto text-xl ">
            We're a vibrant team of data enthusiasts, puzzle solvers, and trendsetters, united by a singular mission to empower the next generation of analysts.
          </p>
        </div>
        
        <div className="mt-10">
          <div className=" leading-7  space-y-4">
            <p className=" leading-6  font-bold">What We Are:</p>
            <p className="">We believe that the world runs on data, but it dances to the rhythm of insights. And that's where you come in.</p>

            <p className=" leading-6 font-bold ">What We Offer:</p>
            <ul className="list-disc list-inside ">
              <li className="">Diverse Challenges: Dive into a vast ocean of problems, spanning industries, tools, and difficulty levels.</li>
              <li className="">Progress Tracking: With our state-of-the-art dashboard, watch your growth story unfold.</li>
              <li className="">Community & Collaboration: Got a question? Join the conversation in our buzzing community forum.</li>
              <li className="">Recruiter's Realm: For our corporate comrades, we've crafted a dedicated portal.</li>
            </ul>

            <p className=" leading-6 font-bold ">Why Choose Us:</p>
            <p className="">Because we're not just another platform; we're a movement. We're here to revolutionize the way you learn, practice, and showcase your skills.</p>

            <p className="leading-6 font-bold ">Join The Revolution:</p>
            <p className="">Whether you're here to hone your skills, scout for talent, or simply satiate your data curiosity, you're in for a treat.</p>

            <p className="italic ">P.S. If data had a party, we'd be the life of it. Join us, and let's make data dance!</p>
          </div>
        </div>
      
      </div>
    </div>
    </div>
  );
};

export default AboutUs;
