import { useEffect } from "react"
import { Link } from "react-router-dom"
import CommonHeader from "../assets/common-header"

import { useNavigate } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"

import WestSharpIcon from "@mui/icons-material/WestSharp"
import EastSharpIcon from "@mui/icons-material/EastSharp"
import StarsIcon from "@mui/icons-material/Stars"

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 345,
    height: 200,
    margin: theme.spacing(2),
    boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
    backgroundColor: "transparent",
    borderRadius: "8px",
    border: "0.5px solid #fefefe1a",
    color: "#fefefe",
    "&:hover": {
      boxShadow: "0px 0px 15px rgba(0,0,0,0.3)",
    },
  },
  cardContent: {
    padding: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    borderRadius: "50%",
    backgroundColor: "transparent",
    border: "none",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.1)",
    },
    textAlign: "center",
  },
  icon: {
    fontSize: 40,
    color: "#fefefe",
  },
}))

const MainPage = () => {
  const classes = useStyles()

  return (
    <div className="post-login-pages-main-container">
      <CommonHeader />
      <div className="flex-row-container">
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Typography
              variant="h5"
              component="h2">
              Solve Free Style Questions
            </Typography>
            <Button
              component={Link}
              to="/question"
              className={classes.button}>
              <StarsIcon className={classes.icon} />
            </Button>
          </CardContent>
        </Card>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Typography
              variant="h5"
              component="h2">
              Solve Questions in Arenas
            </Typography>
            <Button
              component={Link}
              to="/arenas"
              className={classes.button}>
              <StarsIcon className={classes.icon} />
            </Button>
          </CardContent>
        </Card>
      </div>
    </div>
  )
}

export default MainPage
