import React from 'react'
import { Link } from 'react-router-dom';
import { marmaOnlyLogo } from '../../assets/image_files/index.js'
import { aboutLinks, usefulLinks, socialLinks, knowMoreLinks } from '../../utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopyright } from '@fortawesome/free-solid-svg-icons'

const Footer = () => {
  return (
    <div className='border-t-2 border-gray-800 py-4'>
      <div className='container mx-auto opacity-75'>
      <div>
      <div className="flex flex-col sm:flex-col md:flex-row gap-9">
        <div className='mx-auto'>
        <img src={marmaOnlyLogo} alt="marma logo" 
        width={100}
        height={100}
        />
        </div>
      <div className="w-full flex justify-center sm:justify-center md:justify-start lg:justify-start sm:flex-row flex-row flex-wrap gap-[48px]">
          <LinkSection title="About" links={aboutLinks} />
          <LinkSection title="Useful Links" links={usefulLinks} />
          <LinkSection title="Know More" links={knowMoreLinks} />
          <LinkSection title="Social" links={socialLinks} />
      </div>
      </div>     
      <div className="text-center">
          <p className='text-white text-sm'><FontAwesomeIcon icon ={faCopyright} color ='white'/> Copyrights reserved @ Marma Consultancy and Innovations </p> 
      </div>   
    </div>
    </div>
    </div>
  )
}

export default Footer



const LinkSection = ({ title, links }) => (
  <div className=" text-white sm:w-[150px] w-[120px] md:w-auto">
    <h3 className="p-0 text-sm" style={{color:"#fff"}}>{title}</h3>
    {links.map((link, index) => (
      <div key={index} className="mb-2">
        <Link className="no-underline cursor-pointer text-slate-400 text-xs" to={link.path}>
          {link.label}
        </Link>
      </div>
    ))}
  </div>
);