import React from "react"
import "../styles.css"
import Loader from "./loader"
import { Assessment } from "@mui/icons-material"

const ResultComponent = ({ result, isAnswerCorrect, currentPage, totalPages, setCurrentPage, resultLoader }) => {
  // Determine the type of result to render the appropriate view
  const renderResult = () => {
    console.log(result, currentPage, totalPages)
    if (resultLoader) {
      return (
        <div className="m-9">
          <Loader />
        </div>
      )
    }
    if (!result) {
      return <p>Click Run or Submit for processing your answer.</p>
    }
    if (result.error) {
      return <div className="error-message">Error: {result.error}</div>
    }

    if (typeof result === "string") {
      return <div className="string-response">{result}</div>
    }

    if (Array.isArray(result) && result.length > 0) {
      // Handling table (array of objects) responses
      const columns = Object.keys(result[0])
      return (
        <>
          <h6 className={isAnswerCorrect ? "text-green-300" : "text-red-500"}>{result ? (isAnswerCorrect ? "Correct Answer" : "Incorrect Answer") : ""}</h6>
          <div className="table-container">
            <table className="result-table table-auto overflow-scroll">
              <thead>
                <tr>
                  {columns.map((column, index) => (
                    <th key={index}>{column}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {result.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {columns.map((column, colIndex) => (
                      <td key={colIndex}>{row[column]}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div>
            {currentPage > 1 ? (
              <button
                onClick={() => {
                  setCurrentPage(currentPage - 1)
                }}
                disabled={currentPage <= 1}>
                Previous
              </button>
            ) : null}
            {currentPage < totalPages ? (
              <button
                onClick={() => {
                  setCurrentPage(currentPage + 1)
                }}>
                Next
              </button>
            ) : null}
          </div>
        </>
      )
    } else {
      // Fallback for any other types of results
      return <p>Unexpected result format.</p>
    }
  }

  return (
    <div className="result-component">
      <div className="result-heading-container px-3 py-1">
        <h6 className="text-sm text-gray-400"><span className="text-emerald-600"><Assessment className=""/></span> Test Result</h6>
      </div>
      <div className="p-3 text-sm text-gray-400">
      {renderResult()}
      </div>
    </div>
  )
}

export default ResultComponent
