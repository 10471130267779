import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import userReducer from './reducers/user';
import pricingReducer from './reducers/pricing';
import faqsReducer from './reducers/faqs';
import arenaReducer from './reducers/arena'; // Import the arena reducer
import questionHistoryReducer from './reducers/questionhistory'
import userMetricsReducer from './reducers/usermetrics';

const rootReducer = combineReducers({
    user: userReducer,
    pricing: pricingReducer,
    faqs: faqsReducer,
    arena: arenaReducer, // Add the arena reducer to the combined reducers
    questionHistory: questionHistoryReducer,
    userMetrics: userMetricsReducer
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;