import React, { useState, useEffect } from "react"
import "chart.js/auto"
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, LabelList, CartesianGrid } from "recharts"
import { fetchFChartData } from "../utils/apis"
import { toast } from "react-toastify"
import Loader from "../assets/loader"
import { getUniqueId } from "../utils/common-function"
import { Button } from "@mui/material"

const RewardPointsFrequencyChart = ({ data, user, selectedGraph }) => {
  const CustomToolTip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#222",
            borderRadius: 5,
            padding: 5,
            color: "white",
          }}>
          {/* {console.log(payload)} */}
          <p className="label">{selectedGraph === "Percentile" ? `Percentile: ${label}` : `Reward Points: ${label}`}</p>
          {payload.map((entry, index) => (
            <p
              key={`item-${index}`}
              style={{ color: entry.color }}>
              <span className="text-gray-400">{selectedGraph === "Percentile" ? `Reward Points: ${parseFloat(entry.value)}` : `People: ${parseFloat(entry.value)}`}</span>
            </p>
          ))}
        </div>
      )
    }
    return null
  }

  const binSize = 200
  let maxPoints = 0

  // Find the maximum reward points to determine the range of the x-axis
  data.forEach((item) => {
    if (item.totalRewardPoints > maxPoints) {
      maxPoints = item.totalRewardPoints
    }
  })

  // Initialize bins
  const bins = Array(Math.ceil(maxPoints / binSize)).fill(0)
  let currentUserBin = null

  // Fill bins with counts
  data.forEach((item) => {
    const binIndex = Math.floor(item.totalRewardPoints / binSize)
    bins[binIndex]++
    if (item.isCurrentUser) {
      currentUserBin = binIndex
    }
  })

  // Convert counts to percentages

  const binData = bins.map((count, index) => ({
    bin: `${index * binSize}`,
    Percentage: ((count / data.length) * 100).toFixed(2),
    fill: index === currentUserBin ? "#e5e7eb" : "#4b5563", // White for current user, grey otherwise
    label: index === currentUserBin ? `${user[0]?.name}` : "",
    tooltip: `${index * binSize} -  ${(index + 1) * binSize - 1}`,
  }))

  function groupByPercentageRangeAndCalculateAverage(data) {
    // Define percentile ranges
    const ranges = [
      { min: 0, max: 4 },
      { min: 4, max: 8 },
      { min: 8, max: 12 },
      { min: 12, max: 16 },
      { min: 16, max: 20 },
      { min: 20, max: 24 },
      { min: 24, max: 28 },
      { min: 28, max: 32 },
      { min: 32, max: 36 },
      { min: 36, max: 40 },
      { min: 40, max: 44 },
      { min: 44, max: 48 },
      { min: 48, max: 52 },
      { min: 52, max: 56 },
      { min: 56, max: 60 },
      { min: 60, max: 64 },
      { min: 64, max: 68 },
      { min: 68, max: 72 },
      { min: 72, max: 76 },
      { min: 76, max: 80 },
      { min: 80, max: 84 },
      { min: 84, max: 88 },
      { min: 87, max: 92 },
      { min: 92, max: 96 },
      { min: 96, max: 100 }
    ];

    // Create a map to group objects by percentile range
    const groups = data.reduce((acc, item) => {
      const percentage = parseFloat(item.Percentage);
      const range = ranges.find(r => percentage >= r.min && percentage <= r.max);
      const rangeKey = range ? `${range.min}-${range.max}` : 'Other';

      if (!acc[rangeKey]) {
        acc[rangeKey] = {
          totalPoints: 0,
          count: 0,
          items: []
        };
      }
      const binValue = parseInt(item.bin, 10);
      acc[rangeKey].totalPoints += binValue;
      acc[rangeKey].count += 1;
      acc[rangeKey].items.push(item);
      return acc;
    }, {});

    // Calculate the average reward points for each range
    const result = Object.keys(groups).map(rangeKey => {
      const group = groups[rangeKey];
      const averagePoints = group.count > 0 ? group.totalPoints / group.count : 0;
      return {
        Range: rangeKey,
        AverageRewardPoints: averagePoints,
        Items: group.items
      };
    });

    result.sort((a, b) => {
      const aMin = parseInt(a.Range.split('-')[0], 10);
      const bMin = parseInt(b.Range.split('-')[0], 10);
      return aMin - bMin;
    });

    return result;
  }

  function groupBinsAndCountPeople(data) {
    // Extract all bin values and convert them to numbers
    const bins = data.map(item => parseInt(item.bin, 10));
    
    // Calculate the minimum and maximum bin values
    const minBin = Math.min(...bins);
    const maxBin = Math.max(...bins);

    // Define the range step (you can adjust this value as needed)
    const rangeStep = 1000; // for example, ranges of 1000

    // Generate dynamic ranges based on the min and max bins
    const ranges = [];
    for (let start = minBin; start < maxBin; start += rangeStep) {
        const end = start + rangeStep;
        ranges.push(`${start}-${end}`);
    }

    // Ensure the last range goes up to the next step of maxBin
    const lastStart = Math.floor(maxBin / rangeStep) * rangeStep;
    const lastEnd = lastStart + rangeStep;
    ranges.push(`${lastStart}-${lastEnd}`);

    // Initialize an object to store the count of people for each range
    const rangeCount = ranges.map(range => ({
        Range: range,
        Peoples: 0
    }));

    // Iterate over each item in the dataset
    data.forEach(item => {
        const binStart = parseInt(item.bin, 10);
        
        // Check which range the bin falls into
        rangeCount.forEach(rangeObj => {
            const [start, end] = rangeObj.Range.split('-').map(Number);
            if (binStart >= start && binStart < end) {
                rangeObj.Peoples += 1;
            }
        });
    });

    return rangeCount;
}

  const [graphData, setGraphData] = useState(groupByPercentageRangeAndCalculateAverage(binData));

  useEffect(() => {
    if (selectedGraph === "Percentile") {
      setGraphData(groupByPercentageRangeAndCalculateAverage(binData))
    }
    else {
      const d = groupBinsAndCountPeople(binData)
      setGraphData(d)
      console.log(d, "datattatat")
      
    }
  }, [selectedGraph])

  const renderGradients = () => (
    <defs>
      <linearGradient
        id={`barGradient`}
        x1="0%"
        y1="0%"
        x2="100%"
        y2="100%"
      >
        <stop offset="0%" stopColor={"#9f1239"} />
        <stop offset="100%" stopColor={"#fb7185"} />
      </linearGradient>
    </defs>
  );

  return (
      selectedGraph === "Percentile" ?
      <BarChart
        width={800}
        height={400}
        data={graphData}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        {renderGradients()}
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="Range" />
        <YAxis />
        <Tooltip content={<CustomToolTip />} cursor={{fill: '#374151', opacity:".5"}} />
        <Bar dataKey="AverageRewardPoints" fill={`url(#barGradient)`} radius={[6, 6, 0, 0]} maxBarSize={50} barSize={30}/>
      </BarChart>
      :
      <BarChart
        width={800}
        height={400}
        data={graphData}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        {renderGradients()}
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="Range" />
        <YAxis />
        <Tooltip content={<CustomToolTip />} cursor={{fill: '#374151', opacity:".5"}}/>
        <Bar dataKey="Peoples" fill={`url(#barGradient)`} radius={[6, 6, 0, 0]} maxBarSize={50} barSize={30} />
      </BarChart>
  )
}

const Fchart = ({ userRewardPoints, currUserData, forPublic, filterParams }) => {
  const [data, setData] = useState([])
  const [status, setStatus] = useState("idle")
  const [currentuserData, setCurrrentUserData] = useState()
  const [selectedGraph, setSelectedGraph] = useState("Percentile")


  const fetchData = async () => {
    const uniqueId = forPublic ? getUniqueId(window.location.href) : null
    const response = await fetchFChartData(uniqueId, filterParams)

    if (response.status === 200) {
      if (response.data.length > 0) {
        setData(response.data)
        const user = response.data.filter((item) => item.isCurrentUser === true)
        setCurrrentUserData(user)
        currUserData(user)
        userRewardPoints(user[0]?.totalRewardPoints)
      }
      setStatus("success")
    } else {
      setStatus("failure")
      console.error("Failed to fetch data")
      toast.error("Unable to Fetch Your Progress!")
    }
  }

  useEffect(() => {
    fetchData()
  }, [filterParams])

  let content

  if (status === "idle") {
    content = <Loader />
  } else if (status === "no_data") {
    content = <h1 className="text-center text-sm text-gray-400">No Data to Display <br></br>Solve Questions to see your Progress!</h1>
  } else if (status === "failure") {
    content = <h1>Unable to Fetch Data Try Again!</h1>
  } else {
    content = (
      <div>
        <h6 className="text-center text-xl font-semibold p-4">
          Relative Position
        </h6>
        <div className="flex gap-3 flex-wrap justify-center items-center">
          <Button
            size="small"
            variant={`${selectedGraph === "Percentile" ? "contained" : "outlined"}`}
            className={`!shadow-none !text-sm !capitalize !border !border-gray-600 !text-gray-200 ${selectedGraph === "Percentile" && "!bg-gray-600"}`}
            onClick={(e) => setSelectedGraph("Percentile")}>
            Percentile Chart
          </Button>

          <Button
            size="small"
            variant={`${selectedGraph === "Distribution" ? "contained" : "outlined"}`}
            className={`!shadow-none !text-sm !capitalize !border !border-gray-600 !text-gray-200 ${selectedGraph === "Distribution" && "!bg-gray-600"}`}
            onClick={(e) => setSelectedGraph("Distribution")}>
            Distribution Chart
          </Button>
        </div>
        <div className="h-full flex justify-center">
          <RewardPointsFrequencyChart
            data={data}
            user={currentuserData}
            selectedGraph={selectedGraph}
          />
        </div>
      </div>
    )
  }

  return (
    <div
      className="h-full overflow-hidden flex justify-center items-center">
      {content}
    </div>
  )
}

export default Fchart
