const initialState = {
    userMetrics:{}
};

const userMetricsReducer =  (state = initialState, action) => {
    switch(action.type){
        case 'SET_USER_METRICS':
            return {
                ...state,
                userMetrics: action.payload
            };
        default:
            return state;    
    }
};

export default userMetricsReducer;