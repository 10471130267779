import React, { useEffect, useState, useRef } from "react"
import "../styles.css"
import { validateAnswer, runQuery, fetchArenaQuestion } from "../utils/apis"
import { toast } from "react-toastify"
import { useNavigate, useSearchParams, Link, useParams } from "react-router-dom"
import QuestionComponent from "../assets/question"
import SolutionUploadComponent from "../assets/solution-upload"
import { useDispatch, useSelector } from "react-redux"
import { fetchUserDetails, setUser } from "../redux/actions/user"
import ToolLevelForm from "./get-question-filters"
import { LEVELS, SUBJECTS } from "../utils/constants"
import { createArray } from "../utils/common-function"
import { useLocation } from "react-router-dom"
import Loader from "../assets/loader"
import ResultComponent from "../assets/result"
import Modal from "../assets/modal"
import { LANGUAGES } from "../utils/constants"
// import SplitPane from "react-split-pane"
import SplitPane, { Pane } from 'split-pane-react';
import 'split-pane-react/esm/themes/default.css'
import CommonHeader from "../assets/common-header"
import { Button, Tooltip } from "@mui/material"
import ArenaQuestionHeader from "./arena-question-header"
import { fetchCurrentArenaData } from "../redux/actions/arena"

const ArenaQuestionPage = ({ route, navigation }) => {
  const levels = createArray(LEVELS)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const userDetails = useSelector((state) => state.user.userDetails)
  const currentArena = useSelector((state) => state.arena.currentArena)
  const [loader, setLoader] = useState(false)
  const [isInitialLoad, setIsInitialLoad] = useState(true)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [confirmationModalMessage, setConfirmationModalMessage] = useState("")
  const [mode, setMode] = useState(LANGUAGES[0].key)
  const [subscribeComponent, setSubsscribeComponent] = useState(false)
  const [displayIntroForm, setDisaplyIntroForm] = useState(false)
  const [questionMeta, setQuestionMeta] = useState(null)
  const [selectedToolName, setSelectedToolName] = useState()
  const [selectedTechStack, setSelectedTechStack] = useState()
  const [selectedIndustry, setSelectedIndustry] = useState([])
  const [selectedLevel, setSelectedLevel] = useState()
  const [selectedTopic, setSelectedTopic] = useState([])
  const [searchParams, setSearchParams] = useSearchParams()
  const [submittedAnswer, setSubmittedAnswer] = useState("")
  const [answerResult, setAnswerResult] = useState(null)
  const [result, setResult] = useState(null)
  const [isError, setIsError] = useState(false)
  const [submittedKey, onSetSubKey] = useState("")
  const [currentPageRes, setcurrentPageRes] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [pendingToolName, setPendingToolName] = useState(null)
  const [pendingLevel, setPendingLevel] = useState(null)
  const [searchFilterList, setSearchFilterList] = useState({})
  const [techStackUpdate, setTechStackUpdate] = useState({ updating: false, questionId: null })
  const [showSuccessPage, setShowSuccessPage] = useState(false)
  const [quesFetchError, setquesFetchError] = useState(false)
  const [summaryContent, setSummaryContent] = useState("")
  const [resultLoader, setResultLoader] = useState(false)
  const [timer, setTimer] = useState(0)
  const timerRef = useRef(null)

  useEffect(() => {
    if (questionMeta) {
      // Start the timer when metadata is loaded
      setTimer(0)
      startTimer()
    }

    return () => {
      // Clean up the timer when component unmounts
      stopTimer()
    }
  }, [questionMeta])

  const startTimer = () => {
    timerRef.current = setInterval(() => {
      setTimer((prevTime) => prevTime + 1)
    }, 1000)
  }

  const stopTimer = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current)
    }
  }

  const { name, roadmap } = useParams()

  const limit = 10

  const fetchMetaData = async (forceFetch = false, questionId) => {
    if (forceFetch || isInitialLoad) {
      try {
        console.log("=", forceFetch, isInitialLoad, selectedToolName)
        await getQuestionMetaData(forceFetch, questionId)

        setDisaplyIntroForm(false)
        if (isInitialLoad) setIsInitialLoad(false)
      } catch (error) {
        console.error("Failed to fetch question metadata:", error)
        // Handle error appropriately
      }
    } else {
      // Show confirmation modal
      setShowConfirmationModal(true)
    }
  }

  const confirmUpdate = async (confirm) => {
    setShowConfirmationModal(false)
    if (confirm) {
      if (pendingToolName !== null) {
        console.log("5")
        console.log(pendingToolName, "a")
        setSelectedToolName(pendingToolName)
        setPendingToolName(null) // Reset pending tool name
      }
      if (pendingLevel !== null) {
        setSelectedLevel(pendingLevel)
        setPendingLevel(null) // Reset pending level
      }
    } else {
      // User canceled, just reset pending values
      setPendingToolName(null)
      setPendingLevel(null)
    }
  }

  useEffect(() => {
    if (loader) return
    dispatch(fetchUserDetails())
  }, [dispatch])

  useEffect(() => {
    if (loader) return

    if (!userDetails) return

    if (userDetails && !userDetails.isVerified) {
      toast.error("Please verify your email to continue")
      navigate("/verify-email")
    }

    if (isInitialLoad && userDetails && userDetails.questionPreferences) {
      if (userDetails.questionPreferences.toolName) {
        console.log("2")

        setSelectedToolName(userDetails.questionPreferences.toolName)
      }
      if (userDetails.questionPreferences.level) {
        setSelectedLevel(userDetails.questionPreferences.level)
      }
    }

    const shouldFetchMetaData = selectedToolName && (selectedLevel || techStackUpdate.questionId || searchParams.get("questionId"))
    console.log(shouldFetchMetaData, userDetails, isInitialLoad)

    if (isInitialLoad) {
      if (shouldFetchMetaData) {
        fetchMetaData() // No need to await here, since useEffect cannot be async
      } else {
        setDisaplyIntroForm(true)
      }
    } else {
      if (shouldFetchMetaData) {
        if (techStackUpdate.updating) {
          fetchMetaData(true, techStackUpdate.questionId)
          setTechStackUpdate({ updating: false, questionId: "" })
        } else {
          fetchMetaData(true)
        }
      }
    }

    if (currentArena.length === 0) dispatch(fetchCurrentArenaData(name))
  }, [selectedLevel, selectedToolName, selectedTopic, selectedIndustry, userDetails])

  const resetComponent = () => {
    setQuestionMeta(null)
    setSubmittedAnswer(null)
    setAnswerResult(null)
    onSetSubKey("")
    setSummaryContent(null)

    // setSelectedToolName(selectedTechStack)
    setIsError(false)
    setResult(null)
    setTotalPages(0)
    setcurrentPageRes(1)
    setShowSuccessPage(false)
  }

  const getQuestionMetaData = async (isNext = false, questionId, fromPrev = false) => {
    setLoader(true)
    resetComponent()
    if (!isNext && !fromPrev) {
      if (questionId === undefined) {
        questionId = window.location.href.split("?questionId=")[1]
      }
    }
    const direction = isNext === true ? "next" : fromPrev === true ? "prev" : ""
    const currentQuestionId = direction !== "" ? window.location.href.split("?questionId=")[1] : ""

    try {
      setSelectedTechStack(selectedToolName)
      const queryParams = new URLSearchParams({
        questionId,
        toolName: selectedToolName, // remember that this has ti be modified
        level: selectedLevel || "",
        func: selectedTopic || [],
        industry: selectedIndustry || [],
        isInitialLoad,
        name,
        arenaId: currentArena._id,
        roadmap,
        direction,
        currentQuestionId,
      })
      let response = await fetchArenaQuestion(queryParams)
      if (response.status == 200) {
        setQuestionMeta(response.data)
        if (response.data.answerSubmitted && response.data.answerSubmitted.length > 0 && response.data.rewardPoints) {
          toast.info(`You have already submitted your solution & scored ${response.data.rewardPoints} reward points, however you can practice this problem as many times as you wish`, {
            position: toast.POSITION.TOP_CENTER,
            theme: "dark",
          })
        }
        if (selectedToolName === "Python") {
          setSubmittedAnswer(response.data.solutionTemplate)
        }
        navigate(`?questionId=${response.data.questionId}`, { replace: true })
      } else {
        toast.error(response.message || "Some error has occured")
        setquesFetchError(true)
        if (response.message === "No question found") {
          setLoader(false)
        }
        if (response.message === "Free tier complete") {
          setSubsscribeComponent(true)
          setLoader(false)
        }
      }
    } catch (error) {
      console.error(error)
      setquesFetchError(true)
      toast.error("Some error has occured")
    } finally {
      setLoader(false)
    }
  }

  const handleFileUpload = (file) => {
    setSubmittedAnswer(file)
  }

  const handleTextInput = (v) => {
    setSubmittedAnswer(v)
  }

  const getValidateObj = (key) => {
    let answer = submittedAnswer
    const formData = new FormData()
    selectedToolName === "Excel" ? formData.append("file", answer) : formData.append("submittedAnswer", answer)
    formData.append("questionId", questionMeta.questionId)
    formData.append("techStack", selectedTechStack)
    formData.append("language", LANGUAGES.find((l) => l.key === mode).value)
    formData.append("userQuestionHistoryId", questionMeta.userQuestionHistoryId)
    formData.append("limit", limit)
    formData.append("page", currentPageRes)
    formData.append("submittedKey", key)
    selectedToolName === "Excel" && formData.append("ExcelFunctions", questionMeta.ExcelFunctions)
    selectedToolName === "SQL" && formData.append("SQLFunctions", questionMeta.SQLFunctions)
    selectedToolName === "Python" && formData.append("PythonFunctions", questionMeta.PythonFunctions)
    formData.append("arenaId", currentArena._id)
    formData.append("arenaName", name)
    formData.append("roadmapName", roadmap)
    questionMeta.isInitialQuestion === true && formData.append("isInitialQuestion", questionMeta.isInitialQuestion)
    key === "submit" && formData.append("insightsSummary", summaryContent)
    key === "submit" && formData.append("timeSpent", timer)

    return formData
  }

  const updateResultSection = (response, key) => {
    onSetSubKey(key)
    setResultLoader(false)
    let isAnswerCorrect = false
    if (response.status == 200) {
      console.log(response.data.count)
      setTotalPages(response.data.count)

      if (response.data.isAnswerCorrect) {
        setIsError(false)
        setResult(response.data.submittedResult)
        isAnswerCorrect = true
      } else {
        setIsError(true)
        key === "submit" ? setResult(response.message) : setResult(response.data.submittedResult)
      }
    } else {
      setIsError(true)
      setResult(response.message || "Some error has occured")
    }
    setAnswerResult(isAnswerCorrect)
    if (key == "submit") {
      if (isAnswerCorrect) {
        setTimer(0)
        setShowSuccessPage(true)
      }
      if (!isAnswerCorrect) {
        startTimer()
        toast.error("Incorrect", {
          position: toast.POSITION.TOP_CENTER,
          theme: "dark",
        })
      }
    }
  }

  const onSubmit = async (key, page) => {
    try {
      if (!submittedAnswer) {
        toast.error("Please add the solution inside function", {
          position: toast.POSITION.TOP_CENTER,
          theme: "dark",
        })
        return
      }
      if (key === "submit" && !summaryContent) {
        toast.error(`Please add your insights in the summary section before submitting.`, {
          position: toast.POSITION.TOP_CENTER,
          theme: "dark",
        })
        return
      }
      let formData = getValidateObj(key)
      setResultLoader(true)
      let response = key === "submit" ? await validateAnswer(formData) : await runQuery(formData)
      if (key === "submit") {
        stopTimer()

        // Handle form submission logic here
        console.log("Time taken:", timer, "seconds")
      }
      updateResultSection(response, key)
    } catch (err) {
      setIsError(true)
      setResult(err.message || "Some error has occured")
    }
  }

  const onChangeTechStack = async (t) => {
    if (t === "Python") {
      setSubmittedAnswer(questionMeta.solutionTemplate)
    } else {
      setSubmittedAnswer("")
    }
    console.log("4")
    const currentQuestionId = searchParams.get("questionId")
    setTechStackUpdate({ updating: true, questionId: currentQuestionId })

    setSelectedTechStack(t)
    setSelectedToolName(t)
  }

  const onChangePrimary = (k, v) => {
    let messagePart = ""

    let pref = userDetails?.questionPreferences
    if (!pref) {
      pref = {
        toolName: selectedToolName,
        level: selectedLevel,
      }
    }
    if (k === "toolName") {
      messagePart = `tool ${v}`
      setPendingToolName(v)
    }
    if (k == "level") {
      messagePart = `level ${v}`
      setPendingLevel(v)
    }

    setConfirmationModalMessage(`Are you sure you want to skip the current question by updating ${messagePart}?`)
    setShowConfirmationModal(true)

    pref[k] = v
    setUser((prevDetails) => ({
      ...prevDetails,
      questionPreferences: pref,
    }))
  }

  const [sizes, setSizes] = useState([
    400,
    '40%'
  ]);

  const layoutCSS = {
    height: '100%',
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center',
  };

  const inlineStyle = {
    display: "flex",
    overflow: "auto",
    height: "calc(100vh - 90px)",
    width: "100%",
    marginTop: "90px",
    backgroundColor: "rgba(35, 38, 39, 1)",
    position: "relative",
  }
  const inlineStyleLoading = {
    overflow: "auto",
    height: "calc(100vh - 50px)",
    width: "100%",
    marginTop: "50px",
    backgroundColor: "rgba(35, 38, 39, 1)",
    position: "relative",
    color: "white",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  }

  const rightSectionStyles = {
    overflow: "auto",
    borderRadius: "8px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    padding: "0.4rem",
    margin: "0.5rem",
    background: "#141718",
    flexGrow: "1",
    marginLeft: "5px",
    height: "97%",
    color: "white",
    flex: "1",
  }

  return (
    <div>
      {loader ? (
        <div
          className=""
          style={inlineStyleLoading}>
          <CommonHeader />
          <Loader />
        </div>
      ) : displayIntroForm ? (
        <ToolLevelForm
          onSubmit={(e) => {
            console.log("1")
            setSelectedToolName(e.tool)
            setSelectedLevel(e.level)
          }}
        />
      ) : subscribeComponent ? (
        <Modal
          isOpen={subscribeComponent}
          onClose={() => setSubsscribeComponent(!subscribeComponent)}
          title="Free trial Complete!">
          <p>
            Free tier completed <Link to={"/pricing"}>please subscribe to avail benifits</Link>
          </p>
          <button onClick={() => setSubsscribeComponent(!subscribeComponent)}>Close Modal</button>
        </Modal>
      ) : showConfirmationModal ? (
        <Modal
          isOpen={showConfirmationModal}
          onClose={() => setShowConfirmationModal(false)}
          title="Confirm Action">
          <p className='text-gray-400 text-sm mt-2'>{confirmationModalMessage}</p>
          <div className='flex gap-2 mt-4'>
            <Button variant='contained' size='small' className='!shadow-none !bg-gray-600 !text-gray-200 !border-0' onClick={() => confirmUpdate(true)}>Yes</Button>
            <Button variant='outlined' size='small' className='!shadow-none !text-gray-200 !border !border-gray-600' onClick={() => confirmUpdate(false)}>No</Button>
          </div>
        </Modal>
      ) : showSuccessPage ? (
        <Modal
          isOpen={showSuccessPage}
          onClose={() => setShowSuccessPage(false)}
          title="Confirm Action">
          <p className='text-gray-400 text-sm mt-2'>{"Suberb ! Move to Next question ?"}</p>
          <div className='flex gap-2 mt-4'>
            <Button variant='contained' size='small' className='!shadow-none !bg-gray-600 !text-gray-200 !border-0' onClick={() => getQuestionMetaData(true)}>Yes</Button>
            <Button variant='outlined' size='small' className='!shadow-none !text-gray-200 !border !border-gray-600' onClick={() => setShowSuccessPage(false)}>No</Button>
          </div>
        </Modal>
      ) : !questionMeta ? (
        <div
          className=""
          style={inlineStyleLoading}>
          <CommonHeader />
          <div className="flex flex-col justify-center items-center">
            {quesFetchError ? (
              <>
                <h2>Unable to Fetch Current Question</h2>
                <button
                  onClick={() => getQuestionMetaData(true)}
                  className="text-white border-[2px]">
                  Switch to Next Question
                </button>
                <Tooltip
                  title="Subjects/Tools"
                  placement="bottom"
                  arrow>
                  <div className="">
                    <select
                      id="subject-select"
                      value={selectedToolName}
                      onChange={(event) => onChangePrimary("toolName", event.target.value)}
                      className="drop-down-container">
                      {SUBJECTS.map((subject) => (
                        <option
                          value={subject}
                          key={subject}
                          className="p-2 text-pretty text-[17px]">
                          {subject}
                        </option>
                      ))}
                    </select>
                  </div>
                </Tooltip>
              </>
            ) : (
              <Loader />
            )}
          </div>
        </div>
      ) : (
        <div className="bg-[#232627]">
          <ArenaQuestionHeader
            primaryTool={selectedToolName}
            selectedLevel={selectedLevel}
            onChangeSubject={(subject) => onChangePrimary("toolName", subject)}
            onChangeLevel={(level) => onChangePrimary("level", level)}
            levels={levels}
            onSubmit={onSubmit}
            isQuestionFetched={questionMeta ? true : false}
            searchFilterList={searchFilterList}
            selectedIndustry={selectedIndustry}
            selectedTopic={selectedTopic}
            getQuestionMetaData={getQuestionMetaData}
            questionMeta={questionMeta}
            resetComponent={resetComponent}
          />
          <SplitPane
            split='vertical'
            sizes={sizes}
            onChange={setSizes}
            style={inlineStyle}
          >
            <Pane minSize={500} maxSize='50%'>
              <div className="question-data-wrapper">
                <QuestionComponent
                  questionMeta={questionMeta}
                  techStack={selectedTechStack}
                  onSubmit={onSubmit}
                  isQuestionFetched={questionMeta ? true : false}
                  onChangeTechStack={onChangeTechStack}
                  techStacks={questionMeta?.techStacks}
                  primaryTool={selectedToolName}
                />
              </div>
            </Pane>

            <div
              style={rightSectionStyles}
            // minSize={"65%"} split = "horizontal"
            >
              <SolutionUploadComponent
                tool={selectedTechStack}
                handleFileUpload={handleFileUpload}
                handleTextInput={handleTextInput}
                answer={submittedAnswer}
                handleModeChange={(e) => {
                  setMode(e.target.value)
                }}
                LANGUAGES={LANGUAGES}
                mode={mode}
                onSubmit={onSubmit}
                onChangeTechStack={onChangeTechStack}
                techStacks={questionMeta?.techStacks}
                selectedTechStack={selectedTechStack}
                isQuestionFetched={questionMeta ? true : false}
                primaryTool={selectedToolName}
                isArena={window.location.href.includes("arena-question") ? true : false}
                handleSummaryContent={(text) => {
                  setSummaryContent(text)
                }}
                summaryContent={summaryContent}
                questionMeta={questionMeta}
                timer={timer}
                stopTimer={stopTimer}
                startTimer={startTimer}
              />
              <ResultComponent
                result={result}
                isAnswerCorrect={answerResult}
                currentPage={currentPageRes}
                setCurrentPage={(v) => {
                  setcurrentPageRes(v)
                  onSubmit(submittedKey, v)
                }}
                totalPages={totalPages}
                resultLoader={resultLoader}
              />
            </div>
          </SplitPane>
        </div>
      )}
    </div>
  )
}

export default ArenaQuestionPage
