import { EmojiEventsOutlined, LeaderboardRounded, SettingsOutlined } from '@mui/icons-material';
import React, { useState } from 'react';
import Dropdown from '../ui/Dropdown.jsx';
import { NavLink } from 'react-router-dom';
import { Divider, Menu, MenuItem, MenuList } from '@mui/material';

const SideNavDashboard = ({ enrolledArenas, setMode, handleFilterChange, filterBy }) => {
    const [isSideNavOpen, setIsSideNavOpen] = useState(false);
    const [isArenaSelected, setIsArenaSelected] = useState(false);

    const toggleSideNav = () => {
        setIsSideNavOpen(!isSideNavOpen);
    };

    const handleMode = () => {
        const newMode = isArenaSelected ? "Free Style" : "Arena";

        setIsArenaSelected(!isArenaSelected);
        if (newMode === "Arena") {
            handleFilterChange(enrolledArenas[0]._id);
        } else {
            handleFilterChange("Free Style");
        }
        setMode(newMode);
    }


    return (
        <>
            <button
                type="button"
                className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700"
                onClick={toggleSideNav}
            >
                <span className="sr-only">Open sidebar</span>
                <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path clipRule="evenodd" fillRule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                </svg>
            </button>

            <aside className={`fixed !z-60 top-0 left-0 w-64 h-screen transition-transform ${isSideNavOpen ? 'translate-x-0' : '-translate-x-full'} sm:translate-x-0`}>
                <div className="h-full overflow-y-auto">
                    <button
                        type="button"
                        className="inline-flex items-center p-2 mt-12 ms-6 text-sm text-gray-500 rounded-lg sm:block block md:hidden hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700"
                        onClick={toggleSideNav}
                    >
                        <span className="sr-only">Open sidebar</span>
                        <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path clipRule="evenodd" fillRule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                        </svg>
                    </button>
                    <ul className="space-y-2 font-medium mt-4 md:mt-12 p-4">
                        <span className='ms-3 text-gray-400 text-xs p-2'>Select Mode</span>
                        <li className='text-gray-200 p-2 !m-0 text-sm'>
                            <label className="inline-flex items-center cursor-pointer justify-between ms-3 w-full">
                                <div className="flex"><span className="relative inline-block overflow-hidden rounded-full p-[1px]"><span className={`absolute inset-[-1000%] ${!isArenaSelected && "animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#a9a9a9_0%,#0c0c0c_50%,#a9a9a9_100%)] dark:bg-[conic-gradient(from_90deg_at_50%_50%,#171717_0%,#737373_50%,#171717_100%)]"}`}></span><div className="inline-flex h-full w-full cursor-pointer justify-center rounded-full bg-white px-3 py-1 text-xs font-medium leading-5 text-slate-600 backdrop-blur-xl dark:bg-gray-700 !bg-opacity-20 dark:text-slate-200">Free Style</div></span></div>
                                {
                                    enrolledArenas?.length > 0 &&
                                    <>
                                        <input type="checkbox" value="" className="sr-only peer" checked={isArenaSelected} onChange={handleMode} />
                                        <div className="relative w-8 h-5 rounded-full peer bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[4px] after:start-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-3 after:w-3 after:transition-all border-gray-600 peer-checked:bg-amber-500"></div>

                                        <div className="flex"><span className="relative inline-block overflow-hidden rounded-full p-[1px]"><span className={`absolute inset-[-1000%] ${isArenaSelected && "animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#a9a9a9_0%,#0c0c0c_50%,#a9a9a9_100%)] dark:bg-[conic-gradient(from_90deg_at_50%_50%,#171717_0%,#737373_50%,#171717_100%)]"}`}></span><div className="inline-flex h-full w-full cursor-pointer justify-center rounded-full bg-white px-3 py-1 text-xs font-medium leading-5 text-slate-600 backdrop-blur-xl dark:bg-gray-700 !bg-opacity-20 dark:text-slate-200">Arena</div></span></div>
                                    </>
                                }

                            </label>
                        </li>


                        {isArenaSelected && (
                            <div className='ms-5'>
                                <Dropdown
                                    title="Arena"
                                    tooltip={`Solve the situation using different languages`}

                                >
                                    {enrolledArenas &&
                                        enrolledArenas.map((arena) => (
                                            <MenuList
                                                key={arena.arenaId}
                                                label={arena.name}>
                                                <MenuItem
                                                    className='!text-gray-200 !text-md'
                                                    value={arena._id}
                                                    onClick={(e) => handleFilterChange(arena._id)}
                                                >
                                                    {arena.name}
                                                </MenuItem>
                                                <Divider className='!m-0 !border-gray-600' />
                                                {arena.roadmaps.map((roadmap) => (
                                                    <MenuItem
                                                        className='!text-gray-400 !text-sm !ms-3'
                                                        key={roadmap.name}
                                                        value={`${arena._id}|${roadmap.name}`}
                                                        onClick={(e) => handleFilterChange(arena._id + "|" + roadmap.name)}
                                                    >
                                                        {roadmap.name}
                                                    </MenuItem>
                                                ))}
                                            </MenuList>
                                        ))}
                                </Dropdown>
                            </div>
                        )}

                        <hr className="w-full h-px mx-auto !my-2 bg-gray-100 border-0 rounded dark:bg-gray-700"></hr>

                        <NavLink to="/dashboard" end
                            className={({ isActive, isPending }) =>
                                isActive ? "text-gray-200" : "text-gray-500"
                            }

                        >
                            <li className='p-2 cursor-pointer hover:bg-gray-400 hover:bg-opacity-20 transition-all rounded-lg text-sm'>
                                <EmojiEventsOutlined className='!text-base ms-3' /> <span className="ms-2">Leaderboard</span>
                            </li>
                        </NavLink>

                        <NavLink to="/dashboard/performance"
                            className={({ isActive, isPending }) =>
                                isActive ? "text-gray-200" : "text-gray-500"
                            }
                        >
                            <li className='p-2 cursor-pointer hover:bg-gray-400 hover:bg-opacity-20 transition-all rounded-lg text-sm'>
                                <LeaderboardRounded className='!text-base ms-3' /> <span className="ms-2">Performance</span>
                            </li>
                        </NavLink>

                        <NavLink to="/dashboard/settings"
                            className={({ isActive, isPending }) =>
                                isActive ? "text-gray-200" : "text-gray-500"
                            }

                        >
                            <li className='p-2 cursor-pointer hover:bg-gray-400 hover:bg-opacity-20 transition-all rounded-lg text-sm'>
                                <SettingsOutlined className='!text-base ms-3' /> <span className="ms-2">Settings</span>
                            </li>
                        </NavLink>
                    </ul>
                </div>
            </aside>
        </>
    );
};

export default SideNavDashboard;
