import React, { useState, useEffect } from "react"
import { getNotifications, handleSeeNotification } from "../utils/apis"
import { Button, Link as MaterialLink } from "@mui/material"
import Loader from "./loader"
import { Link } from "react-router-dom"
import { emptyInbox } from "../assets/image_files"

const Notifications = ({ fetchUnseenCount }) => {
  const [notifications, setNotifications] = useState(null)

  useEffect(() => {
    const fetchUnseenNotifications = async () => {
      const response = await getNotifications()

      if (response.status === 200) {
        setNotifications(response.data)
      } else {
        setNotifications([])
      }
    }
    fetchUnseenNotifications()
  }, [])

  const handleUpdate = async (notificationId) => {
    const response = await handleSeeNotification(notificationId)
    if (response.status === 200) {
      fetchUnseenCount()
      let updatedStatus = notifications
      updatedStatus = updatedStatus.map((notification) => {
        if (notification._id === notificationId) {
          notification.seen = true
        }
        return notification
      })
      setNotifications(updatedStatus)
    }
  }

  const handleDate = (dateString) => {
    const dateObject = new Date(dateString)

    const localDateTime = dateObject.toLocaleString("en-US", {
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    })

    return localDateTime
  }

  console.log({ notifications })

  return !notifications ? (
    <Loader />
  ) : (
    <div>
      <div className="shiny-border mb-4">
        <div className="flex justify-between sticky bg-[#1f2021] top-[3rem] items-baseline p-3 rounded-[18px] z-20">
          <h2 className="font-semibold text-[#e5e7eb] text-base">Notifications</h2>
        </div>
      </div>

      {notifications.length === 0 ? (
        notifications?.length === 0 && (
          <div className="flex flex-col justify-center items-center h-full">
            <img
              src={emptyInbox}
              alt="No Data Found"
              className="w-[250px] mb-3 opacity-35"
            />
            <h2 className="text-base text-gray-400">Nothing in here!</h2>
          </div>
        )
      ) : (
        <>
          <div className="myCard p-6 !bg-transparent pl-0 ">
            {notifications &&
              notifications.map((notification, index) => (
                <div key={index}>
                  <div className="flex items-start m-2 shiny-border max-w-md">
                    <div className=" text-gray-400 p-3 rounded-2xl max-w-md border-none border-[0.15px] w-full rounded-bl-none !bg-[#1f2021] myCard">
                      <p>{notification.message}</p>
                      <div className="flex justify-between items-baseline w-full">
                        {notification?.questionId && (
                          <MaterialLink
                            className="font-bold"
                            sx={{ cursor: "pointer", color: "#a16207", textDecoration: "none" }}>
                            <Link to={`/question?questionId=${notification.questionId}`}>Solve Question</Link>
                          </MaterialLink>
                        )}
                        {!notification.seen && (
                          <div className="self-end">
                            <Button
                              variant="outlined"
                              onClick={() => {
                                handleUpdate(notification._id)
                              }}
                              className="!font-medium hover:!text-yellow-600 hover:!bg-transparent hover:border-yellow-600 !rounded-lg !text-sm !px-2 !py-1.5 !capitalize  !text-gray-400 !border-gray-600 !mb-3 !self-end ">
                              Mark as Read
                            </Button>
                          </div>
                        )}
                      </div>
                      <div className="flex justify-end mb-3">
                        <sub>{handleDate(notification.createdAt)}</sub>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </>
      )}
    </div>
  )
}

export default Notifications
