import { getFaqsData } from "../../utils/apis";

export const setFaqsData = (faqList) => {
    return {
        type:'SET_FAQS',
        payload:faqList
    }
}

export const fetchfaqList = () =>{
    return async dispatch => {
        let response = await getFaqsData();
        if(response.status === 200) {
            let data;
            if(localStorage.usertoken){
            data = [{userRegistration:response.data[0].userRegistration},
                    {generalQueries:response.data[0].generalQueries}
                    ,{paymentIssues:response.data[0].paymentIssues},
                     {platformUsage:response.data[0].platformUsage}
                    ];
                }        
            else
            data = [{preloginQueries: response.data[0].preloginQueries}]   
            dispatch(setFaqsData(data));
        }    
    }
}