import { userperformanceData } from "../../utils/apis"

export const setUserMetrics = (user_metrics) => {
  return {
    type: "SET_USER_METRICS",
    payload: user_metrics,
  }
}

export const fetchUserMetrics = () => {
  return async (dispatch) => {
    let response = await userperformanceData()

    if (response.status === 200) {
      const currentUsermetrics = response.data
      dispatch(setUserMetrics(currentUsermetrics))
      return response.data
    }
  }
}
